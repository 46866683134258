import { Grid, makeStyles, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { courseGroupActions, snackbarActions, organizationActions, usergroupActions } from '../../../_state/actions'
import { Button } from '@mui/material'
import { history } from '../../../_state/helpers'

import { FormattedMessage } from 'react-intl'
import CapeeshStyledModal from '../../../Components/CapeeshStyledModal'
import { CapeeshColors } from '../../../assets/ColorPalette'

import TopicPreviewCard from './TopicCards/TopicPreviewCard'
import CreateNewSubPartComponent from './CreateNewModules/CreateNewSubPartModule'
import AdjustableModal from './AdjustableModal'
import ComponentStepper from './ComponentStepper'
import CreateTopicNameAndImage from './CreateTopicNameAndImage'
import TopicCreationPreviewMockup from './TopicCreationPreviewMockup'
import CourseGroupHeader from './Components/CourseGroupHeader'
import CreateCourseGroupNameAndImageSelection from './CreateCourseGroupNameAndImageSelection'
import CourseCreationPreviewMockup from './CourseCreationPreviewMockup'
import CourseGroupTopicsEmpty from './Components/CourseGroupTopicsEmpty'
import ShareCourseGroupToClassroomsModal from './Components/ShareCourseGroupToClassroomsModal'

const useStyles = makeStyles((theme) => ({
    newCourseIcon: {
        height: '50px',
        width: '50px',
        margin: 'auto auto',
    },
    dropZone: {
        position: 'relative',
        height: '100px',
        color: '#ccc',
        border: 'dashed 2px #ccc',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
        margin: '15px auto',
        padding: 15,
        transition: 'all 200ms ease-out',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '200px',
        display: 'block',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },

    capHeaderBorder: {
        margiTop: '20px',
        display: 'flex',
        flexWrap: 'nowrap',
        flexGrow: 1,
        width: '100%',
        borderTop: '4px solid gray',
    },
    heading: {
        alignSelf: 'stretch',
        color: '#232B35',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.64,
        textTransform: 'uppercase',
    },
    base: {
        paddingTop: 32,
    },
    topicListDescriptionText: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        backgroundColor: 'white',
    },

    topicListContainer: {
        marginTop: '32px',
        justifySelf: 'center',
        width: '80%',
        backgroundColor: 'white',
        borderRadius: '16px',
        padding: '32px',
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
}))

const CourseGroupOverview = (props) => {
    const classes = useStyles()
    const { computedMatch } = props

    // create new sub part attriburtes
    const [showCreateNewSubPart, setShowCreateNewSubPart] = useState(false)
    const [newSubPartName, setNewSubPartName] = useState('')

    // create new topic
    const [showCreateNewTopic, setShowCreateNewTopic] = useState(false)

    const [newTopicSubPartId, setNewTopicSubPartId] = useState('')
    const [newTopicName, setNewTopicName] = useState('')
    const [newTopicDescription, setNewTopicDescription] = useState('')
    const [newTopicImage, setNewTopicImage] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)

    // show delete topic
    const [deleteCourseGroupSubPartToTopicMapperDto, setDeleteCourseGroupSubPartToTopicMapperDto] = useState(null)
    const [showDeleteTopicConfirmation, setShowDeleteTopicConfirmation] = useState(false)

    // attach existing topic
    const [showAttachExistingTopicModal, setShowAttachExistingTopicModal] = useState(false)
    const [attachExistingTopicSubPartId, setAttachExistingTopicSubPartId] = useState('')

    const [courseGroup, setCourseGroup] = useState(null)

    // delete course group..
    const [showDeleteCourseGroupConfirmation, setShowDeleteCourseGroupConfirmation] = useState(false)

    // edit pane
    const [showEditPane, setShowEditPane] = useState(false)

    // share course
    const [showShareCourse, setShowShareCourse] = useState(false)
    const [updagingSharedCourse, setUpdatingSharedCourse] = useState(false)
    const [numberOfClassroomsUsingCourse, setNumberOfClassroomsUsingCourse] = useState(0)

    // edit state
    const [tempCourseGroupName, setTempCourseGroupName] = useState('')
    const [tempCourseGroupDescription, setTempCourseGroupDescription] = useState('')
    const [imageForCourse, setImageForCourse] = useState('')

    const [newCourseGroupImageBlob, setNewCourseGroupImageBlob] = useState(null)
    const [courseGroupSelectedImageId, setCourseGroupSelectedImageId] = useState(null)

    const courseGroupId = computedMatch.params.courseGroupId
    console.log('courseGroupId', courseGroupId)
    const calculateNumberOfClassroomsUsingCourseGroup = () => {
        if (!props.usergroup?.usergroups || props.usergroup.usergroups.length === 0) {
            setNumberOfClassroomsUsingCourse(0)
        }
        let classroomsUsingCourseGroup = 0
        for (const userGroup of props.usergroup.usergroups) {
            if (userGroup.courseGroupIds?.find((x) => x === courseGroupId)) {
                classroomsUsingCourseGroup++
            }
        }
        console.log('setting classrooms using course group', classroomsUsingCourseGroup)
        setNumberOfClassroomsUsingCourse(classroomsUsingCourseGroup)
    }

    useEffect(() => {
        const { dispatch } = props
        dispatch(courseGroupActions.getCourseGroup(courseGroupId))

        props.dispatch(usergroupActions.getAllUserGroupsForOrganisation(props.organization.selectedOrganization))

        window.scrollTo(0, 0)
        calculateNumberOfClassroomsUsingCourseGroup()
    }, [])

    useEffect(() => {
        calculateNumberOfClassroomsUsingCourseGroup()
    }, [props.usergroup.usergroups])

    useEffect(() => {
        console.log()
        if (!props.courseGroup.currentCourseGroup) {
            return
        }

        if (props.courseGroup.currentCourseGroup?.id === courseGroupId) {
            setCourseGroup(props.courseGroup.currentCourseGroup)
        }
        props.dispatch(organizationActions.getAvailableCourseGroups(props.organization.selectedOrganization))
    }, [props.courseGroup.currentCourseGroup])

    useEffect(() => {
        if (props.courseGroup.deleteCourseGroupFinished) {
            history.push('/creator/')
            props.dispatch(courseGroupActions.deleteCourseGroupReset())
        }
    }, [props.courseGroup.deleteCourseGroupFinished])

    const handleDeleteTopicFromCourseGroup = (courseGroupSubPartToTopicMapperDto) => {
        setDeleteCourseGroupSubPartToTopicMapperDto(courseGroupSubPartToTopicMapperDto)
        setShowDeleteTopicConfirmation(true)
    }

    const handleTopicClicked = (topicId) => {
        history.push('/creator/course/group/' + courseGroup.id + '/topic/' + topicId)
    }

    const handleEditSubPartName = (subPartId, newSubPartName) => {}

    const handleCancelAddingNewSubPart = () => {
        setShowCreateNewSubPart(false)
        setNewSubPartName('')
    }
    const handleStartAddingNewSubPart = () => {
        setShowCreateNewSubPart(true)
        setNewSubPartName('')
    }

    const handleCreateSubPart = (subPartName) => {
        let newSubPart = {
            name: subPartName,
            courseGroupSubPartToTopicMapperDtos: [],
        }
        if (!courseGroup.courseGroupSubPartDtos) {
            courseGroup.courseGroupSubPartDtos = [newSubPart]
        } else {
            courseGroup.courseGroupSubPartDtos.push(newSubPart)
        }

        props.dispatch(courseGroupActions.updateCourseGroup(courseGroup))
        setNewSubPartName('')
        setShowCreateNewSubPart(false)
    }

    const handleDeleteCourseGroup = () => {
        setShowDeleteCourseGroupConfirmation(true)
    }

    const handleDeleteCourseGroupConfirmed = () => {
        props.dispatch(courseGroupActions.deleteCourseGroup(courseGroup.id))
    }

    const handleDeleteTopicFromCourseGroupConfirmed = () => {
        props.dispatch(
            courseGroupActions.deleteCourseGroupSubPartToTopicMapper(
                courseGroup.id,
                deleteCourseGroupSubPartToTopicMapperDto.courseGroupSubPartId,
                deleteCourseGroupSubPartToTopicMapperDto.id
            )
        )
        setShowDeleteTopicConfirmation(false)
    }

    const handleDeleteCourseGroupCancelled = () => {
        setShowDeleteCourseGroupConfirmation(false)
    }

    const handleDeleteTopicFromCourseGroupCancelled = () => {
        setShowDeleteTopicConfirmation(false)
    }

    const handleCancelAddingNewTopic = () => {
        setShowCreateNewTopic(false)
        setNewTopicName('')
        setNewTopicSubPartId('')
    }

    const handleStartAddingNewTopic = (subPartId) => {
        setShowCreateNewTopic(true)
        setNewTopicSubPartId(subPartId)
        setNewTopicName('')
    }

    const handleShowAttachExistingTopic = (subPartId) => {
        setAttachExistingTopicSubPartId(subPartId)
        setShowAttachExistingTopicModal(true)
    }

    const handleCancelAttachExistingTopic = () => {
        setAttachExistingTopicSubPartId('')
        setShowAttachExistingTopicModal(false)
    }

    const handleAttachExistingTopic = (topicId) => {
        let subPartToTopicMapper = {
            courseGroupSubPartId: attachExistingTopicSubPartId,
            topicId: topicId,
        }

        courseGroup.courseGroupSubPartDtos.forEach((x) => {
            if (x.id === attachExistingTopicSubPartId) {
                if (!x.courseGroupSubPartToTopicMapperDtos) {
                    x.courseGroupSubPartToTopicMapperDtos = [subPartToTopicMapper]
                } else {
                    x.courseGroupSubPartToTopicMapperDtos.push(subPartToTopicMapper)
                }
            }
        })
        props.dispatch(courseGroupActions.updateCourseGroup(courseGroup))
    }

    const handleCreateTopic = () => {
        if (newTopicSubPartId === '' || newTopicName === '') {
            console.log('create topic failed name empty', newTopicSubPartId, newTopicName)
            return
        }

        props.dispatch(
            courseGroupActions.addTopicToCourseGroupSubPart(
                newTopicName,
                newTopicSubPartId,
                courseGroup,
                selectedImage,
                newTopicDescription,
                true
            )
        )
        setShowCreateNewTopic(false)
        setNewTopicSubPartId('')
        setNewTopicName('')
    }

    const handleCreateTopicSingleSubPart = (subPartId) => {
        setNewTopicSubPartId(subPartId)
        setShowCreateNewTopic(true)
    }

    const handleCloseCreateTopicModal = () => {
        setNewTopicName('')
        setNewTopicDescription('')
        setNewTopicImage(null)

        setShowCreateNewTopic(false)
    }

    const handleShowCreateTopicFirstSubPart = () => {
        if (courseGroup?.courseGroupSubPartDtos?.length > 0) {
            handleCreateTopicSingleSubPart(courseGroup.courseGroupSubPartDtos[0].id)
        }
    }

    const handleEditCourse = () => {
        setTempCourseGroupName(courseGroup.name)
        setTempCourseGroupDescription(courseGroup.description)
        // fix image url
        let fileReferenceId = null
        if (courseGroup.imageUrl) {
            var fileReferenceIdFromImageUrl = courseGroup.imageUrl.split('image/file/')[1]
            if (fileReferenceIdFromImageUrl) {
                fileReferenceId = fileReferenceIdFromImageUrl
            }
        }
        setCourseGroupSelectedImageId(fileReferenceId)

        setShowEditPane(true)
    }

    const handleSetImageBlobFromHeader = (imageBlob) => {
        let imageResult = {
            type: 'image/jpeg',
            image: { name: courseGroup?.imageFileReferenceId },
            imageUrl: imageBlob,
            imageFileName: 'placeholder',
            shouldUpload: false,
        }
        console.log('imageResult', imageResult)
        setNewCourseGroupImageBlob(imageResult)
    }

    const handleShareCourse = () => {
        setShowShareCourse(true)
    }
    const handleCloseShareCourse = () => {
        props.dispatch(usergroupActions.resetIsUpdatingUserGroupsForCourseGroupId())
        setShowShareCourse(false)
    }

    const updateCourseGroupSharedWithClassrooms = (userGroupIds) => {
        if (!props.usergroup?.usergroups || props.usergroup.usergroups.length === 0) {
        }

        setUpdatingSharedCourse(true)

        const addToUserGroupIds = []
        const deleteFromUserGroupIds = []

        for (const userGroup of props.usergroup.usergroups) {
            if (userGroupIds.find((x) => x === userGroup.id)) {
                // check if add
                if (!userGroup.courseGroupIds?.find((x) => x === courseGroupId)) {
                    // add
                    addToUserGroupIds.push(userGroup.id)
                }
            } else {
                // check if delete
                if (userGroup.courseGroupIds?.find((x) => x === courseGroupId)) {
                    // remove
                    deleteFromUserGroupIds.push(userGroup.id)
                }
            }
        }
        props.dispatch(
            usergroupActions.updateUserGroupsForCourseGroupId(
                props.organization.selectedOrganization,
                courseGroupId,
                addToUserGroupIds,
                deleteFromUserGroupIds
            )
        )
    }

    useEffect(() => {
        if (!props.usergroup.updatingUserGroupsForCourseGroup) {
            setUpdatingSharedCourse(false)
            setShowShareCourse(false)
        }
    }, [props.usergroup.updatingUserGroupsForCourseGroup])

    const handleSaveCourseGroup = () => {
        let changes = false

        // fix image check
        let imageFileReferenceId = courseGroup.imageFileReferenceId ? courseGroup.imageFileReferenceId : null
        if (courseGroupSelectedImageId !== imageFileReferenceId) {
            console.log('file reference changed', courseGroupSelectedImageId)
            imageFileReferenceId = courseGroupSelectedImageId
            changes = true
        }

        let courseGroupName = courseGroup.name
        if (tempCourseGroupName !== courseGroupName) {
            courseGroupName = tempCourseGroupName
            changes = true
        }
        let courseGroupDescription = courseGroup.description
        if (tempCourseGroupDescription !== courseGroupDescription) {
            courseGroupDescription = tempCourseGroupDescription
            changes = true
        }

        if (!changes) {
            setShowEditPane(false)
            return
        }

        courseGroup.name = courseGroupName
        courseGroup.description = courseGroupDescription
        courseGroup.imageFileReferenceId = imageFileReferenceId

        console.log('course group updated:  ', courseGroup)

        props.dispatch(courseGroupActions.updateCourseGroup(courseGroup))

        setShowEditPane(false)
    }

    const handleEditCourseCanceled = () => {
        setNewCourseGroupImageBlob(null)
        setCourseGroupSelectedImageId(null)
        setShowEditPane(false)
    }

    const handleCourses = () => {
        history.push('/creator')
    }

    const editCourseStepperComponents = [
        {
            component: (
                <CreateCourseGroupNameAndImageSelection
                    setMainText={setTempCourseGroupName}
                    setCardDescription={setTempCourseGroupDescription}
                    l2Id={courseGroup?.l2Id}
                    mainText={tempCourseGroupName}
                    cardDescription={tempCourseGroupDescription}
                    selectedImageForCourseGroup={newCourseGroupImageBlob}
                    setSelectedImageForCourseGroup={setNewCourseGroupImageBlob}
                    selectedCourseGroupImageId={courseGroupSelectedImageId}
                    setSeletedGroupGroupImageId={setCourseGroupSelectedImageId}
                />
            ),
            stepCondition:
                tempCourseGroupName.length > 0 && tempCourseGroupDescription.length > 0 && courseGroupSelectedImageId,
        },
    ]

    const stepperComponents = [
        {
            component: (
                <CreateTopicNameAndImage
                    l2Id={courseGroup?.l2Id}
                    setNewTopicName={setNewTopicName}
                    setNewTopicDescription={setNewTopicDescription}
                    setNewTopicImage={setNewTopicImage}
                    newTopicImage={newTopicImage}
                    newTopicName={newTopicName}
                    newTopicDescription={newTopicDescription}
                    setSelectedTopicImage={setSelectedImage}
                />
            ),
            stepCondition: newTopicName.length > 0 && newTopicDescription.length > 0 && newTopicImage,
        },
        /*
        {
            component: <TopicLanguageSelection languages={[]} />,
            stepCondition: true,
        },
         */
    ]

    return (
        <div
            style={{
                background: CapeeshColors.EggShell2,
                backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(225, 225, 221) 1px, transparent 0)',
                backgroundSize: '12px 12px',
            }}>
            {showEditPane && (
                <AdjustableModal
                    closeDialog={handleEditCourseCanceled}
                    isOpen={showEditPane}
                    leftContent={
                        <div className={classes.base}>
                            <div className={classes.heading}>EDIT COURSE</div>
                            <ComponentStepper
                                stepperComponents={editCourseStepperComponents}
                                onFinishStepper={handleSaveCourseGroup}
                                onCancel={handleEditCourseCanceled}
                            />
                        </div>
                    }
                    centerContent={
                        <CourseCreationPreviewMockup
                            imageSrc={newCourseGroupImageBlob}
                            mainText={tempCourseGroupName}
                            description={tempCourseGroupDescription}
                        />
                    }
                />
            )}
            {showShareCourse && (
                <ShareCourseGroupToClassroomsModal
                    courseGroupId={courseGroupId}
                    userGroups={props.usergroup.usergroups}
                    isOpen={showShareCourse}
                    onClose={handleCloseShareCourse}
                    onSharedCoursesChanged={updateCourseGroupSharedWithClassrooms}
                    savingSharedCourses={updagingSharedCourse}
                />
            )}
            {showCreateNewTopic && (
                <AdjustableModal
                    closeDialog={handleCloseCreateTopicModal}
                    isOpen={showCreateNewTopic}
                    leftContent={
                        <div className={classes.base}>
                            <div className={classes.heading}>CREATE A NEW TOPIC</div>
                            <ComponentStepper
                                stepperComponents={stepperComponents}
                                onFinishStepper={handleCreateTopic}
                                onCancel={handleCloseCreateTopicModal}
                            />
                        </div>
                    }
                    centerContent={
                        <TopicCreationPreviewMockup
                            language={courseGroup?.l2Id}
                            courseHeading={courseGroup?.name}
                            courseDescription={courseGroup?.description}
                            topicName={newTopicName}
                            topicDescription={newTopicDescription}
                            topicImage={newTopicImage}
                        />
                    }
                />
            )}

            {showCreateNewSubPart && (
                <CreateNewSubPartComponent
                    onCreateSubPart={handleCreateSubPart}
                    onCancel={() => setShowCreateNewSubPart(false)}
                    open={showCreateNewSubPart}
                />
            )}

            {showDeleteCourseGroupConfirmation && (
                <CapeeshStyledModal
                    open={showDeleteCourseGroupConfirmation}
                    textTitle={
                        <FormattedMessage id="globalwords.deleteconfirmation" defaultMessage="Delete confirmation" />
                    }
                    textLine1={
                        <FormattedMessage
                            id="creator.course.group.delete.course.group"
                            defaultMessage="Do you really want to delete this course?"
                        />
                    }
                    onButton2={() => handleDeleteCourseGroupConfirmed()}
                    onButton1={() => handleDeleteCourseGroupCancelled()}
                    button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />}
                    button1Variant={'outlined'}
                    button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes" />}
                    onClose={() => handleDeleteCourseGroupCancelled()}
                />
            )}

            {showDeleteTopicConfirmation && (
                <CapeeshStyledModal
                    open={showDeleteTopicConfirmation}
                    textTitle={
                        <FormattedMessage id="globalwords.deleteconfirmation" defaultMessage="Delete confirmation" />
                    }
                    textLine1={
                        <FormattedMessage
                            id="creator.course.group.delete.course.group"
                            defaultMessage="Do you really want to delete this topic from the course? The course will not be deleted, only removed from the course."
                        />
                    }
                    onButton2={() => handleDeleteTopicFromCourseGroupConfirmed()}
                    onButton1={() => handleDeleteTopicFromCourseGroupCancelled()}
                    button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />}
                    button1Variant={'outlined'}
                    button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes" />}
                    onClose={() => handleDeleteTopicFromCourseGroupCancelled()}
                />
            )}

            <div>
                <CourseGroupHeader
                    courseGroup={courseGroup}
                    organization={props.organization}
                    onCreateTopic={handleShowCreateTopicFirstSubPart}
                    onBackToCourses={() => handleCourses()}
                    courseGroupId={courseGroupId}
                    showActionButtons={
                        courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length > 0 &&
                        courseGroup?.courseGroupSubPartDtos?.length === 1
                    }
                    onEditCourse={() => handleEditCourse()}
                    setImageBlob={handleSetImageBlobFromHeader}
                    onShareCourse={handleShareCourse}
                    numberOfClassroomsUsingCourseGroup={numberOfClassroomsUsingCourse}
                />

                <div className={classes.topicListContainer}>
                    {courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                    variant="h4"
                                    style={{
                                        display: 'inline-block',
                                        color: CapeeshColors.DeepBlue,
                                    }}>
                                    Topics
                                </Typography>
                                <div
                                    style={{
                                        marginBottom: '20px',
                                        marginTop: '10px',
                                    }}>
                                    <Typography variant="h4" className={classes.topicListDescriptionText}>
                                        Click on a topic to create, edit and publish lessons..{' '}
                                        {courseGroup?.courseGroupSubPartDtos?.length > 1 &&
                                            'The topics are split into sections.'}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}

                    {/*{*/}
                    {/*  !(courseGroup?.courseGroupSubPartDtos?.length === 1 && courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length === 0) &&*/}
                    {/*  <Button*/}
                    {/*    className={classes.leftSideButtons}*/}
                    {/*    variant={"contained"}*/}
                    {/*    style={{*/}
                    {/*      background: CapeeshColors.Green,*/}
                    {/*      color: 'white',*/}
                    {/*      marginRight: '15px',*/}
                    {/*      textTransform: 'none'*/}
                    {/*    }}*/}
                    {/*    onClick={() => setShowCreateNewSubPart(true)}*/}
                    {/*  >*/}
                    {/*    <FormattedMessage id="dashboard.creator.section.new" defaultMessage="Create section"/>*/}
                    {/*  </Button>*/}
                    {/*}*/}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100vh',
                            alignItems: 'stretch',
                        }}>
                        {courseGroup?.courseGroupSubPartDtos?.length === 1 && (
                            <div
                                style={{
                                    marginTop: courseGroup?.courseGroupSubPartDtos?.length > 1 ? '30px' : '0px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                {courseGroup.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos.map(
                                    (topicMapper, index2) => {
                                        return (
                                            <TopicPreviewCard
                                                key={topicMapper.imageUrl}
                                                courseGroupSubPartToTopicMapperDto={topicMapper}
                                                openTopic={handleTopicClicked}
                                                onDeleteTopicFromCourseGroup={handleDeleteTopicFromCourseGroup}
                                            />
                                        )
                                    }
                                )}
                            </div>
                        )}

                        {courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length === 0 && (
                            <CourseGroupTopicsEmpty onCreateTopic={handleShowCreateTopicFirstSubPart} />
                        )}

                        {courseGroup?.courseGroupSubPartDtos?.length > 1 &&
                            courseGroup.courseGroupSubPartDtos.map((subPart, index) => {
                                return (
                                    <React.Fragment>
                                        <Grid
                                            container
                                            style={{
                                                marginTop: '35px',
                                            }}>
                                            <Grid item xs={8}>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        marginBottom: '7px',
                                                        fontSize: '25px',
                                                        fontWeight: 'bold',
                                                        display: 'inline-block',
                                                        color: CapeeshColors.DeepBlue,
                                                    }}>
                                                    {subPart.name}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'right',
                                                    justifyContent: 'right',
                                                    marginBottom: '10px',
                                                }}>
                                                <Button
                                                    className={classes.leftSideButtons}
                                                    variant={'contained'}
                                                    style={{
                                                        background: CapeeshColors.Blue,
                                                        color: 'white',
                                                        marginRight: '15px',
                                                        minHeight: '30px',
                                                        textTransform: 'none',
                                                    }}
                                                    onClick={() => handleCreateTopicSingleSubPart(subPart?.id)}>
                                                    <FormattedMessage
                                                        id="dashboard.creator.newtopic.button.title"
                                                        defaultMessage={'Create topic in ' + subPart.name}
                                                    />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {subPart.courseGroupSubPartToTopicMapperDtos.length === 0 && (
                                            <Typography
                                                variant="h4"
                                                style={{
                                                    fontFamily: 'Lato',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    fontSize: '16px',
                                                    lineHeight: '23px',
                                                }}>
                                                No courses have been created within the {subPart.name} section of the
                                                course.
                                            </Typography>
                                        )}
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                gap: '20px',
                                            }}>
                                            {subPart.courseGroupSubPartToTopicMapperDtos.map((topicMapper, index2) => {
                                                return (
                                                    <TopicPreviewCard
                                                        key={topicMapper.imageUrl}
                                                        courseGroupSubPartToTopicMapperDto={topicMapper}
                                                        openTopic={handleTopicClicked}
                                                        onDeleteTopicFromCourseGroup={handleDeleteTopicFromCourseGroup}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, usergroup, courseGroup, metadata, user } = state
    return {
        organization,
        usergroup,
        courseGroup,
        metadata,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseGroupOverview)
