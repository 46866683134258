import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { ToggleModeViewTypes } from '../../../../Logic/ScenarioConstants'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CircularProgress from '@material-ui/core/CircularProgress'
import CreateIcon from '@material-ui/icons/Create'
import { motion } from 'framer-motion'

import { ReactComponent as LockImage } from '../../../../assets/images/lock.svg'
import { ReactComponent as UnlockedImage } from '../../../../assets/images/unlocked.svg'

import LessonBuilderAdminMenu from '../../../../Components/CDropdownMenu/LessonBuilderAdminMenu'

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '16px',
        width: '100%',
        height: '40px',
        background: CapeeshColors.Primary900,
        padding: '8px',
        position: 'relative',
    },
    header: {
        paddingLeft: '16px',
        paddingRight: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },

    leftButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
    },
    centerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        rowGap: '8px',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
    },

    topBarbutton: {
        background: CapeeshColors.Primary600,
        color: 'white',
        borderRadius: 8,
        textTransform: 'none',
    },
    editmode: {
        fontFamily: 'Rubik',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    editModeIndicationText: {
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    centerTextContainer: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9,
    },
}))

const LessonBuilderHeaderBar = ({
    canEditLesson,
    showAdminButtons,
    showPreview,
    onOpenOptions,
    onOpenSupportedLanugages,
    onRevertModal,
    onOpenLibrary,
    validated,
    isBusy,
    canBePublished,
    onPublishLesson,
    onGoToTopic,
    checkForCanBePublished,
    onToggleCanEditMode,
    isCreatorUser,
    lessonLoaded,
}) => {
    const classes = useStyles()

    const buttonAnimation = { rotate: [0, 5, -5, 5, -5, 0], scale: [1, 1.15, 1] }
    const animationDuration = 0.75

    const [animation, setAnimation] = React.useState({})

    const TriggerAnimation = () => {
        setAnimation(buttonAnimation)
    }

    useEffect(() => {
        document.addEventListener('lessonBuilderLockedClick', (e) => {
            TriggerAnimation()
        })

        return () => {
            document.removeEventListener('lessonBuilderLockedClick', (e) => {
                TriggerAnimation()
            })
        }
    }, [])

    return (
        <div className={classes.header}>
            <div className={classes.centerTextContainer}>
                <div className={classes.centerContainer}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                        }}>
                        {!canEditLesson ? (
                            <LockImage size={24} fill={CapeeshColors.Primary400} fillOpacity={1} />
                        ) : (
                            <UnlockedImage size={24} fill={CapeeshColors.Primary400} fillOpacity={1} />
                        )}

                        <div className={classes.editmode} style={{}}>
                            Edit Mode
                        </div>
                        {canEditLesson ? (
                            <div className={classes.editModeIndicationText}>(unlocked)</div>
                        ) : (
                            <div className={classes.editModeIndicationText}>(locked)</div>
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <div className={classes.leftButtonsContainer}>
                    <Button
                        style={{
                            color: 'white',
                            background: CapeeshColors.DarkButtonBackground,
                            textTransform: 'none',
                        }}
                        className={classes.topBarbutton}
                        size="small"
                        variant={'outlined'}
                        onClick={onGoToTopic}>
                        Back to lesson list
                    </Button>

                    {canEditLesson && (
                        <Button
                            style={{
                                color: 'white',
                                background: CapeeshColors.DarkButtonBackground,
                                textTransform: 'none',
                            }}
                            onClick={() => onOpenOptions()}
                            className={classes.topBarbutton}
                            variant="outlined"
                            size="small">
                            Options
                        </Button>
                    )}

                    {showAdminButtons && (
                        <LessonBuilderAdminMenu
                            onOpenSupportedLanugages={onOpenSupportedLanugages}
                            onRevertModal={onRevertModal}
                            onOpenLibrary={onOpenLibrary}
                        />
                    )}
                </div>
                <div className={classes.rightContainer}>
                    {lessonLoaded && !validated && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '8px',
                                color: CapeeshColors.Primary400,
                            }}>
                            <ErrorIcon />
                            <Typography
                                variant="body1"
                                style={{
                                    boxShadow: 'none',
                                    fontSize: 16,
                                    color: 'white',
                                    fontFamily: 'Lato',
                                }}>
                                {isBusy
                                    ? 'Updating the lesson'
                                    : 'Fix validation errors in all stages with red borders'}
                            </Typography>
                        </div>
                    )}
                    {validated && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '8px' }}>
                            <CheckCircleIcon
                                style={{
                                    color: CapeeshColors.Primary400,
                                }}
                            />
                            <Typography
                                variant="body1"
                                style={{
                                    boxShadow: 'none',
                                    fontSize: 16,
                                    color: 'white',
                                    fontFamily: 'Lato',
                                }}>
                                {isBusy ? 'Updating the lesson...' : 'Validated'}
                            </Typography>
                        </div>
                    )}

                    {lessonLoaded && canBePublished && (
                        <Button
                            onClick={() => onPublishLesson()}
                            className={classes.button}
                            style={{
                                textTransform: 'none',
                                background: CapeeshColors.Primary600,
                                color: 'white',
                            }}
                            variant="contained"
                            size="small">
                            Publish changes
                        </Button>
                    )}
                    {canEditLesson && checkForCanBePublished && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '8px' }}>
                            <CircularProgress
                                style={{
                                    marginLeft: '10px',
                                    marginTop: '5px',
                                    height: '20px',
                                    width: '20px',
                                    pointerEvents: 'none',
                                    color: CapeeshColors.Primary600,
                                }}></CircularProgress>
                            <Typography
                                style={{
                                    marginLeft: '5px',
                                    marginTop: '5px',
                                    paddingBottom: '3px',
                                    boxShadow: 'none',
                                    fontSize: 16,
                                    color: 'white',
                                    fontFamily: 'Lato',
                                }}>
                                Checking status
                            </Typography>
                        </div>
                    )}

                    {isCreatorUser && canEditLesson && (
                        <Button
                            onClick={onToggleCanEditMode}
                            style={{
                                marginLeft: '10px',
                                textTransform: 'none',
                            }}
                            className={classes.button}
                            variant="contained"
                            size="small"
                            color="secondary">
                            Stop editing
                        </Button>
                    )}
                    {isCreatorUser && !canEditLesson && (
                        <motion.div
                            animate={animation}
                            transition={{ ease: 'easeInOut', duration: animationDuration }}
                            onAnimationComplete={() => {
                                setAnimation({})
                            }}>
                            <Button
                                onClick={onToggleCanEditMode}
                                style={{
                                    marginLeft: '10px',
                                    background: CapeeshColors.Primary600,
                                    textTransform: 'none',
                                }}
                                className={classes.button}
                                variant="contained"
                                size="small">
                                <CreateIcon
                                    style={{
                                        marginRight: '5px',
                                    }}
                                />
                                Edit lesson
                            </Button>
                        </motion.div>
                    )}
                </div>
            </div>
        </div>
    )
}

LessonBuilderHeaderBar.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonBuilderHeaderBar)
