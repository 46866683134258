import React, { useRef, useState } from 'react'
import { MenuItem, ControlledMenu, useHover } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { connect } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import { CapeeshColors } from '../../assets/ColorPalette'

const useStyles = makeStyles((theme) => ({
    singleElementStyle: {
        fontSize: '20px',
        height: '32px',
    },
    hoverButtonStyle: {
        fontSize: '20px',
        cursor: 'pointer',
    },
}))

const LessonBuilderAdminMenu = ({ onOpenSupportedLanugages, onRevertModal, onOpenLibrary }) => {
    const classes = useStyles()

    const ref = useRef(null)
    const [isOpen, setOpen] = useState(false)
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen)

    return (
        <React.Fragment>
            <div
                className={classes.hoverButtonStyle}
                ref={ref}
                {...anchorProps}
                style={{
                    backgroundColor: CapeeshColors.DarkButtonBackground,
                    borderRadius: '4px',
                    display: 'flex',
                    height: '32px',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                }}>
                <div style={{}}>
                    <Typography
                        style={{
                            color: 'white',
                        }}>
                        Capeesh Admin only
                    </Typography>
                </div>
            </div>

            <ControlledMenu
                {...hoverProps}
                state={isOpen ? 'open' : 'closed'}
                anchorRef={ref}
                onClose={() => setOpen(false)}>
                <MenuItem
                    disabled={false}
                    onClick={() => onOpenSupportedLanugages()}
                    className={classes.singleElementStyle}>
                    Lesson Languages
                </MenuItem>

                <MenuItem onClick={() => onRevertModal()} className={classes.singleElementStyle}>
                    Versions
                </MenuItem>

                <MenuItem disabled={false} onClick={() => onOpenLibrary()} className={classes.singleElementStyle}>
                    Library
                </MenuItem>
            </ControlledMenu>
        </React.Fragment>
    )
}

LessonBuilderAdminMenu.propTypes = {}

export default LessonBuilderAdminMenu
