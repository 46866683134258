import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import shortStoryImage from 'assets/images/shortStoryIllustration.png'
import storyImage from 'assets/images/narrativeIllustration.png'
import { AutoCourseCreatorNarrativeType } from '../../../../Logic/AutoCourseCreationConstants'
import ComingSoonSvg from '../Images/ComingSoonSvg'

const useStyles = makeStyles((theme) => ({
    centerElements: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center',
        width: '100vv',
    },
    mainText: {
        width: '100%',
        textAlign: 'center',
        color: 'white',
        fontSize: 32,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        marginBottom: 16,
    },
    selectionBoxes: {
        display: 'flex',
        justifyContent: 'center',
    },
    images: {
        width: 96,
        height: 104,
        marginBottom: 16,
    },
    boxMainText: {
        width: '100%',
        textAlign: 'center',
        color: 'white',
        fontSize: 24,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    boxSubText: {
        color: 'white',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginTop: 24,
    },
    boxSubTextBold: {
        color: 'white',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '600',
        wordWrap: 'break-word',
        textAlign: 'center',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 32,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 32,
        width: 390,
        height: 226,
    },
}))

const AutoLanguageCourseNarrativeTypeScreen = (props) => {
    // props.selectedNarrativeOption(narrativeOptionEnum)

    const {} = props
    const classes = useStyles()

    const handleChooseNarrative = (option) => {
        props.selectedNarrativeOption(option)
    }

    useEffect(() => {
        handleChooseNarrative(AutoCourseCreatorNarrativeType.Fictional)
    }, [])

    return (
        <div>
            <div className={classes.centerElements}>
                <div className={classes.selectionBoxes}>
                    <div className={classes.contentContainer}>
                        <img src={storyImage} className={classes.images} />
                        <Typography className={classes.boxMainText}>It’s time to craft your story!</Typography>
                        <Typography className={classes.boxSubText}>
                            Create a compelling story that runs through all your lessons, teaching all your content in a
                            realistic context.
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

AutoLanguageCourseNarrativeTypeScreen.propTypes = {}

const mapStateToProps = (state) => {
    return {
        autoCourseCreation: state.autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoLanguageCourseNarrativeTypeScreen)
