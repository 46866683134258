import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'

import { history } from '../../../_state/helpers'
import PreviewCourseGroupCard from './Cards/PreviewCourseGroupCard'

const useStyles = makeStyles((theme) => ({
    newCourseIcon: {
        height: '50px',
        width: '50px',
        margin: 'auto auto',
    },
}))

const CreatorCourseGroupComponent = (props) => {
    const classes = useStyles()

    const handleOpenCourseGroup = (courseGroupId) => {
        history.push('/creator/course/group/' + courseGroupId)
    }

    const isNewlyCreatedCourseGroup = (coursegroup) => {
        // if new, move to first in the course group list
        return coursegroup.id === props.newlyCreatedCourseGroupId ? '-1' : coursegroup.name
    }

    return (
        <div key={props.courseGroups}>
            <div
                style={{
                    marginTop: '20px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, 273px)',
                    rowGap: '40px',
                    alignContent: 'space-evenly',
                    justifyItems: 'center',
                    justifyContent: 'center',
                }}>
                {/*</Grid>*/}
                {props.courseGroups
                    .sort((a, b) => isNewlyCreatedCourseGroup(a).localeCompare(b.name))
                    .map(
                        (courseGroup, index) => (
                            <PreviewCourseGroupCard
                                courseGroup={courseGroup}
                                index={index}
                                openCourseGroup={handleOpenCourseGroup}
                            />
                        )
                        // </Grid>
                    )}
                {/*</Grid>*/}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, metadata } = state
    return {
        organization,
        metadata,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorCourseGroupComponent)
