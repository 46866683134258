import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { organizationActions, usergroupActions } from '_state/actions'
import { userActions } from '../../_state/actions'
import { history } from '_state/helpers'

import DashboardImage from '../../assets/images/dashboard/dashboard_img.png'
import CreateImage from '../../assets/images/dashboard/create_img.png'
import { CapeeshColors } from '../../assets/ColorPalette'
import { Button } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import OrganizationSelectFooter from '../../Components/Footers/OrganizationSelectFooter'
import OrganizationChangeDialog from './Organization/Components/OrganizationChangeDialog'
import Set from '@iconify-icons/mdi/set'
import { FormattedMessage } from 'react-intl'
import { useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '80px',
        marginRight: '80px',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '40px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '10px',
        },
    },
    gridContainer: {
        display: 'flex',
        marginLeft: '80px',
        marginRight: '80px',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            marginRight: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '5px',
            marginRight: '5px',
        },
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            // gap: '10px',
        },
        gap: '40px',
    },
    blockItem: {},
    main: {
        backgroundColor: CapeeshColors.EggShell2,
        backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(225, 225, 221) 1px, transparent 0)',
        backgroundSize: '12px 12px',
        marginTop: '-80px',
        minHeight: 'calc(100vh - 65px)',
        // height: '100%',
    },

    goToAreaButton: {
        marginTop: -7,
        height: '40px',
        align: 'right',
        minWidth: '120px',
        textAlign: 'center',
        textTransform: 'none',
    },

    sectionHolder: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    cardHolder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    cardLeft: {
        maxWidth: '800px',
        minWidth: '300px',
        justifyContent: 'flex-start',
        marginRight: '80px',
    },
    cardRight: {
        maxWidth: '800px',
        minWidth: '300px',
        justifyContent: 'flex-end',
    },

    card: {
        minHeight: '250px',
        marginTop: '25px',
    },

    cardTitle: {
        color: CapeeshColors.DeepBlue,
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '400',
        textTransform: 'uppercase',
        letterSpacing: 0.64,
        wordWrap: 'break-word',
    },

    cardLargeText: {
        color: CapeeshColors.DeepBlue,
        fontSize: 40,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
        letterSpacing: 0.64,
    },
    cardDescription: {
        color: CapeeshColors.DeepBlue,
        fontSize: 20,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        letterSpacing: 0.64,
    },
}))

const TopLevelDashboard = (props) => {
    const classes = useStyles()

    const [currentOrganization, setCurrentOrganization] = useState(null)
    const [openOrgModal, setOpenOrgModal] = useState(false)

    const theme = useTheme()
    const smDownMatch = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        props.dispatch(usergroupActions.getAllUserGroupsForOrganisation(props.organization.selectedOrganization))
        props.dispatch(userActions.getUserInfo(props.organization.selectedOrganization))
    }, [])

    useEffect(() => {
        var org = props.organization.myOrganizations.find((x) => x.id === props.organization.selectedOrganization)
        if (org) {
            setCurrentOrganization(org)
        }
    }, [props.organization.myOrganizations])

    const handleGoToCreator = () => {
        if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') {
            history.push('/creator/')
        } else {
            history.push('/creator/overview')
        }
    }

    const handleOrganizationSwapToDashboard = (organizationId) => {
        props.dispatch(organizationActions.setCurrentOrganization(organizationId, false))
        props.dispatch(organizationActions.getGraphDataDayOfWeekOrganization(organizationId))
        props.dispatch(organizationActions.getGraphDataHourlyOrganization(organizationId))
        props.dispatch(organizationActions.getAvailableCourseGroups(organizationId))
        history.push('/')

        setCurrentOrganization(organizationId)
    }

    const handleGoToSkillsOverview = () => {
        history.push('/skills/')
    }

    const handleGoToUserManagementDashboard = () => {
        history.push('/dashboard/')
    }
    const handleGoToOrganizations = () => {
        history.push('/admin/organizations')
    }

    return (
        <div className={classes.main}>
            {openOrgModal && (
                <OrganizationChangeDialog
                    closeOrgModal={() => setOpenOrgModal(false)}
                    chooseOrg={handleOrganizationSwapToDashboard}
                />
            )}
            <div className={classes.root}>
                <div className={classes.gridContainer}>
                    <div className={classes.rowContainer}>
                        <div className={classes.blockItem}>
                            <div
                                className={classes.rowCenterSpaceBetween}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    minHeight: smDownMatch ? '300px' : '400px',
                                    maxHeight: '500px',
                                }}>
                                <div>
                                    <Typography className={classes.cardTitle} style={{ marginTop: '20px' }}>
                                        Capeesh Dashboard
                                    </Typography>

                                    <Typography comopnent={'div'} className={classes.cardLargeText}>
                                        <FormattedMessage
                                            id="top.level.dashboard.getfulloverview"
                                            defaultMessage="Get a full overview with"
                                        />{' '}
                                        <Typography
                                            display={'inline'}
                                            className={classes.cardLargeText}
                                            style={{
                                                color: CapeeshColors.Primary600,
                                            }}>
                                            Capeesh Dashboard
                                        </Typography>
                                    </Typography>

                                    <Typography className={classes.cardDescription}>
                                        <FormattedMessage
                                            id="top.level.dashboard.description"
                                            defaultMessage="Administer classrooms, students and courses that users can access. Access statistics and reports"
                                        />
                                    </Typography>

                                    <Button
                                        variant="outlined"
                                        onClick={() => handleGoToUserManagementDashboard()}
                                        style={{
                                            backgroundColor: CapeeshColors.Primary900,
                                            color: 'white',
                                            fontSize: 16,
                                            fontFamily: 'Rubik',
                                            fontWeight: '400',
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            textTransform: 'none',
                                            marginTop: '10px',
                                        }}>
                                        <FormattedMessage
                                            id="top.level.dashboard.gotodashboardbutton"
                                            defaultMessage="Go to dashboard"
                                        />
                                        <ArrowRightAltIcon
                                            sx={{
                                                marginLeft: '5px',
                                                fontSize: '15px',
                                                color: 'white',
                                                paddingRight: '5px',
                                            }}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {!smDownMatch && (
                            <div className={classes.blockItem}>
                                <div
                                    className={classes.cardRight}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                    }}>
                                    <img
                                        style={{
                                            marginTop: '20px',
                                            minWidth: '40%',
                                            maxWidth: '110%',
                                            borderRadius: '16px',
                                        }}
                                        src={DashboardImage}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.rowContainer}>
                        {!smDownMatch && (
                            <div className={classes.blockItem}>
                                <div className={classes.cardLeft}>
                                    <img style={{ minWidth: '50%', maxWidth: '110%' }} src={CreateImage} />
                                </div>
                            </div>
                        )}

                        <div className={classes.blockItem}>
                            <div
                                className={classes.cardRight}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    minHeight: smDownMatch ? '400px' : '500px',
                                    maxHeight: '600px',
                                    // marginLeft: '40px',
                                }}>
                                <div>
                                    <Typography className={classes.cardTitle}>Capeesh Create</Typography>

                                    <Typography className={classes.cardLargeText}>
                                        <FormattedMessage
                                            id="top.level.dashboard.createyourowncourseswith"
                                            defaultMessage="Create your own courses with"
                                        />{' '}
                                        <Typography
                                            display={'inline'}
                                            className={classes.cardLargeText}
                                            style={{
                                                color: '#B43CB2',
                                            }}>
                                            Capeesh Create
                                        </Typography>
                                    </Typography>

                                    <Typography className={classes.cardDescription}>
                                        <FormattedMessage
                                            id="top.level.dashboard.createdescription"
                                            defaultMessage="Create engaging and game-based training in minutes. Or browse our off-the-shelf Skills courses."
                                        />
                                    </Typography>

                                    <div
                                        style={{
                                            marginTop: '10px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleGoToCreator()}
                                            style={{
                                                backgroundColor: CapeeshColors.Primary900,
                                                color: 'white',
                                                fontSize: 16,
                                                fontFamily: 'Rubik',
                                                fontWeight: '400',
                                                paddingLeft: '16px',
                                                paddingRight: '16px',
                                                textTransform: 'none',
                                            }}>
                                            <FormattedMessage
                                                id="top.level.dashboard.gotocreatebuttontext"
                                                defaultMessage="Go to Create"
                                            />
                                            <ArrowRightAltIcon
                                                sx={{
                                                    marginLeft: '5px',
                                                    fontSize: '15px',
                                                    color: 'white',
                                                    paddingRight: '5px',
                                                }}
                                            />
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            onClick={() => handleGoToSkillsOverview()}
                                            style={{
                                                color: CapeeshColors.Primary900,
                                                backgroundColor: 'white',
                                                fontSize: 16,
                                                fontFamily: 'Rubik',
                                                fontWeight: '400',
                                                paddingLeft: '16px',
                                                paddingRight: '16px',
                                                textTransform: 'none',
                                                marginLeft: '10px',
                                            }}>
                                            <FormattedMessage
                                                id="top.level.dashboard.seeallskillsbuttontext"
                                                defaultMessage="See all our Skills courses"
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {props.organization.myOrganizations?.length > 1 && (
                <OrganizationSelectFooter openOrgModal={() => setOpenOrgModal(true)}></OrganizationSelectFooter>
            )}
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopLevelDashboard)
