import { asyncRequest } from './asyncRequest'

export const usergroupConstants = {
    CREATE_USERGROUP: new asyncRequest('CREATE_USERGROUP'),
    CREATE_USERGROUP_RESET: new asyncRequest('CREATE_USERGROUP_RESET'),
    EDIT_USERGROUP: new asyncRequest('EDIT_USERGROUP'),
    DELETE_USERGROUP: new asyncRequest('DELETE_USERGROUP'),
    UPDATE_USERGROUP: new asyncRequest('UPDATE_USERGROUP'),
    GET_ALL_USERGROUPS: new asyncRequest('GET_ALL_USERGROUPS'),
    GET_USERGROUP_USERS: new asyncRequest('GET_USERGROUP_USERS'),
    GET_USERGROUP_TOPICS: new asyncRequest('GET_USERGROUP_TOPICS'),
    ADD_USERGROUP_USERS: new asyncRequest('ADD_USERGROUP_USERS'),
    DELETE_USERGROUP_USERS: new asyncRequest('DELETE_USERGROUP_USERS'),
    ADD_USERGROUP_TOPIC: new asyncRequest('ADD_USERGROUP_TOPIC'),
    DELETE_USERGROUP_TOPIC: new asyncRequest('DELETE_USERGROUP_TOPIC'),
    GET_USERGROUP_STATS: new asyncRequest('GET_USERGROUP_STATS'),
    RESET_CURRENT_ORGANIZATION: new asyncRequest('RESET_CURRENT_ORGANIZATION'),

    ADD_COURSE_GROUP_TO_USER_GROUP: new asyncRequest('ADD_COURSE_GROUP_TO_USER_GROUP'),
    DELETE_COURSE_GROUP_FROM_USER_GROUP: new asyncRequest('DELETE_COURSE_GROUP_FROM_USER_GROUP'),
    GET_COURSE_GROUPS_FOR_USER_GROUP: new asyncRequest('GET_COURSE_GROUPS_FOR_USER_GROUP'),

    UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID: new asyncRequest('UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID'),
    RESET_UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID: new asyncRequest('RESET_UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID'),

    ADD_USER_GROUP_REPORTING_USER: new asyncRequest('Add_USER_GROUP_REPORTING_USER'),
    REMOVE_USER_GROUP_REPORTING_USER: new asyncRequest('REMOVE_USER_GROUP_REPORTING_USER'),
}

export const usergroupActions = {
    resetCreateUserGroup() {
        return {
            type: usergroupConstants.CREATE_USERGROUP_RESET.REQUEST,
            payload: {},
        }
    },

    createUserGroup(organizationId, name, description, courseGroupIds) {
        return {
            type: usergroupConstants.CREATE_USERGROUP.REQUEST,
            payload: {
                organizationId: organizationId,
                name: name,
                description: description,
                courseGroupIds: courseGroupIds,
            },
        }
    },

    editUserGroup(userGroupId, name, organizationId) {
        return {
            type: usergroupConstants.EDIT_USERGROUP.REQUEST,
            payload: {
                userGroupId: userGroupId,
                name: name,
                organizationId: organizationId,
            },
        }
    },

    deleteUserGroup(organizationId, userGroupId) {
        return {
            type: usergroupConstants.DELETE_USERGROUP.REQUEST,
            payload: {
                organizationId: organizationId,
                userGroupId: userGroupId,
            },
        }
    },

    resetCurrentOrganization(organizationId) {
        return {
            type: usergroupConstants.RESET_CURRENT_ORGANIZATION.REQUEST,
            payload: {
                organizationId: organizationId,
            },
        }
    },

    getCourseGroupsForUserGroup(userGroupId) {
        return {
            type: usergroupConstants.GET_COURSE_GROUPS_FOR_USER_GROUP.REQUEST,
            payload: {
                userGroupId,
            },
        }
    },

    resetIsUpdatingUserGroupsForCourseGroupId() {
        return {
            type: usergroupConstants.RESET_UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID.REQUEST,
        }
    },

    updateUserGroupsForCourseGroupId(organizationId, courseGroupId, addToUserGroupIds, deleteFromUserGroupIds) {
        return {
            type: usergroupConstants.UPDATE_USER_GROUPS_FOR_COURSE_GROUP_ID.REQUEST,
            payload: {
                organizationId,
                courseGroupId,
                addToUserGroupIds,
                deleteFromUserGroupIds,
            },
        }
    },

    addCourseGroupToUserGroup(userGroupId, courseGroupId) {
        return {
            type: usergroupConstants.ADD_COURSE_GROUP_TO_USER_GROUP.REQUEST,
            payload: {
                userGroupId,
                courseGroupId,
            },
        }
    },

    removeCourseGroupFromUserGroup(userGroupId, courseGroupId) {
        return {
            type: usergroupConstants.DELETE_COURSE_GROUP_FROM_USER_GROUP.REQUEST,
            payload: {
                userGroupId,
                courseGroupId,
            },
        }
    },

    getUserGroupUsers(organizationId, userGroupId) {
        return {
            type: usergroupConstants.GET_USERGROUP_USERS.REQUEST,
            payload: {
                organizationId: organizationId,
                userGroupId: userGroupId,
            },
        }
    },

    getUserGroupTopics(organizationId, userGroupId) {
        return {
            type: usergroupConstants.GET_USERGROUP_TOPICS.REQUEST,
            payload: {
                organizationId: organizationId,
                userGroupId: userGroupId,
            },
        }
    },

    addUserGroupUsers(organizationId, userGroupId, userIds) {
        return {
            type: usergroupConstants.ADD_USERGROUP_USERS.REQUEST,
            payload: {
                organizationId: organizationId,
                userGroupId: userGroupId,
                userIds: userIds,
            },
        }
    },

    deleteUserGroupUsers(userGroupId, userIds, organizationId) {
        return {
            type: usergroupConstants.DELETE_USERGROUP_USERS.REQUEST,
            payload: {
                userGroupId: userGroupId,
                userIds: userIds,
                organizationId,
            },
        }
    },

    addUserGroupTopic(organizationId, userGroupId, topicId, shouldReprocess) {
        return {
            type: usergroupConstants.ADD_USERGROUP_TOPIC.REQUEST,
            payload: {
                organizationId: organizationId,
                userGroupId: userGroupId,
                topicId: topicId,
                shouldReprocess,
            },
        }
    },

    deleteUserGroupTopic(organizationId, userGroupId, topicId, shouldReprocess) {
        return {
            type: usergroupConstants.DELETE_USERGROUP_TOPIC.REQUEST,
            payload: {
                organizationId: organizationId,
                userGroupId: userGroupId,
                topicId: topicId,
                shouldReprocess: shouldReprocess,
            },
        }
    },

    getAllUserGroupsForOrganisation(organizationId) {
        return {
            type: usergroupConstants.GET_ALL_USERGROUPS.REQUEST,
            payload: {
                organizationId,
            },
        }
    },

    updateUserGroup(organizationId, userGroupId, name, description, maxLicenses) {
        return {
            type: usergroupConstants.UPDATE_USERGROUP.REQUEST,
            payload: {
                organizationId,
                userGroupId,
                name,
                description,
                maxLicenses,
            },
        }
    },

    getUserGroupStats(organizationId, userGroupId) {
        return {
            type: usergroupConstants.GET_USERGROUP_STATS.REQUEST,
            payload: {
                organizationId,
                userGroupId,
            },
        }
    },

    addReportingUserToUserGroup(organizationId, userGroupId, email) {
        return {
            type: usergroupConstants.ADD_USER_GROUP_REPORTING_USER.REQUEST,
            payload: {
                organizationId,
                userGroupId,
                email,
            },
        }
    },

    removeReportingUserFromUserGroup(organizationId, userGroupId, userId) {
        return {
            type: usergroupConstants.REMOVE_USER_GROUP_REPORTING_USER.REQUEST,
            payload: {
                organizationId,
                userGroupId,
                userId,
            },
        }
    },
}
