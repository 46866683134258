import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import { motion } from 'framer-motion'
import ripImageTop from 'assets/images/option-header-tear.svg'
import ripImageBottom from 'assets/images/option-footer-tear.svg'

const useStyles = makeStyles((theme) => ({
    mainCardContainer: {
        width: 293,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        marginRight: 8,
    },
}))

const ChapterOptionCard = (props) => {
    const {} = props
    const classes = useStyles()

    return (
        <div className={classes.mainCardContainer}>
            <img src={ripImageTop} />
            <div
                style={{
                    height: '100%',
                    background: 'white',
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 8,
                    paddingBottom: 8,
                }}>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: [1, 0.5, 1] }}
                    transition={{ duration: 1, loop: Infinity }}
                    style={{
                        width: 100,
                        height: 20,
                        background: '#EFEFF4',
                        borderRadius: 8,
                        marginBottom: 16,
                    }}></motion.div>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: [1, 0.5, 1] }}
                    transition={{ duration: 1, loop: Infinity }}
                    style={{
                        width: 261,
                        height: 20,
                        background: '#EFEFF4',
                        borderRadius: 8,
                        marginBottom: 4,
                    }}></motion.div>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: [1, 0.5, 1] }}
                    transition={{ duration: 1, loop: Infinity }}
                    style={{
                        width: 229,
                        height: 20,
                        background: '#EFEFF4',
                        borderRadius: 8,
                        marginBottom: 4,
                    }}></motion.div>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: [1, 0.5, 1] }}
                    transition={{ duration: 1, loop: Infinity }}
                    style={{
                        width: 197,
                        height: 20,
                        background: '#EFEFF4',
                        borderRadius: 8,
                        marginBottom: 16,
                    }}></motion.div>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: [1, 0.5, 1] }}
                    transition={{ duration: 1, loop: Infinity }}
                    style={{
                        width: 197,
                        height: 20,
                        background: '#EFEFF4',
                        borderRadius: 8,
                        marginBottom: 4,
                    }}></motion.div>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: [1, 0.5, 1] }}
                    transition={{ duration: 1, loop: Infinity }}
                    style={{
                        width: 261,
                        height: 20,
                        background: '#EFEFF4',
                        borderRadius: 8,
                        marginBottom: 4,
                    }}></motion.div>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: [1, 0.5, 1] }}
                    transition={{ duration: 1, loop: Infinity }}
                    style={{
                        width: 261,
                        height: 20,
                        background: '#EFEFF4',
                        borderRadius: 8,
                        marginBottom: 4,
                    }}></motion.div>
            </div>
            <img src={ripImageBottom} />
        </div>
    )
}

ChapterOptionCard.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterOptionCard)
