import React, { useState, useEffect, useRef } from 'react'
import {
    Grid,
    Button,
    makeStyles,
    Typography,
    Card,
    List,
    ListItem,
    Select,
    MenuItem,
    ButtonGroup,
    Box,
} from '@material-ui/core'
import { Checkbox } from '@mui/material'
import { TextField } from '@mui/material'
import UndoIcon from '@mui/icons-material/Undo'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { connect } from 'react-redux'
import 'react-image-crop/dist/ReactCrop.css'
import SpeakWizard from '../../../assets/images/698wSpeak.png'
import BriefWizard from '../../../assets/images/698wBriefDebrief.png'
import RuleWizard from '../../../assets/images/RuleWizard.png'
import DefinitionWizard from '../../../assets/images/DefinitionWizard.png'
import TestImageWizard from '../../../assets/images/TestImageWizard.png'
import DialogueWizard from '../../../assets/images/DialogueWizard.png'
import AssociationWizard from '../../../assets/images/AssociationWizard.png'
import InstructionWizard from '../../../assets/images/InstructionWizard.png'
import QuizWizard from '../../../assets/images/QuizWizard.png'
import { ReactComponent as SubtitleImage } from '../../../assets/images/SubtitleImage.svg'
import WatchWizard from '../../../assets/images/WatchWizard.png'
import QuizWizardAudio from '../../../assets/images/QuizWizardAudio.png'
import AudiostoryWizard from '../../../assets/images/AudiostoryWizard.png'
import ListenWizard from '../../../assets/images/698wListen.png'
import DebriefWizard from '../../../assets/images/698wBriefDebrief.png'
import DialogueStageUtterance from './ScenarioComponents/DialogueStageUtterance'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import ScenarioAnswer from './ScenarioAnswer'
import { lessonActions } from '../../../_state/actions'
import ScenarioMentorPreview from './ScenarioMentorPreview'
import Lin from '../../../assets/images/Lin.png'
import Ron from '../../../assets/images/Ron.png'
import Dropzone from 'react-dropzone'
import 'react-image-crop/dist/ReactCrop.css'
import CloseIcon from '@material-ui/icons/Close'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import ReactPlayer from 'react-player/lazy'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Rnd } from 'react-rnd'
import RuleCardRule from './RuleCardRule'
import MultiChoiceNodeModal from './ScenarioStages/MultiChoiceNodeModal'
import InteractionFillInBlankDistractors from './Interactions/InteractionFillInBlankDistractors'
import InteractionSentenceBuilder from './Interactions/InteractionSentenceBuilder'
import {
    AudioFieldType,
    AudioLibraryType,
    AudioSelectionOptionType,
    ScenarioInteractiveElementType,
    ScenarioNodeType,
    ScenarioTextType,
    CustomLessonAuthorNoteType,
    ScenarioAudioSelection,
    ScenarioAudioPlayType,
} from '../../../Logic/ScenarioConstants'
import RuleCardDisplay from './ScenerioMapNodeViews/RuleCardDisplay'
import RulecardModalContent from './ScenarioStages/RulecardModalContent'

import Keyboard from '../../../assets/images/Keyboard.png'
import MessageRoundedIcon from '@mui/icons-material/MessageRounded'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AudioPlayer from './ScenarioComponents/AudioPlayer'
import AudioRecorder from './ScenarioComponents/AudioRecorder'
import AudioLibrary from './Audio/AudioLibrary'

import VocabularySelector from './ScenarioComponents/VocabularySelector'

import UploadFile from './ScenarioComponents/UploadFile'
import TextFieldWithLanguageSelector from './ScenarioComponents/TextFieldWithLanguageSelector'

import { FileReferenceAudioUsageType } from '../../../Logic/FileReferenceConstants'
import InstructionStageEditNode from './EditNodeModules/InstructionStageEditNode'
import BackgroundImageSelection from './Images/BackgroundImageSelection'
import InteractionImage from './Interactions/InteractionImage'
import DialogStageEditNode from './EditNodeModules/DialogStageEditNode'
import ListenWithSliderEditNode from './EditNodeModules/ListenWithSliderEditNode'
import { getFlagAssetForLanguageId } from '../../../Logic/FlagUnicodeLogic'
import { Textfit } from 'react-textfit'
import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '98px',
        top: 0,
        position: 'fixed',
        zIndex: 100,
    },
    grow: {
        flexGrow: 1,
    },
    imageViewer: {
        maxWidth: '150px',
        minWidth: '50px',
        height: 'auto',
    },

    textFields: {
        width: '428px',
        textColor: 'white',
    },
    textFieldAnswers: {
        width: '348px',
        textColor: 'white',
    },

    feedback: {
        width: '428px',
        textColor: 'white',
        marginBottom: '20px',
    },

    answerHeaders: {
        paddingTop: '12px',
        paddingBottom: '8px',
        marginLeft: '13px',
    },
    myCustomClass: {
        fontSize: '1.2rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.4rem',
        },
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem',
    },
    answersSection: {
        marginBottom: '10px',
    },
    tabs: {
        backgroundColor: 'white',
    },
    buttongroupSection: {
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        paddingRight: '50px',
    },
    cardPlayButton: {
        height: '40px',
        backgroundColor: '#143349',
        display: 'flex',
        justify: 'flex-start',
        alignItems: 'center',
        width: '363px',
    },
    formControl: {
        borderRadius: '15px',
    },
    dropZone: {
        position: 'relative',
        marginTop: '10px',
        marginLeft: '10px',
        height: '115px',
        width: '500px',
        paddingRight: '10px',
        color: '#ccc',
        border: 'dashed 2px #ccc',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
        transition: 'all 200ms ease-out',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'block',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },

    dropzoneContainer: {
        marginTop: '20px',
    },

    playIcon: {
        color: '#eee',
        fontSize: 36,
        marginLeft: '15px',
        marginRight: '15px',
        cursor: 'pointer',
    },
    undoIcon: {},

    nodeTitle: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '30px',
        lineHeight: '22px',
        paddingBottom: '8px',
        color: '#35323C',
    },

    l1ChooserStyle: {
        marginLeft: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '60px',
        height: '60px',
        border: '5px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '7px',
        maxHeight: '40px',
        minWidth: '78px',
        minHeight: '40px',
    },

    nodeTypeSelectorStyle: {
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '350px',
        height: '60px',
        border: '5px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '7px',
        maxHeight: '40px',
        minWidth: '240px',
        minHeight: '40px',
    },

    buttonAnnouncement: {
        border: '5px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '40px',
        maxHeight: '40px',
        minWidth: '40px',
        minHeight: '40px',
        marginTop: '7px',
    },

    circularProgress: {
        color: '#eee',
        fontSize: 36,
        margin: '15px',
    },

    closeIcon: {
        color: 'white',
        paddingRight: '5px',
        cursor: 'pointer',
    },

    interactions: {
        paddingTop: '10px',
    },

    scenario: {
        paddingTop: '30px',
    },

    saveButton: {
        backgroundColor: '#143349',
        fontColor: 'white',
        color: 'white',
        textTransform: 'none',
    },
    textfieldWithDisable: {
        textTransform: 'none',
        backgroundColor: '#143349',
        color: 'white',
        '&:disabled': {
            backgroundColor: '#143349',
            color: 'grey',
        },
    },
    stageHeader: {
        boxShadow: 'none',
        color: 'black',
        fontFamily: 'Lato',
        fontSize: 32,
        fontWeight: 'bold',
    },
    sectionHeader: {
        boxShadow: 'none',
        color: 'black',
        fontFamily: 'Lato',
        fontSize: 22,
        fontWeight: 'bold',
    },

    scenarioDescription: {
        paddingTop: '3px',
    },

    multilineColor: {
        color: 'white',
    },

    scenarioDescriptionText: {
        boxShadow: 'none',
        fontFamily: 'Lato',
        letterSpacing: '0.02em',
        fontSize: 14,
        color: '#8E8E93',
    },

    scenarioScenesPreviewSection: {
        marginTop: '20px',
    },

    scenarioMentorPreviewComponent: {
        marginRight: '13px',
    },

    uploadIcon: {
        width: '100%',
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'block',
    },
    divList: {
        maxHeight: 430,
        overflow: 'auto',
    },

    list: {
        height: '1000px',
    },

    ruleTextField: {
        marginBottom: '10px',
    },
    ruleTitle: {
        paddingTop: '30px',
        marginBottom: '10px',
    },
    definitionText: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '15px',
    },
    testImageText: {
        fontSize: 11,
    },
    underscoreReplacement: {
        display: 'inline-block',
        background: 'rgba(68, 166, 239, 0.3)',
        width: '8px',
        height: '16px',
        verticalAlign: 'middle',
        position: 'relative',
        boxShadow: 'inset 0px 2px 0px rgba(102, 67, 160, 0.12)',
        // borderRadius: '4px'
    },
    pinkSquare: {
        backgroundColor: 'pink',
        width: '4px',
        height: '4px',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    explanationText: {
        fontSize: 12,
    },
    noCapitals: {
        textTransform: 'none',
    },

    imageLibraryButton: {
        textTransform: 'none',
        border: '1px solid #BDBDBD',
        boxSizing: 'border-box',
        borderRadius: '10px',
        width: '150px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },

    audioLibraryButton: {
        textTransform: 'none',
        border: '1px solid #BDBDBD',
        boxSizing: 'border-box',
        borderRadius: '10px',
        width: '150px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '&:disabled': {
            color: 'grey',
        },
    },

    typographySpeakout: {
        marginBottom: '5px',
        color: '#8e8e93',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    editNodeSectionTitle: {
        width: '70px',
        boxShadow: 'none',
        flex: 1,
        fontSize: 36,
        color: 'black',
        fontFamily: 'Lato',
    },
    editNodeSectionDescription: {
        boxShadow: 'none',
        letterSpacing: '0.02em',
        fontSize: 14,
        color: '#8E8E93',
        fontFamily: 'Lato',
    },
}))

const ScenarioEditNode = (props) => {
    const [readyToLoad, setReadyToLoad] = useState(false)
    const [robotSelected, setRobotSelected] = useState(0)

    const [testOptionChunkingMode, setTestOptionChunkingMode] = useState({})

    const [selectedMentor, setSelectedMentor] = React.useState(0)
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)

    // image related attributes
    const [imagePreviewFileUrl, setImagePreviewFileUrl] = useState(null)
    const [imagePreviewSrc, setImagePreviewSrc] = useState(null)

    const [imageAddress, setImageAddress] = useState('')
    const [definitionImageAddress, setDefinitionImageAddress] = useState('')
    const imgRef = useRef(null)
    var stageImageStyle = {
        backgroundSize: '100% auto',
    }

    // other
    const [languageTarget, setLanguageTarget] = useState('')
    const [backgroundAudioButton, setBackgroundAudioButton] = useState(ScenarioAudioSelection.DragAndDropUpload)
    const [sfxAudioButton, setSfxAudioButton] = useState(ScenarioAudioSelection.DragAndDropUpload)

    const [audioPlaying, setAudioPlaying] = useState(false)
    const [quizQuestionInFocus, setQuizQuestionInFocus] = useState(0)
    const [audioControls, setAudioControls] = useState(false)
    const [duration, setDuration] = useState(0)
    const [blobURL, setBlobURL] = useState(null)
    const audioRef = useRef(null)
    const [screenWidth, setScreenWidth] = useState(245)
    const [placementWidth, setPlacementWidth] = useState(436)
    const [placementHeightPadding, setPlacementHeightPadding] = useState(200)
    const [screenHeight, setScreenHeight] = useState(246) // 361
    const [screenHeightListen, setScreenHeightListen] = useState(100)
    const [maxScreenHeightForNormalized, setMaxScreenHeightForNormalized] = useState(375)
    const [minScreenHeightForNormalized, setMinScreenHeightForNormalized] = useState(220)
    const [mentorWidth, setMentorWidth] = useState(50)
    const [mentorHeight, setMentorHeight] = useState(100)
    const [mentorX, setMentorX] = useState(250)
    const [mentorY, setMentorY] = useState(0)
    const [alignment, setAlignment] = React.useState('left')
    const [recordSelected, setRecordSelected] = React.useState(false)
    const [selectedDialogueUtterance, setSelectedDialogueUtterance] = React.useState(-1)
    const [isRecording, setIsRecording] = React.useState(false)
    const [playing, setPlaying] = React.useState(false)
    const [isBlocked, setIsBlocked] = React.useState(true)
    const [type, setType] = React.useState(null)
    const [loop, setLoop] = React.useState(false)
    const [currentInteraction, setCurrentInteraction] = React.useState(0)
    const aceEditorRef = useRef()
    const ruleCardRefs = useRef([])
    const [ruleCardInFocus, setRuleCardInFocus] = React.useState(-1)
    const [puzzleBlockInFocus, setPuzzleBlockInFocus] = React.useState(0)

    const [displayErrors, setDisplayErrors] = React.useState(false)
    const [buttonSelected, setButtonSelected] = React.useState(0)
    const [openVocabularyImport, setOpenVocabularyImport] = React.useState(false)

    const [savingNode, setSavingNode] = useState(false)
    const [l1FriendlyName, setL1FriendlyName] = useState('')

    const handleAnswerClick = (node, index) => {
        // user chose an answer
        setSelectedAnswer(node.text)
        setSelectedAnswerIndex(index)
    }

    const recordModeSelected = () => {
        setButtonSelected(AudioSelectionOptionType.RecordAudio)
        setRecordSelected(true)
        setBlobURL(null)

        setLanguageTarget('')
        navigator.getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia

        if (isBlocked) {
            navigator.mediaDevices.getUserMedia(
                { audio: true },
                () => {
                    setIsBlocked(false)
                },
                () => {
                    setIsBlocked(true)
                }
            )
        }
    }

    useEffect(() => {
        props.onDisableCollabCheck(false)
        setL1FriendlyName(
            props.metadata.metadata.languages
                .filter((x) => x.id === props.lesson.selectedScenario.l1Id)
                .sort((a, b) => a.name.localeCompare(b.name))[0].friendlyName
        )
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setReadyToLoad(true)
        }, 900) // FIXME - Prevents backgroundSize not being applied to the image and zoomed in version appearing instead
    }, [props.node])

    useEffect(() => {
        if (props.node.data.testOptionImageUrl) {
            setImageAddress(props.node.data.testOptionImageUrl)
        } else {
            setImageAddress(props.node.data.imageUrl)
        }
        stageImageStyle = {
            position: 'absolute',
            top: '58px',
            left: '50px',
            width: '258px',
            height: '217px',
            background: 'url(' + imageAddress + ') no-repeat bottom',
            backgroundSize: '100% auto',
        }
        setReadyToLoad(true)
    }, [props.node.data.imageUrl, props.node.data.testOptionImageUrl])

    useEffect(() => {
        setDefinitionImageAddress(props.node.data.testOptionImageUrl)

        stageImageStyle = {
            position: 'absolute',
            top: '58px',
            left: '50px',
            width: '258px',
            height: '217px',
            background: 'url(' + imageAddress + ') no-repeat bottom',
            backgroundSize: '100% auto',
        }
        setReadyToLoad(true)
        // setTimeout(() => {  setReadyToLoad(true); }, 900); // FIXME - Prevents backgroundSize not being applied to the image and zoomed in version appearing instead
    }, [props.node.data.testOptionImageUrl])

    useEffect(() => {
        if (
            props.node.scenarioMentors &&
            props.node.scenarioMentors[0].screenObjectDto &&
            props.node.scenarioMentors[0].screenObjectDto.normalizedPosition
        ) {
            let y = props.node.scenarioMentors[0].screenObjectDto.normalizedPosition.y
            let x = props.node.scenarioMentors[0].screenObjectDto.normalizedPosition.x
            let screenHeight = maxScreenHeightForNormalized
            switch (props.node.scenarioNodeType) {
                case ScenarioNodeType.Listen:
                    screenHeight = minScreenHeightForNormalized
                    break
                case ScenarioNodeType.Speak:
                    screenHeight = minScreenHeightForNormalized
                    break
                case ScenarioNodeType.BranchingMultiChoice:
                    screenHeight = minScreenHeightForNormalized
                    break
                case ScenarioNodeType.MultiChoice:
                    screenHeight = minScreenHeightForNormalized
                    break
                case ScenarioNodeType.ListenMultiChoice:
                    screenHeight = minScreenHeightForNormalized
                    break
            }

            let mentorHeightTemp = props.node.scenarioMentors[0].screenObjectDto.normalizedSize.y * screenHeight
            let mentorWidthTemp = mentorHeightTemp * (160 / 320)
            setMentorWidth(mentorWidthTemp)
            setMentorHeight(mentorHeightTemp)
            let leftPlacement = (placementWidth - screenWidth) / 2

            let newPositionX = screenWidth * x + leftPlacement
            let innerCanvasPositionY = screenHeight * (1 - y) - mentorHeightTemp
            let newPositionY = innerCanvasPositionY + placementHeightPadding

            setMentorX(newPositionX)
            setMentorY(newPositionY)
            //setScreenHeight()
        }
    }, [props.node.scenarioMentors])

    const backgroundAudioSection = () => {
        return audioStageOptions(
            AudioLibraryType.BackgroundAudio,
            'Background Audio',
            'Choose the audio that plays in the background during the stage.',
            'No background audio is attached to this stage.',
            AudioFieldType.BackgroundAudioUrl,
            setBackgroundAudioButton,
            backgroundAudioButton
        )
    }

    const sfxAudioSection = () => {
        return audioStageOptions(
            AudioLibraryType.SoundEffect,
            'Sound effects',
            'Add a sound effect. This will play at the beginning of this stage.',
            'No sound effect is attached to this stage.',
            AudioFieldType.SfxAudioUrl,
            setSfxAudioButton,
            sfxAudioButton
        )
    }

    const handleTextFieldLostFocus = (event, answerIndex, option) => {}

    const handleFeedbackTextChange = (event, answerIndex, option) => {
        let scoreAlteration = 0
        let failure = false
        let scoreReasoning = event.target.value

        if (option && option.scoreAlteration) scoreAlteration = option.scoreAlteration
        if (option && option.failure) failure = option.failure

        props.updateAnswerText(null, answerIndex, props.node.id, failure, scoreAlteration, scoreReasoning)
        if (event.target.value === '') {
            //props.deleteAnswer(answerIndex, props.node.id)
        }
    }

    const handleRuleCardTitleChange = (event, answerIndex, option, scoreReasoning) => {
        props.updateRuleCardTitle(event.target.value, props.node.id) // TODO: add L1 -> L2 Support
    }

    const displayL2WithGapForDistractors = (text, alternatives) => {
        let textWithGaps = text
        let distractorGaps = highlightIterator(alternatives)
        distractorGaps.forEach((gap) => {
            for (let i = gap[0]; i < gap[1]; i++) {
                textWithGaps = setCharAt(textWithGaps, i, '_')
            }
        })
        return textWithGaps
    }

    const handleClickedModal = () => {
        setRuleCardInFocus(-1)
    }

    const handleAnswerTextChange = (event, answerIndex, option, scoreReasoning) => {
        let scoreAlteration = 0
        let failure = false
        if (option && option.scoreAlteration) scoreAlteration = option.scoreAlteration
        if (option && option.failure) failure = option.failure
        if (option && option.scoreReasoning) scoreReasoning = option.scoreReasoning
        props.updateAnswerText(event.target.value, answerIndex, props.node.id, failure, scoreAlteration, scoreReasoning)
        if (event.target.value === '') {
            props.deleteAnswer(answerIndex, props.node.id)
        }
    }

    const handleL1SubtitleTextChange = (text) => {
        props.updateL1SubtitleText(text)
    }

    const handleDebriefTextLanguageChanged = (languageChooserValue, answerIndex) => {
        if (
            (languageChooserValue !== undefined && languageChooserValue === 1) ||
            (languageChooserValue === 0 && answerIndex >= 0 && answerIndex <= 1)
        ) {
            var debriefIsInL1 = languageChooserValue === 0
            props.onChangeDebriefL1Id(props.node.id, answerIndex, debriefIsInL1)
        }
    }

    const handleDefinitionTextUpdated = (text, explanation = '') => {
        var explanationIsInL1 = props.node.testOptions[1].explanationLanguageId !== props.languageId
        props.updateScenarioDefinitionText(text, explanation, props.node.id, explanationIsInL1)
    }

    const handleScenarioBriefChange = (event) => {
        var briefIsInL1 = props.node.options[0].textLanguageId !== props.languageId
        props.updateScenarioBriefText(event.target.value, briefIsInL1)
    }

    const handleRuleCardTextChange = (event) => {
        console.log('TODO: handle rule card change')
    }

    const handleAddAlternative = (startIndex, text, testOption, type) => {
        props.updateRuleCardAlternative(startIndex, text, testOption, type)
    }

    const handleAddKeyboardTestDistractorLetters = (startIndex, text, testOption, type) => {
        props.updateAddKeyboardTestDistractorLetters(startIndex, text, testOption, type)
    }

    const onReorderTestOptions = (newOrder) => {
        props.onReorderTestOptions(newOrder, props.node)
    }

    const handleAddRule = (index) => {
        props.onAddRule(props.node)
    }

    const displayCorrectMentor = (mentor) => {
        switch (mentor) {
            case 3:
                return Ron
            default:
                return Lin
        }
    }

    const BoxMentor = () => (
        <div
            className="box"
            style={{
                margin: 0,
                height: '100%',
                background: 'url(' + displayCorrectMentor(props.node.scenarioMentors[0].mentor) + ') no-repeat bottom',
                backgroundSize: '100% auto',
            }}>
            <article className="media">
                <div className="media-left"></div>
                <div className="media-content">
                    <div className="content"></div>
                </div>
            </article>
        </div>
    )

    const handlePlayVoiceByAudioUrl = (audioBlobUrl) => {
        var audioTracker = props.selectedNodeAudioTrackers.find((x) => x.audioBlobUrl === audioBlobUrl)
        if (audioTracker) {
            setAudioPlaying(!!!audioPlaying)
            props.onPlaySelectedAudio(audioTracker)
        } else if (audioBlobUrl.includes('sound/file')) {
            // call play on the url itself, which will then also update the selected node audio trackers and contain this
            setAudioPlaying(!!!audioPlaying)
            props.onPlaySelectedAudioUrl(audioBlobUrl)
        }
    }

    const handlePlay = (audioTrackerId) => {
        setAudioPlaying(!!!audioPlaying)
        var audioTracker = props.selectedNodeAudioTrackers.find((x) => x.trackerId === audioTrackerId)
        if (audioTracker) {
            props.onPlaySelectedAudio(audioTracker)
        }
    }

    const handlePause = () => {
        setAudioPlaying(false)
    }

    const handleProgress = (state) => {}

    const handleDuration = (duration) => {
        setDuration(0)
    }

    const handleShowFeedbackText = (index) => {
        // to do - add a call on LessonBuilderController that adds the scoreReasoning attribute to node, or removes score reasoning (toggle)
        props.toggleReasoning(props.node.id, index)
    }

    const handleScoreAlteration = (currentNode, scoring, answerIndex) => {
        let failure = false
        let scoreAlteration = 0

        if (scoring === -1000) {
            failure = true
            scoreAlteration = -1000
        } else if (scoring === -1) {
            failure = false
            scoreAlteration = -1
        }

        let newNode = { ...currentNode, failure, scoreAlteration }
        props.updateAnswerText(newNode.text, answerIndex, props.node.id, failure, scoreAlteration)
    }

    const handleUpdateImagePreview = (blobUrl) => {
        setImagePreviewFileUrl(blobUrl)
    }

    const handleSaveStageOptions = () => {
        const { organization } = props
        setSavingNode(true)
        // upload image

        props.updateScenarioNode(props.node, organization.selectedOrganization)
    }

    const handleCancelStageOptions = (event, answerIndex) => {
        props.onClose()
    }

    const displayPhoneSurround = () => {
        if (props.node) {
            switch (props.node.scenarioNodeType) {
                case ScenarioNodeType.Watch:
                    return WatchWizard
                case ScenarioNodeType.Listen:
                    return ListenWizard
                case ScenarioNodeType.Speak:
                    return SpeakWizard
                case ScenarioNodeType.BranchingMultiChoice:
                    return SpeakWizard
                case ScenarioNodeType.Debrief:
                    return DebriefWizard
                case ScenarioNodeType.Brief:
                    return BriefWizard
                case ScenarioNodeType.TestRule:
                    return RuleWizard
                case ScenarioNodeType.TestDefinition:
                    return DefinitionWizard
                case ScenarioNodeType.TestImage:
                    return TestImageWizard
                case ScenarioNodeType.TestAssociationPuzzle:
                    return AssociationWizard
                case ScenarioNodeType.Instruction:
                    return InstructionWizard
                case ScenarioNodeType.TestDialog:
                    return DialogueWizard
                case ScenarioNodeType.TestListenWithSlider:
                    return AudiostoryWizard
                case ScenarioNodeType.MultiChoice:
                    return QuizWizard
                case ScenarioNodeType.ListenMultiChoice:
                    return QuizWizard
                default:
                    return SpeakWizard
            }
        }
        return SpeakWizard
    }

    const displayOptionVisual = (index, scenarioNodeType) => {
        switch (index) {
            case 0:
                switch (scenarioNodeType) {
                    case ScenarioNodeType.Watch:
                        return '353px'
                    case ScenarioNodeType.Listen:
                        return '353px'
                    case ScenarioNodeType.Speak:
                        return '353px'
                    case ScenarioNodeType.BranchingMultiChoice:
                        return '353px'
                    case ScenarioNodeType.MultiChoice:
                        return '353px'
                    case ScenarioNodeType.ListenMultiChoice:
                        return '353px'
                    case ScenarioNodeType.Debrief:
                        return '353px'
                    case ScenarioNodeType.Brief:
                        return '243px'
                    default:
                        return '353px'
                }

            case 1:
                switch (scenarioNodeType) {
                    case ScenarioNodeType.Watch:
                        return '397px'
                    case ScenarioNodeType.Listen:
                        return '397px'
                    case ScenarioNodeType.Speak:
                        return '397px'
                    case ScenarioNodeType.BranchingMultiChoice:
                        return '397px'
                    case ScenarioNodeType.MultiChoice:
                        return '397px'
                    case ScenarioNodeType.ListenMultiChoice:
                        return '397px'
                    case ScenarioNodeType.Debrief:
                        return '397px'
                    case ScenarioNodeType.Brief:
                        return '267px'
                    default:
                        return '397px'
                }
            case 2:
                return '441px'
            default:
                return SpeakWizard
        }
    }

    const displayStageOptions = () => {
        if (props.node) {
            switch (props.node.scenarioNodeType) {
                case ScenarioNodeType.Watch:
                    return watchStageOptions()
                case ScenarioNodeType.Listen:
                    return listenStageOptions()
                case ScenarioNodeType.Speak:
                    return speakStageOptions()
                case ScenarioNodeType.BranchingMultiChoice:
                    return speakStageOptions()
                case ScenarioNodeType.MultiChoice:
                    return multiChoiceStageOptions()
                case ScenarioNodeType.ListenMultiChoice:
                    return multiChoiceStageOptions()
                case ScenarioNodeType.Debrief:
                    return debriefStageOptions()
                case ScenarioNodeType.Brief:
                    return briefStageOptions()
                case ScenarioNodeType.TestRule:
                    return rulecardStageOptions()
                case ScenarioNodeType.TestDefinition:
                    return definitionStageOptions()
                case ScenarioNodeType.TestImage:
                    return imageStageOptions()
                case ScenarioNodeType.TestAssociationPuzzle:
                    return puzzleStageOptions()
                case ScenarioNodeType.Instruction:
                    return instructionStageOptions()
                case ScenarioNodeType.TestDialog:
                    return dialogStageOptions()
                case ScenarioNodeType.TestListenWithSlider:
                    return listenWithSliderOptions()
                default:
                    return
            }
        }
    }

    const handleRemoveVoice = (type = null, testOptionId = null) => {
        props.onVoiceDelete(type, testOptionId)
    }

    const handleEnded = () => {
        setPlaying(loop)
        setAudioPlaying(false)
    }

    const highlightIterator = (alternatives) => {
        let highlightArray = []
        let addedStartIndeces = []
        alternatives.forEach((alternative) => {
            if (!!!addedStartIndeces.includes(alternative.startIndex)) {
                highlightArray.push([alternative.startIndex, alternative.startIndex + alternative.text.length])
                addedStartIndeces.push(alternative.startIndex)
            }
        })
        return highlightArray
    }

    const handleSetMentorSize = (width, height, position) => {
        let localScreenHeight
        switch (props.node.scenarioNodeType) {
            case ScenarioNodeType.Watch:
                localScreenHeight = maxScreenHeightForNormalized
                break
            case ScenarioNodeType.Listen:
                localScreenHeight = minScreenHeightForNormalized
                break
            case ScenarioNodeType.Speak:
                localScreenHeight = minScreenHeightForNormalized
                break
            case ScenarioNodeType.BranchingMultiChoice:
                localScreenHeight = minScreenHeightForNormalized
                break
            case ScenarioNodeType.MultiChoice:
                localScreenHeight = minScreenHeightForNormalized
                break
            case ScenarioNodeType.ListenMultiChoice:
                localScreenHeight = minScreenHeightForNormalized
                break
        }

        let tempMentorHeight = parseFloat(height, 10)
        let newMentorWidth = parseFloat(width, 10)
        let positionX = parseFloat(position.x, 10)
        let positionY = parseFloat(position.y, 10)

        let leftOffset = (placementWidth - screenWidth) / 2
        let normalizedX = (position.x - leftOffset) / screenWidth

        let yPosForMentorLowerLeftCorner = position.y + tempMentorHeight - placementHeightPadding
        let normalizedY = 1 - yPosForMentorLowerLeftCorner / localScreenHeight
        let normalizedHeight = tempMentorHeight / localScreenHeight

        setMentorWidth(parseFloat(width, 10))
        setMentorHeight(tempMentorHeight)
        setMentorX(positionX)
        setMentorY(positionY)
        props.onMentorSizeChange(normalizedHeight, normalizedX, normalizedY)
    }

    const handleMoveMentor = (x, y) => {
        let localScreenHeight = 0
        switch (props.node.scenarioNodeType) {
            case ScenarioNodeType.Watch:
                localScreenHeight = maxScreenHeightForNormalized
                break
            case ScenarioNodeType.Listen:
                localScreenHeight = minScreenHeightForNormalized
                break
            case ScenarioNodeType.Speak:
                localScreenHeight = minScreenHeightForNormalized
                break
            case ScenarioNodeType.BranchingMultiChoice:
                localScreenHeight = minScreenHeightForNormalized
                break
            case ScenarioNodeType.MultiChoice:
                localScreenHeight = minScreenHeightForNormalized
                break
            case ScenarioNodeType.ListenMultiChoice:
                localScreenHeight = minScreenHeightForNormalized
                break
        }
        let newMentorWidth = parseFloat(mentorWidth, 10)
        let newMentorHeight = parseFloat(mentorHeight, 10)

        setMentorX(x)
        setMentorY(y)
        let yPosForMentorLowerLeftCorner = y + newMentorHeight - placementHeightPadding
        let leftOffset = (placementWidth - screenWidth) / 2
        let normalizedX = (x - leftOffset) / screenWidth
        let normalizedY = 1 - yPosForMentorLowerLeftCorner / localScreenHeight
        props.onMentorMove(normalizedX, normalizedY)
    }

    const displayStageBackgroundImage = () => {
        if (props.node) {
            let stageImageStyle = {}
            stageImageStyle.position = 'absolute'
            stageImageStyle.top = '56px' // 58px
            stageImageStyle.left = '42px'
            stageImageStyle.width = '261px'
            stageImageStyle.height = '230px'
            stageImageStyle.backgroundImage = 'url(' + imageAddress + ')'
            stageImageStyle.backgroundPosition = 'no-repeat bottom'
            stageImageStyle.backgroundSize = '100% auto'

            switch (props.node.scenarioNodeType) {
                case ScenarioNodeType.Watch:
                    stageImageStyle.height = '352px'
                    return stageImageStyle
                case ScenarioNodeType.Listen:
                    stageImageStyle.top = '55px'
                    stageImageStyle.left = '40px'
                    stageImageStyle.width = '264px'
                    stageImageStyle.height = '228px'
                    return stageImageStyle
                case ScenarioNodeType.Speak:
                    stageImageStyle.top = '55px'
                    return stageImageStyle
                case ScenarioNodeType.BranchingMultiChoice:
                    stageImageStyle.top = '55px'
                    return stageImageStyle
                case ScenarioNodeType.MultiChoice:
                    stageImageStyle.top = '50px'
                    stageImageStyle.left = '38px'
                    stageImageStyle.width = '264px'
                    stageImageStyle.height = '264px'
                    return stageImageStyle
                    return stageImageStyle
                case ScenarioNodeType.ListenMultiChoice:
                    stageImageStyle.top = '50px'
                    stageImageStyle.left = '38px'
                    stageImageStyle.width = '264px'
                    stageImageStyle.height = '264px'
                    return stageImageStyle
                case ScenarioNodeType.Debrief:
                    stageImageStyle.top = '56px'
                    stageImageStyle.left = '42px'
                    return stageImageStyle
                case ScenarioNodeType.Brief:
                    stageImageStyle.height = '180px'
                    stageImageStyle.left = '42px'
                    stageImageStyle.top = '56px' // 58px
                    return stageImageStyle
                case ScenarioNodeType.TestDefinition:
                    stageImageStyle.top = '98px' // 58px
                    stageImageStyle.left = '82px'
                    stageImageStyle.width = '176px'
                    stageImageStyle.height = '176px'
                    stageImageStyle.backgroundImage = 'url(' + definitionImageAddress + ')'
                    return stageImageStyle
                case ScenarioNodeType.Instruction:
                    stageImageStyle.top = '92px' // 58px
                    stageImageStyle.left = '94px'
                    stageImageStyle.width = '152px'
                    stageImageStyle.height = '152px'
                    stageImageStyle.backgroundImage = 'url(' + definitionImageAddress + ')'
                    return stageImageStyle
                case ScenarioNodeType.TestImage:
                    stageImageStyle.left = '110px'
                    stageImageStyle.top = '124px'
                    stageImageStyle.width = '120px'
                    stageImageStyle.backgroundImage = 'url(' + definitionImageAddress + ')'
                    return stageImageStyle
                default:
                    return stageImageStyle
            }
        }
    }

    const handleAudioItemClick = (urlType, audio, audioNiceName) => {
        props.updateNodeAudio(urlType, audio.apiUrl, props.node.nodeId, audio.niceName, audioNiceName)
        setAudioPlaying(false)
        setRecordSelected(false)
    }

    const handleRemoveBackgroundAudio = (audioFieldType, audioTrackerId) => {
        if (!props.canEditLesson) {
            return
        }

        props.updateNodeAudio(audioFieldType, null, props.node.nodeId, null, null, null, audioTrackerId, null, true)
    }

    const handleOnAudioDrop = (file, urlType, niceName, nodeId) => {
        props.onAudioDrop(file, urlType, niceName, nodeId)
    }

    const handleOnVoiceDrop = (file, testOptionId = null, niceName = '', localFile = true) => {
        const blobURL = URL.createObjectURL(file[0])

        props.onVoiceDrop(file[0], blobURL, niceName, testOptionId, localFile)
    }

    const handleOnMicVoiceDrop = (file, blobUrl, testOptionId = null, niceName = '', localFile = true) => {
        props.onVoiceDrop(file[0], blobUrl, niceName, testOptionId, localFile, true)
    }

    const handleAudioLibraryClick = (urlType, apiUrl, testOptionId) => {
        props.updateNodeAudio(urlType, apiUrl, props.node.nodeId, null, null, testOptionId)
        setAudioPlaying(false)
        setRecordSelected(false)
    }

    const handleSetQuizQuestionInFocus = (index) => {
        setQuizQuestionInFocus(index)
    }

    const handleUpdateSelectedDialogueUtterance = (index) => {
        setSelectedDialogueUtterance(index)
    }

    const handleMentorSelect = (event, value) => {
        setSelectedMentor(event)
        props.onMentorSelected(event)
    }

    const handleGenerateSuggestion = (text, optionIndex) => {
        // TODO: - add a call on LessonBuilderController that adds the scoreReasoning attribute to node, or removes score reasoning (toggle)
        props.onSuggestalternative(props.node.id, optionIndex, false)
    }

    const handleBranchingMultiChoiceLanguageTypeChange = (languageChooserValue) => {
        if (languageChooserValue === 1) {
            props.handleBranchingMultiChoiceQuestionLanguageIdChanged(false)
        } else {
            props.handleBranchingMultiChoiceQuestionLanguageIdChanged(true)
        }
    }

    const authorCommentsToggle = () => {
        return (
            <div
                style={{
                    marginTop: '20px',
                    position: 'absolute',
                    left: '700px',
                    minWidth: '200px',
                    display: 'flex',
                    textAlign: 'right',
                    alignItems: 'right',
                    justifyContent: 'right',
                    boxShadow: 'none',
                    flex: 1,
                    fontSize: 6,
                    color: 'black',
                    fontFamily: 'Lato',
                }}>
                <IconButton onClick={props.toggleDisplayComments} size="medium">
                    <Badge
                        variant="dot"
                        invisible={
                            !(props.node?.authorNoteDtos?.filter(
                                (x) => x.authorNoteType === CustomLessonAuthorNoteType.Created
                            ).length > 0
                                ? true
                                : false)
                        }
                        sx={{
                            '& .MuiBadge-badge': {
                                color: '#FF5159',
                                backgroundColor: '#FF5159',
                            },
                        }}>
                        <MessageRoundedIcon style={{ marginTop: '2px' }} />
                    </Badge>
                    <Typography
                        variant="body1"
                        style={{
                            marginLeft: '15px',
                            justifyContent: 'right',
                            boxShadow: 'none',
                            fontSize: 16,
                            color: 'black',
                            fontFamily: 'Lato',
                        }}>
                        Show comments
                    </Typography>
                </IconButton>
            </div>
        )
    }

    const speakStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '528px', marginTop: '70px' }}>
                <React.Fragment>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h6" className={classes.stageHeader}>
                            Branching
                        </Typography>
                        <Typography
                            style={{
                                boxShadow: 'none',
                                letterSpacing: '0.02em',
                                fontSize: 14,
                                color: '#8E8E93',
                                fontFamily: 'Lato',
                            }}>
                            A speaking or tapping stage that's perfect for role plays. Your learners can choose between
                            one to three answers.
                        </Typography>
                        <Typography
                            style={{
                                boxShadow: 'none',
                                letterSpacing: '0.02em',
                                fontSize: 14,
                                color: '#8E8E93',
                                fontFamily: 'Lato',
                                marginTop: '10px',
                            }}>
                            Each answer can take the lesson in a completely different direction, allowing you to make
                            engaging, interactive and replayable stories.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h6" className={classes.sectionHeader} style={{ marginTop: '30px' }}>
                            Answer mode
                        </Typography>

                        <Typography
                            style={{
                                boxShadow: 'none',
                                letterSpacing: '0.02em',
                                fontSize: 14,
                                color: '#8E8E93',
                                fontFamily: 'Lato',
                            }}>
                            There are two interaction modes to choose between: Speaking and Tapping. Choose how you wish
                            your learners to choose an answer.
                        </Typography>
                    </Grid>
                </React.Fragment>

                <Grid container>
                    {speakAndBranchingMultiChoiceSelector()}
                    <BackgroundImageSelection
                        canEditLesson={props.canEditLesson}
                        node={props.node}
                        lesson={props.lesson}
                        updateImageDrop={props.updateImageDrop}
                        scenarioImages={props.scenarioImages}
                        onUpdatePreviewImage={handleUpdateImagePreview}
                    />

                    {props.node.scenarioNodeType === ScenarioNodeType.BranchingMultiChoice && (
                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                            <Typography variant="body1" className={classes.sectionHeader}>
                                Question (optional)
                            </Typography>
                            <Typography
                                style={{
                                    boxShadow: 'none',
                                    letterSpacing: '0.02em',
                                    fontSize: 14,
                                    color: '#8E8E93',
                                    fontFamily: 'Lato',
                                }}>
                                If you wish, add a question that appears above the answers.
                            </Typography>
                        </Grid>
                    )}
                    {props.node.scenarioNodeType === ScenarioNodeType.BranchingMultiChoice && (
                        <Grid item xs={12} sm={12} md={12} style={{ paddingRight: '50px', marginTop: '10px' }}>
                            <TextFieldWithLanguageSelector
                                text={props.node.texts.find((x) => x.textType === ScenarioTextType.Question)?.text}
                                disabled={!props.canEditLesson}
                                onChange={(event) =>
                                    props.handleBranchingMultiChoiceQuestionChanged(event.target.value)
                                }
                                onChangeLanguageLanguage={(e) =>
                                    handleBranchingMultiChoiceLanguageTypeChange(e.target.value)
                                }
                                l2Id={props.languageId}
                                l1Id={props.l1Id}
                                soloLanguageId={props.l1Id}
                                canChangeLanguage={true}
                                displayL1OrL2={
                                    props.node.texts.find((x) => x.textType === ScenarioTextType.Question)
                                        ?.textLanguageId === props.languageId
                                        ? 1
                                        : 0
                                }
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12} style={{ paddingRight: '5px', marginTop: '20px' }}>
                        <Typography variant="body1" className={classes.sectionHeader}>
                            Answers
                        </Typography>
                        <Typography
                            variant="body1"
                            style={{
                                boxShadow: 'none',
                                letterSpacing: '0.02em',
                                fontSize: 14,
                                color: '#8E8E93',
                                fontFamily: 'Lato',
                                marginTop: '10px',
                            }}>
                            Add alternative answers for your students to choose between. Answers can lower the user's
                            score or cause them to fail the scenario.
                        </Typography>
                        <div className={classes.answersSection}>
                            <Typography
                                style={{
                                    fontFamily: 'Lato',
                                    fontWeight: 'bold',
                                }}
                                className={classes.answerHeaders}
                                variant="body1">
                                Answer 1
                            </Typography>

                            <Grid container>
                                <Grid item style={{}}>
                                    <TextFieldWithLanguageSelector
                                        text={props.node.options.length > 0 ? props.node.options[0].text : ''}
                                        width="304px"
                                        disabled={!props.canEditLesson}
                                        onChange={(event) => handleAnswerTextChange(event, 0, props.node.options[0])}
                                        l2Id={null}
                                        l1Id={null}
                                        soloLanguageId={props.languageId}
                                        canChangeLanguage={false}
                                    />
                                </Grid>
                                <Grid item style={{}}>
                                    <Select
                                        style={{
                                            marginLeft: '9px',
                                            paddingLeft: '9px',
                                            paddingRight: '9px',
                                            height: '55px',
                                            border: '5px solid #143349',
                                            boxSizing: 'border-box',
                                            borderRadius: '10px',
                                        }}
                                        disableUnderline
                                        value={
                                            props.node.options[0] &&
                                            props.node.options[0].failure &&
                                            props.node.options[0].failure === true
                                                ? -1000
                                                : props.node.options[0] &&
                                                    props.node.options[0].scoreAlteration &&
                                                    props.node.options[0].scoreAlteration < 0
                                                  ? -1
                                                  : 0
                                        }
                                        disabled={
                                            !!!props.node.options ||
                                            props.node.options.length === 0 ||
                                            !props.canEditLesson
                                        }
                                        onChange={(e) =>
                                            handleScoreAlteration(props.node.options[0], e.target.value, 0)
                                        }
                                        autoWidth>
                                        <MenuItem disabled={!props.canEditLesson} value={0}>
                                            Correct
                                        </MenuItem>
                                        <MenuItem disabled={!props.canEditLesson} value={-1}>
                                            Incorrect
                                        </MenuItem>
                                        <MenuItem disabled={!props.canEditLesson} value={-1000}>
                                            Fails lesson
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item style={{ textAlign: 'center' }}>
                                    <FormControlLabel
                                        style={{ marginLeft: '7px' }}
                                        control={
                                            <Checkbox
                                                checked={
                                                    props.node.options[0] &&
                                                    (props.node.options[0].scoreReasoning ||
                                                        props.node.options[0].scoreReasoning === '')
                                                }
                                                onClick={() => handleShowFeedbackText(0)}
                                                color="primary"
                                                disabled={
                                                    !!!props.node.options ||
                                                    props.node.options.length === 0 ||
                                                    !props.canEditLesson
                                                }
                                            />
                                        }
                                        label={'Feedback'}
                                        labelPlacement="top"
                                    />
                                </Grid>
                            </Grid>
                            {props.node.options[0] &&
                                (props.node.options[0].scoreReasoning ||
                                    props.node.options[0].scoreReasoning === '') && (
                                    <div style={{ marginLeft: '30px' }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        fontWeight: 'italic',
                                                    }}
                                                    className={classes.answerHeaders}
                                                    variant="body1">
                                                    Write feedback for answer 1. This is displayed at the end of the
                                                    lesson. (Optional)
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <TextFieldWithLanguageSelector
                                            text={
                                                props.node.options.length > 0 && props.node.options[0].scoreReasoning
                                                    ? props.node.options[0].scoreReasoning
                                                    : ''
                                            }
                                            disabled={!props.canEditLesson}
                                            onChange={(event) =>
                                                handleFeedbackTextChange(event, 0, props.node.options[0])
                                            }
                                            onChangeLanguageLanguage={(e) =>
                                                handleLanguageForScoreReasoning(e.target.value, 0)
                                            }
                                            l2Id={props.languageId}
                                            l1Id={props.l1Id}
                                            soloLanguageId={props.l1Id}
                                            canChangeLanguage={true}
                                            displayL1OrL2={
                                                props.node.options[0].scoreReasoningLanguageId === props.languageId
                                                    ? 1
                                                    : 0
                                            }
                                        />
                                    </div>
                                )}

                            <Typography
                                style={{
                                    fontFamily: 'Lato',
                                    fontWeight: 'bold',
                                }}
                                className={classes.answerHeaders}
                                variant="body1">
                                Answer 2
                            </Typography>

                            <Grid container>
                                <Grid item style={{}}>
                                    <TextFieldWithLanguageSelector
                                        text={props.node.options.length > 1 ? props.node.options[1].text : ''}
                                        width="304px"
                                        disabled={props.node.options.length < 1 || !props.canEditLesson}
                                        onChange={(event) => handleAnswerTextChange(event, 1, props.node.options[1])}
                                        onBlur={(event) => handleTextFieldLostFocus(event, 1, props.node.options[1])}
                                        l2Id={null}
                                        l1Id={null}
                                        soloLanguageId={props.languageId}
                                        canChangeLanguage={false}
                                    />
                                </Grid>
                                <Grid item style={{}}>
                                    <Select
                                        style={{
                                            marginLeft: '9px',
                                            paddingLeft: '9px',
                                            paddingRight: '9px',
                                            //width: '60px',
                                            height: '55px',
                                            border: '5px solid #143349',
                                            boxSizing: 'border-box',
                                            borderRadius: '10px',
                                            //maxWidth: '7px',
                                            //maxHeight: '40px',
                                            //minWidth: '78px',
                                            //minHeight: '40px',
                                            //marginTop: '7px'
                                        }}
                                        disableUnderline
                                        value={
                                            props.node.options[1] &&
                                            props.node.options[1].failure &&
                                            props.node.options[1].failure === true
                                                ? -1000
                                                : props.node.options[1] &&
                                                    props.node.options[1].scoreAlteration &&
                                                    props.node.options[1].scoreAlteration < 0
                                                  ? -1
                                                  : 0
                                        }
                                        disabled={
                                            !!!props.node.options ||
                                            props.node.options.length <= 1 ||
                                            !props.canEditLesson
                                        }
                                        onChange={(e) =>
                                            handleScoreAlteration(props.node.options[1], e.target.value, 1)
                                        }
                                        autoWidth>
                                        <MenuItem disabled={!props.canEditLesson} value={0}>
                                            Correct
                                        </MenuItem>
                                        <MenuItem disabled={!props.canEditLesson} value={-1}>
                                            Incorrect
                                        </MenuItem>
                                        <MenuItem disabled={!props.canEditLesson} value={-1000}>
                                            Fails lesson
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item style={{ textAlign: 'center' }}>
                                    <FormControlLabel
                                        style={{ marginLeft: '7px' }}
                                        control={
                                            <Checkbox
                                                checked={
                                                    props.node.options[1] &&
                                                    (props.node.options[1].scoreReasoning ||
                                                        props.node.options[1].scoreReasoning === '')
                                                }
                                                disabled={
                                                    !!!props.node.options ||
                                                    props.node.options.length <= 1 ||
                                                    !props.canEditLesson
                                                }
                                                onClick={() => handleShowFeedbackText(1)}
                                                color="primary"
                                            />
                                        }
                                        label={'Feedback'}
                                        labelPlacement="top"
                                    />
                                </Grid>
                            </Grid>
                            {props.node.options?.length > 1 &&
                                props.node.options[1] &&
                                (props.node.options[1].scoreReasoning ||
                                    props.node.options[1].scoreReasoning === '') && (
                                    <div style={{ marginLeft: '30px' }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        fontWeight: 'italic',
                                                    }}
                                                    className={classes.answerHeaders}
                                                    variant="body1">
                                                    Write feedback for answer 2. This is displayed at the end of the
                                                    lesson. (Optional)
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <TextFieldWithLanguageSelector
                                            text={
                                                props.node.options.length > 0 && props.node.options[1].scoreReasoning
                                                    ? props.node.options[1].scoreReasoning
                                                    : ''
                                            }
                                            disabled={!props.canEditLesson}
                                            width={'350px'}
                                            onChange={(event) =>
                                                handleFeedbackTextChange(event, 1, props.node.options[1])
                                            }
                                            onChangeLanguageLanguage={(e) =>
                                                handleLanguageForScoreReasoning(e.target.value, 1)
                                            }
                                            l2Id={props.languageId}
                                            l1Id={props.l1Id}
                                            soloLanguageId={props.l1Id}
                                            canChangeLanguage={true}
                                            displayL1OrL2={
                                                props.node.options[1].scoreReasoningLanguageId === props.languageId
                                                    ? 1
                                                    : 0
                                            }
                                        />
                                    </div>
                                )}

                            <Typography
                                style={{
                                    fontFamily: 'Lato',
                                    fontWeight: 'bold',
                                }}
                                className={classes.answerHeaders}
                                variant="body1">
                                Answer 3
                            </Typography>
                            <Grid container>
                                <Grid item style={{}}>
                                    <TextFieldWithLanguageSelector
                                        text={props.node.options.length > 2 ? props.node.options[2].text : ''}
                                        width="304px"
                                        disabled={props.node.options.length < 2 || !props.canEditLesson}
                                        onChange={(event) => handleAnswerTextChange(event, 2, props.node.options[2])}
                                        //onBlur={(event) => handleTextFieldLostFocus(event, 2, props.node.options[2])}
                                        l2Id={null}
                                        l1Id={null}
                                        soloLanguageId={props.languageId}
                                        canChangeLanguage={false}
                                    />
                                </Grid>
                                <Grid item style={{}}>
                                    <Select
                                        style={{
                                            marginLeft: '9px',
                                            paddingLeft: '9px',
                                            paddingRight: '9px',
                                            //width: '60px',
                                            height: '55px',
                                            border: '5px solid #143349',
                                            boxSizing: 'border-box',
                                            borderRadius: '10px',
                                            //maxWidth: '7px',
                                            //maxHeight: '40px',
                                            //minWidth: '78px',
                                            //minHeight: '40px',
                                            //marginTop: '7px'
                                        }}
                                        disableUnderline
                                        value={
                                            props.node.options[2] &&
                                            props.node.options[2].failure &&
                                            props.node.options[2].failure === true
                                                ? -1000
                                                : props.node.options[2] &&
                                                    props.node.options[2].scoreAlteration &&
                                                    props.node.options[2].scoreAlteration < 0
                                                  ? -1
                                                  : 0
                                        }
                                        disabled={
                                            !!!props.node.options ||
                                            props.node.options.length <= 2 ||
                                            !props.canEditLesson
                                        }
                                        onChange={(e) =>
                                            handleScoreAlteration(props.node.options[2], e.target.value, 2)
                                        }
                                        autoWidth>
                                        <MenuItem disabled={!props.canEditLesson} value={0}>
                                            Correct
                                        </MenuItem>
                                        <MenuItem disabled={!props.canEditLesson} value={-1}>
                                            Incorrect
                                        </MenuItem>
                                        <MenuItem disabled={!props.canEditLesson} value={-1000}>
                                            Fails lesson
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item style={{ textAlign: 'center' }}>
                                    <FormControlLabel
                                        style={{ marginLeft: '7px' }}
                                        control={
                                            <Checkbox
                                                checked={
                                                    props.node.options[2] &&
                                                    (props.node.options[2].scoreReasoning ||
                                                        props.node.options[2].scoreReasoning === '')
                                                }
                                                disabled={
                                                    !!!props.node.options ||
                                                    props.node.options.length <= 2 ||
                                                    !props.canEditLesson
                                                }
                                                onClick={() => handleShowFeedbackText(2)}
                                                color="primary"
                                            />
                                        }
                                        label={'Feedback'}
                                        labelPlacement="top"
                                    />
                                </Grid>
                            </Grid>
                            {props.node.options?.length > 2 &&
                                props.node.options[2] &&
                                (props.node.options[2].scoreReasoning ||
                                    props.node.options[2].scoreReasoning === '') && (
                                    <div style={{ marginLeft: '30px' }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        fontWeight: 'italic',
                                                    }}
                                                    className={classes.answerHeaders}
                                                    variant="body1">
                                                    Write feedback for answer 3. This is displayed at the end of the
                                                    lesson. (Optional)
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <TextFieldWithLanguageSelector
                                            value={
                                                props.node.options.length > 2 && props.node.options[2].scoreReasoning
                                                    ? props.node.options[2].scoreReasoning
                                                    : ''
                                            }
                                            disabled={!props.canEditLesson}
                                            width={'350px'}
                                            onChange={(event) =>
                                                handleFeedbackTextChange(event, 2, props.node.options[2])
                                            }
                                            onChangeLanguageLanguage={(e) =>
                                                handleLanguageForScoreReasoning(e.target.value, 2)
                                            }
                                            l2Id={props.languageId}
                                            l1Id={props.l1Id}
                                            soloLanguageId={props.l1Id}
                                            canChangeLanguage={true}
                                            displayL1OrL2={
                                                props.node.options[2].scoreReasoningLanguageId === props.languageId
                                                    ? 1
                                                    : 0
                                            }
                                        />
                                    </div>
                                )}

                            {backgroundAudioSection()}
                            {mentorStageOptions()}
                            {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                            {saveOrCancelStage()}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const sentenceBuildWordGroups = (alternatives) => {
        let isSentenceBuilder = true

        alternatives.sort((a, b) => b.startIndex + a.startIndex).forEach((alternative) => {})
    }

    const isHumanVoiceAdded = (option) => {
        return option?.textAudioUrl?.includes('snippet') === false
    }

    const handleChangeRuleCardDistractor = (distractor, ruleCard, testOption) => {
        props.onChangeRuleCardDistractor(distractor, ruleCard, testOption)
    }

    const handleOnNodeInteractionScoringChanged = (testOptionIndex, shouldBeScored) => {
        props.handleOnNodeInteractionScoringChanged(testOptionIndex, shouldBeScored)
    }

    const setLanguageForTestOption = (testOption, testOptionIsInL1) => {
        props.onTestOptionTextLanguageTypeChange(testOption, testOptionIsInL1)
    }

    const handleToggleRuleCardDistractorCorrect = (testOptionId, alternative) => {
        props.onToggleRuleCardDistractorCorrect(testOptionId, alternative)
    }

    const handleNodeInteractionScoringChanged = (testOptionIndex, shouldBeScored) => {
        props.onNodeInteractionScoringChanged(testOptionIndex, shouldBeScored)
    }

    const handleDeleteAlternativeDistractor = (testOptionId, alternative) => {
        props.onDeleteAlternativeDistractor(testOptionId, alternative)
    }

    const handleAddDistractorWithinSentence = (text) => {
        //props.onChangeTextImageQuestion(props.node.testOptions[1].testOptionId, text)
    }

    const setCharAt = (str, index, chr) => {
        if (index > str.length - 1) return str
        return str.substring(0, index) + chr + str.substring(index + 1)
    }

    const handleDistractorDisplayTest = (testOption) => {
        let textWithGaps = testOption.text
        let distractorGaps = highlightIterator(testOption.alternatives)
        distractorGaps.forEach((gap) => {
            for (let i = gap[0]; i < gap[1]; i++) {
                textWithGaps = setCharAt(textWithGaps, i, '_')
            }
        })
        return textWithGaps
        //props.onChangeTextImageQuestion(props.node.testOptions[1].testOptionId, text)
    }

    const handleEditDistractors = (arrayLetters, type, text, typeInt, testOptionIndex) => {
        let testOptionId = props.node.testOptions[testOptionIndex].testOptionId
        testOptionChunkingMode[testOptionId] = typeInt

        props.onEditDistractors(arrayLetters, type, testOptionIndex)
    }

    const setCurrentChunkModeForTestOptionId = (testOptionId, chunkMode) => {
        return (testOptionChunkingMode[testOptionId] = chunkMode)
    }
    const getCurrentChunkingModeForTestOptionId = (testOptionId) => {
        if (testOptionId in testOptionChunkingMode) {
            return testOptionChunkingMode[testOptionId]
        }
    }

    const handleCreatePuzzleBlocks = (blocksToAdd) => {
        props.onAddPuzzleBlocksFromExisting(blocksToAdd)
    }

    const displayInteraction = (
        alternatives,
        scenarioInteractiveElementType,
        testOptionIndex,
        localScenarioNodeType,
        placeholder,
        allowLanguageSelection,
        changeLanguage
    ) => {
        switch (scenarioInteractiveElementType) {
            case ScenarioInteractiveElementType.FillInBlankWriting:
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                style={{
                                    boxShadow: 'none',
                                    letterSpacing: '0.02em',
                                    fontSize: 14,
                                    color: '#8E8E93',
                                    fontFamily: 'Lato',
                                    marginBottom: '10px',
                                }}>
                                Highlight any part of the text below to turn it into a spelling test.
                            </Typography>
                        </Grid>
                        <RuleCardRule
                            addAlternativeText={'Make into a spelling test'}
                            placeholder={placeholder}
                            onDeleteRule={props.onDeleteRule}
                            onDistractorTypeChange={props.onDistractorTypeChange}
                            onRulecardTextChange={props.onRulecardTextChange}
                            onHighlightIterator={highlightIterator}
                            isInFocus={true}
                            onScrollIntoView={handleScrollIntoView}
                            onToggleCorrect={props.onToggleCorrect}
                            onAddDistractor={props.onAddDistractor}
                            onDeleteDistractor={props.onDeleteDistractor}
                            onChangeRuleCardDistractor={handleChangeRuleCardDistractor}
                            onToggleRuleCardDistractorCorrect={handleToggleRuleCardDistractorCorrect}
                            onDeleteAlternativeDistractor={handleDeleteAlternativeDistractor}
                            node={props.node}
                            localScenarioNodeType={localScenarioNodeType}
                            scenarioInteractiveElementType={scenarioInteractiveElementType}
                            index={testOptionIndex}
                            onRuleCardTextChange={handleRuleCardTextChange}
                            testOption={props.node.testOptions[testOptionIndex]}
                            languageId={props.languageId}
                            l1Id={props.l1Id}
                            onAddAlternative={(selectionStart, text, testOption) =>
                                handleAddAlternative(selectionStart, text, testOption, type)
                            }
                            onAddKeyboardTestDistractorLetters={(selectionStart, text, testOption) =>
                                handleAddKeyboardTestDistractorLetters(selectionStart, text, testOption, type)
                            }
                            canEditLesson={props.canEditLesson}
                            onNodeInteractionScoringChanged={handleNodeInteractionScoringChanged}
                            allowLanguageSelection={allowLanguageSelection}
                            changeLanguage={changeLanguage}
                            noiseSuppression={props.noiseSuppression}
                            onToggleNoiseSuppression={() => props.onToggleNoiseSuppression()}
                        />
                    </React.Fragment>
                )
            case ScenarioInteractiveElementType.Text:
                return (
                    <RuleCardRule
                        placeholder={placeholder}
                        onDeleteRule={props.onDeleteRule}
                        onDistractorTypeChange={props.onDistractorTypeChange}
                        onRulecardTextChange={props.onRulecardTextChange}
                        onHighlightIterator={highlightIterator}
                        isInFocus={true}
                        onScrollIntoView={handleScrollIntoView}
                        onToggleCorrect={props.onToggleCorrect}
                        onAddDistractor={props.onAddDistractor}
                        onDeleteDistractor={props.onDeleteDistractor}
                        onChangeRuleCardDistractor={handleChangeRuleCardDistractor}
                        onToggleRuleCardDistractorCorrect={handleToggleRuleCardDistractorCorrect}
                        onDeleteAlternativeDistractor={handleDeleteAlternativeDistractor}
                        node={props.node}
                        localScenarioNodeType={localScenarioNodeType}
                        scenarioInteractiveElementType={scenarioInteractiveElementType}
                        index={testOptionIndex}
                        onRuleCardTextChange={handleRuleCardTextChange}
                        testOption={props.node.testOptions[testOptionIndex]}
                        languageId={props.languageId}
                        l1Id={props.l1Id}
                        onAddAlternative={(selectionStart, text, testOption) =>
                            handleAddAlternative(selectionStart, text, testOption, type)
                        }
                        onAddKeyboardTestDistractorLetters={(selectionStart, text, testOption) =>
                            handleAddKeyboardTestDistractorLetters(selectionStart, text, testOption, type)
                        }
                        canEditLesson={props.canEditLesson}
                        onNodeInteractionScoringChanged={handleNodeInteractionScoringChanged}
                        allowLanguageSelection={allowLanguageSelection}
                        changeLanguage={changeLanguage}
                    />
                )
            case ScenarioInteractiveElementType.FillInBlankDistractors:
                if (detectInteractionMode(alternatives, props.node.testOptions[testOptionIndex].text) === true) {
                    return (
                        <InteractionSentenceBuilder
                            onUpdateChunkingMode={setCurrentChunkModeForTestOptionId}
                            onToggleCorrect={props.onToggleCorrect}
                            onDeleteDistractor={props.onDeleteDistractor}
                            onEditDistractors={handleEditDistractors}
                            onAddDistractor={props.onAddDistractor}
                            testOption={props.node.testOptions[testOptionIndex]}
                            testOptionIndex={testOptionIndex}
                            text={props.node.testOptions[testOptionIndex].text}
                            onEditText={props.onEditText}
                            alternatives={props.node.testOptions[testOptionIndex].alternatives.sort(
                                (a, b) => b.startIndex + a.startIndex
                            )}
                        />
                    )
                } else {
                    return (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#8E8E93',
                                        fontFamily: 'Lato',
                                        marginBottom: '10px',
                                    }}>
                                    Highlight any part of the interaction text to turn it into a multiple choice test
                                </Typography>
                            </Grid>
                            <RuleCardRule
                                addAlternativeText={'Make into a multiple choice test'}
                                placeholder={placeholder}
                                onDeleteRule={props.onDeleteRule}
                                onDistractorTypeChange={props.onDistractorTypeChange}
                                onRulecardTextChange={props.onRulecardTextChange}
                                onHighlightIterator={highlightIterator}
                                isInFocus={true}
                                onScrollIntoView={handleScrollIntoView}
                                onToggleCorrect={props.onToggleCorrect}
                                onAddDistractor={props.onAddDistractor}
                                localScenarioNodeType={localScenarioNodeType}
                                onDeleteDistractor={props.onDeleteDistractor}
                                onChangeRuleCardDistractor={handleChangeRuleCardDistractor}
                                onToggleRuleCardDistractorCorrect={handleToggleRuleCardDistractorCorrect}
                                onDeleteAlternativeDistractor={handleDeleteAlternativeDistractor}
                                node={props.node}
                                index={testOptionIndex}
                                onRuleCardTextChange={handleRuleCardTextChange}
                                testOption={props.node.testOptions[testOptionIndex]}
                                languageId={props.languageId}
                                l1Id={props.l1Id}
                                onAddAlternative={(selectionStart, text, testOption) =>
                                    handleAddAlternative(selectionStart, text, testOption, type)
                                }
                                onAddKeyboardTestDistractorLetters={(selectionStart, text, testOption) =>
                                    handleAddKeyboardTestDistractorLetters(selectionStart, text, testOption, type)
                                }
                                canEditLesson={props.canEditLesson}
                                onNodeInteractionScoringChanged={handleNodeInteractionScoringChanged}
                                allowLanguageSelection={allowLanguageSelection}
                                changeLanguage={changeLanguage}
                                noiseSuppression={props.noiseSuppression}
                                onToggleNoiseSuppression={() => props.onToggleNoiseSuppression()}
                            />
                        </React.Fragment>
                    )
                }
        }
    }

    const detectInteractionMode = (alternatives, text) => {
        if (text === '') return false
        sentenceBuildWordGroups(alternatives)
        let total = text.length
        let next = 0
        let areMultipleAtStartIndex = false
        alternatives.forEach((alt) => {
            for (let x = 0; x <= total; x++) {
                if (x >= alt.startIndex && x <= alt.startIndex + alt.text.length) {
                    next++
                }
            }
            alternatives.forEach((alt2) => {
                if (alt.startIndex === alt2.startIndex && alt.textSnippetId !== alt2.textSnippetId) {
                    areMultipleAtStartIndex = true
                }
            })
        })
        return next >= total && !!!areMultipleAtStartIndex && alternatives.length > 1
    }

    const handleDeletePuzzleBlockPair = (index) => {
        setPuzzleBlockInFocus(0)
        props.onDeletePuzzleBlockPair(index)
    }

    const puzzleStageBlock = (alternative, index) => {
        return (
            <React.Fragment>
                <Grid item xs={5} sm={5} md={5} style={{ marginBottom: '20px' }}>
                    <TextField
                        multiline
                        value={alternative.text}
                        disabled={!props.canEditLesson}
                        onClick={(event) => setPuzzleBlockInFocus(index)}
                        onChange={(event) =>
                            props.onChangePuzzleBlockText(alternative.textSnippetId, event.target.value, 'left')
                        }
                        label={'Left side block'}
                        style={{ width: '200px', borderRadius: '10px' }}
                        InputProps={{ disableUnderline: true, shrink: 'false' }}
                        className={classes.textFields}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={5} sm={5} md={5} style={{ marginBottom: '20px' }}>
                    <TextField
                        multiline
                        value={alternative.associatedText}
                        disabled={!props.canEditLesson}
                        onClick={(event) => setPuzzleBlockInFocus(index)}
                        onChange={(event) =>
                            props.onChangePuzzleBlockText(
                                alternative.associatedTextSnippetId,
                                event.target.value,
                                'right'
                            )
                        }
                        label={'Right side block'}
                        style={{ width: '200px', borderRadius: '10px' }}
                        InputProps={{ disableUnderline: true, shrink: 'false' }}
                        className={classes.textFields}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={2} sm={2} md={2} style={{ marginBottom: '20px' }}>
                    {props.node.testOptions[0].alternatives.length > 1 && (
                        <IconButton aria-label="delete" style={{ marginTop: '10px' }}>
                            <DeleteIcon
                                onClick={(event) => {
                                    handleDeletePuzzleBlockPair(index)
                                }}
                                fontSize="small"
                            />
                        </IconButton>
                    )}
                </Grid>
            </React.Fragment>
        )
    }

    const puzzleBlockLanguageSelection = () => {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
                    <Typography variant="h6" className={classes.sectionHeader}>
                        Language
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5} style={{ marginTop: '10px' }}>
                    <Typography
                        variant="body1"
                        style={{
                            boxShadow: 'none',
                            fontSize: 16,
                            color: 'black',
                            fontFamily: 'Lato',
                            marginBottom: '10px',
                        }}>
                        Left column language
                    </Typography>
                    <Select
                        xs={2}
                        style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width: '60px',
                            height: '60px',
                            border: '5px solid #143349',
                            boxSizing: 'border-box',
                            borderRadius: '10px',
                            maxWidth: '7px',
                            maxHeight: '40px',
                            minWidth: '78px',
                            minHeight: '40px',
                        }}
                        disableUnderline
                        value={props.node.testOptions[0]?.alternatives[0]?.textLanguageId === props.languageId ? 1 : 0}
                        autoWidth
                        onChange={(e) => props.onChangePuzzleBlockColumnLanguage('left', e.target.value)}>
                        <MenuItem disabled={!props.canEditLesson} value={0}>
                            <img src={getFlagAssetForLanguageId(props.l1Id)} width="23" alt={'L1'} />
                        </MenuItem>
                        <MenuItem disabled={!props.canEditLesson} value={1}>
                            <img src={getFlagAssetForLanguageId(props.languageId)} width="23" alt={'L2'} />
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ marginTop: '10px' }}>
                    <Typography
                        variant="body1"
                        style={{
                            boxShadow: 'none',
                            fontSize: 16,
                            color: 'black',
                            fontFamily: 'Lato',
                            marginBottom: '10px',
                        }}>
                        Right column language
                    </Typography>
                    <Select
                        xs={2}
                        style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width: '60px',
                            height: '60px',
                            border: '5px solid #143349',
                            boxSizing: 'border-box',
                            borderRadius: '10px',
                            maxWidth: '7px',
                            maxHeight: '40px',
                            minWidth: '78px',
                            minHeight: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                        disableUnderline
                        value={
                            props.node.testOptions[0]?.alternatives[0]?.associatedTextLanguageId === props.languageId
                                ? 1
                                : 0
                        }
                        autoWidth
                        onChange={(e) => props.onChangePuzzleBlockColumnLanguage('right', e.target.value)}>
                        <MenuItem disabled={!props.canEditLesson} value={0}>
                            <img src={getFlagAssetForLanguageId(props.l1Id)} width="23" alt={'L1'} />
                        </MenuItem>
                        <MenuItem disabled={!props.canEditLesson} value={1}>
                            <img src={getFlagAssetForLanguageId(props.languageId)} width="23" alt={'L2'} />
                        </MenuItem>
                    </Select>
                </Grid>
            </React.Fragment>
        )
    }

    const puzzleStageBlockPhoneDisplay = (alternative, middleBlock) => {
        return (
            <React.Fragment>
                <Grid item xs={6} sm={6} md={6} className={classes.scenarsio}>
                    {((props.node.testOptions[0].scenarioInteractiveElementType !==
                        ScenarioInteractiveElementType.AssociationPuzzleSequential &&
                        props.node.testOptions[0].scenarioInteractiveElementType !==
                            ScenarioInteractiveElementType.AssociationPuzzleSequentialKeepRight) ||
                        middleBlock === true) && (
                        <Card
                            style={{
                                margin: '10px',
                                marginTop: '5px',
                                marginBottom: '128px',
                                marginLeft: middleBlock === true ? '10px' : '16px',
                                width: middleBlock === true ? '100px' : '85px',
                                height: middleBlock === true ? '100px' : '85px',
                                dropShadow: '(0px 3.57333px 0px rgba(0, 0, 0, 0.25))',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}>
                            <Textfit
                                variant="body1"
                                max={14}
                                style={{
                                    //fontSize: 14,
                                    fontFamily: 'Lato',
                                    width: '250px',
                                    height: '60px',
                                    textAlign: 'center',
                                    color: '#44A6EF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 'normal',
                                }}>
                                {alternative.text}
                            </Textfit>
                        </Card>
                    )}
                </Grid>
                <Grid item xs={6} sm={6} md={6} className={classes.scenarsio}>
                    <Card
                        style={{
                            margin: '10px',
                            marginTop: '5px',
                            marginBottom: '128px',
                            marginLeft: middleBlock === true ? '10px' : '16px',
                            width: middleBlock === true ? '100px' : '85px',
                            height: middleBlock === true ? '100px' : '85px',
                            dropShadow: '(0px 3.57333px 0px rgba(0, 0, 0, 0.25))',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}>
                        <Textfit
                            variant="body1"
                            max={14}
                            style={{
                                //fontSize: 14,
                                fontFamily: 'Lato',
                                width: '250px',
                                height: '60px',
                                textAlign: 'center',
                                color: '#44A6EF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 'normal',
                            }}>
                            {alternative.associatedText}
                        </Textfit>
                    </Card>
                </Grid>
            </React.Fragment>
        )
    }

    const listenWithSliderOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '528px' }}>
                <ListenWithSliderEditNode
                    canEditLesson={props.canEditLesson}
                    node={props.node}
                    lesson={props.lesson}
                    onAudioLibrary={audioLibraryV2}
                    onUpdateAllTestOptionOnCurrentNode={props.onUpdateAllTestOptionOnCurrentNode}
                    onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
                    onVoiceDrop={handleOnVoiceDrop}
                    onMicVoiceDrop={handleOnMicVoiceDrop}
                    onPlayVoice={handlePlayVoiceByAudioUrl}
                    onRemoveVoice={handleRemoveVoice}
                    RuleCardTitleChange={handleRuleCardTitleChange}
                    interactionSelectionSection={interactionSelectionSection}
                    onTestOptionTextLanguageTypeChange={handleTestOptionTextLanguageTypeChange}
                    onChangeWhenSpoken={handleChangeWhenSpoken}
                    detectInteractionMode={detectInteractionMode}
                    onChangeTextImageQuestionL2={handleChangeTextImageQuestionL2}
                    displayInteraction={displayInteraction}
                    onRuleCardTitleChange={handleRuleCardTitleChange}
                    onReorderTestOptions={onReorderTestOptions}
                    onAddRule={handleAddRule}
                    onChangeInteraction={handleChangeInteraction}
                    languageId={props.languageId}
                    audioPlaying={audioPlaying}
                    handleOnNodeInteractionScoringChanged={handleOnNodeInteractionScoringChanged}
                    currentAudioBlobUrl={props.currentAudioBlobUrl}
                    currentAudioTracker={props.currentAudioTracker}
                    onAudioLibraryClick={handleAudioLibraryClick}
                    l1Id={props.l1Id}
                />
                {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                {saveOrCancelStage()}
            </Grid>
        )
    }

    const dialogStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                <DialogStageEditNode
                    canEditLesson={props.canEditLesson}
                    node={props.node}
                    lesson={props.lesson}
                    onAudioLibrary={audioLibraryV2}
                    onUpdateAllTestOptionOnCurrentNode={props.onUpdateAllTestOptionOnCurrentNode}
                    onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
                    onVoiceDrop={handleOnVoiceDrop}
                    onMicVoiceDrop={handleOnMicVoiceDrop}
                    onPlayVoice={handlePlayVoiceByAudioUrl}
                    onRemoveVoice={handleRemoveVoice}
                    RuleCardTitleChange={handleRuleCardTitleChange}
                    interactionSelectionSection={interactionSelectionSection}
                    onTestOptionTextLanguageTypeChange={handleTestOptionTextLanguageTypeChange}
                    onChangeWhenSpoken={handleChangeWhenSpoken}
                    detectInteractionMode={detectInteractionMode}
                    onChangeTextImageQuestionL2={handleChangeTextImageQuestionL2}
                    displayInteraction={displayInteraction}
                    onRuleCardTitleChange={handleRuleCardTitleChange}
                    onReorderTestOptions={onReorderTestOptions}
                    onAddRule={handleAddRule}
                    displayErrorsSection={displayErrorsSection}
                    saveOrCancelStage={saveOrCancelStage}
                    onChangeInteraction={handleChangeInteraction}
                    languageId={props.languageId}
                    audioPlaying={audioPlaying}
                    handleOnNodeInteractionScoringChanged={handleOnNodeInteractionScoringChanged}
                    currentAudioBlobUrl={props.currentAudioBlobUrl}
                    currentAudioTracker={props.currentAudioTracker}
                    onAudioLibraryClick={handleAudioLibraryClick}
                    l1Id={props.l1Id}
                    onUpdateSelectedDialogueUtterance={handleUpdateSelectedDialogueUtterance}
                />
                {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                {saveOrCancelStage()}
            </Grid>
        )
    }

    const instructionStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                <InstructionStageEditNode
                    canEditLesson={props.canEditLesson}
                    node={props.node}
                    lesson={props.lesson}
                    updateImageDrop={props.updateImageDrop}
                    scenarioImages={props.scenarioImages}
                    onAudioLibrary={audioLibraryV2}
                    onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
                    onUpdatePreviewImage={handleUpdateImagePreview}
                    l2Id={props.languageId}
                    l1Id={props.l1Id}
                    onAutoTranslation={props.onAutoTranslation}
                />
                {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                {saveOrCancelStage()}
            </Grid>
        )
    }
    const puzzleStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                <Grid item xs={12} style={{ marginTop: '70px', marginBottom: '20px' }}>
                    <Typography variant="h6" className={classes.stageHeader}>
                        Puzzle
                    </Typography>
                    <Typography
                        style={{
                            boxShadow: 'none',
                            letterSpacing: '0.02em',
                            fontSize: 14,
                            color: '#8E8E93',
                            fontFamily: 'Lato',
                        }}>
                        Add pairs of puzzle blocks. The order of the blocks is randomised in the app. The learner must
                        line up the matching blocks to clear the puzzle.
                    </Typography>
                </Grid>
                {openVocabularyImport && (
                    <VocabularySelector
                        language1={props.node.testOptions[0]?.alternatives[0]?.textLanguageId}
                        language2={props.node.testOptions[0]?.alternatives[0]?.associatedTextLanguageId}
                        existingSelection={props.node.testOptions[0].alternatives}
                        lessonNodes={props.nodes}
                        createPuzzleBlocks={handleCreatePuzzleBlocks}
                        open={openVocabularyImport}
                        toggleOpen={(x) => setOpenVocabularyImport(!!!openVocabularyImport)}
                    />
                )}
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h6" className={classes.sectionHeader}>
                            Title
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextFieldWithLanguageSelector
                            text={props.node.texts[0].text}
                            width={'300px'}
                            disabled={!props.canEditLesson}
                            onChange={(event) => handleChangeTextImageTitle(event.target.value)}
                            onChangeLanguageLanguage={(e) =>
                                props.onChangePuzzleBlockDescriptionLanguage(e.target.value)
                            }
                            l2Id={props.languageId}
                            l1Id={props.l1Id}
                            canChangeLanguage={true}
                            displayL1OrL2={props.node.texts[0]?.textLanguageId === props.languageId ? 1 : 0}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                        {props.node.testOptions[0]?.alternatives?.length > 0 && puzzleBlockLanguageSelection()}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
                    <Typography variant="h6" className={classes.sectionHeader}>
                        Puzzle pieces
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                        {props.node?.testOptions?.length > 0 &&
                            props.node.testOptions[0]?.alternatives?.length === 0 && (
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            boxShadow: 'none',
                                            fontSize: 16,
                                            color: 'black',
                                            fontFamily: 'Lato',
                                            marginBottom: '20px',
                                        }}>
                                        No puzzle blocks added.
                                    </Typography>
                                </Grid>
                            )}
                        {props.node?.testOptions?.length > 0 &&
                            props.node.testOptions[0]?.alternatives &&
                            props.node.testOptions[0].alternatives.map((alternative, index) =>
                                puzzleStageBlock(alternative, index)
                            )}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button
                                onClick={() => props.onAddPuzzleBlocks()}
                                className={classes.saveButton}
                                style={{
                                    marginRight: '5px',
                                    display: 'flex',
                                    textAlign: 'left',
                                    alignItems: 'left',
                                    float: 'left',
                                }}
                                variant="outlined">
                                Add new block pair
                            </Button>
                            <Button
                                onClick={() => setOpenVocabularyImport(true)}
                                className={classes.saveButton}
                                style={{
                                    marginRight: '5px',
                                    display: 'flex',
                                    textAlign: 'left',
                                    alignItems: 'left',
                                    float: 'left',
                                }}
                                variant="outlined">
                                Create blocks from other stages
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                    <Typography variant="h6" className={classes.sectionHeader}>
                        Puzzle mode
                    </Typography>

                    <Typography
                        style={{
                            boxShadow: 'none',
                            letterSpacing: '0.02em',
                            fontSize: 14,
                            color: '#8E8E93',
                            fontFamily: 'Lato',
                        }}>
                        By default, the learner can match the puzzle pieces in any order. If you prefer, you can make
                        them appear one at a time. Choose which mode you would like to use.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <React.Fragment>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!props.canEditLesson}
                                    checked={
                                        props.node.testOptions[0].scenarioInteractiveElementType ===
                                            ScenarioInteractiveElementType.AssociationPuzzleSequential ||
                                        props.node.testOptions[0].scenarioInteractiveElementType ===
                                            ScenarioInteractiveElementType.AssociationPuzzleSequentialKeepRight
                                    }
                                    onChange={(event) => handleChangePuzzleInteractionType()}
                                    color="primary"
                                />
                            }
                            label={'Make the left side puzzle blocks appear one at a time'}
                        />
                    </React.Fragment>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <React.Fragment>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!props.canEditLesson}
                                    checked={
                                        props.node.testOptions[0].scenarioInteractiveElementType ===
                                        ScenarioInteractiveElementType.AssociationPuzzleSequentialKeepRight
                                    }
                                    onChange={(event) => handleChangePuzzleInteractionKeepRight()}
                                    color="primary"
                                />
                            }
                            label={'Keep right puzzle blocks after answer on the left side'}
                        />
                    </React.Fragment>
                </Grid>

                {props.node.testOptions[0]?.alternatives?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                        <Grid container>
                            <Grid item style={{ marginRight: '30px' }}>
                                <Typography variant="h6" className={classes.sectionHeader}>
                                    Spoken
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    style={{
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                        marginBottom: '5px',
                                        height: '60px',
                                        border: '5px solid #143349',
                                        boxSizing: 'border-box',
                                        borderRadius: '10px',
                                        maxHeight: '40px',
                                        minWidth: '230px',
                                        minHeight: '40px',
                                        marginTop: '0px',
                                    }}
                                    disableUnderline
                                    value={props.node.testOptions[0].audioPlayType}
                                    autoWidth
                                    onChange={(e) => handleChangeWhenSpoken(e.target.value, 0)}>
                                    <MenuItem disabled={!props.canEditLesson} value={0}>
                                        Never
                                    </MenuItem>
                                    <MenuItem disabled={!props.canEditLesson} value={2}>
                                        After each correct answer
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                {saveOrCancelStage()}
            </Grid>
        )
    }

    const imageStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                <Grid item xs={12} style={{ marginTop: '70px', marginBottom: '20px' }}>
                    <Typography variant="h6" className={classes.stageHeader}>
                        Test
                    </Typography>
                    <Typography
                        style={{
                            boxShadow: 'none',
                            letterSpacing: '0.02em',
                            fontSize: 14,
                            color: '#8E8E93',
                            fontFamily: 'Lato',
                        }}>
                        A stage to test your learner's knowledge. Choose an Interaction, and create a spelling, multiple
                        choice or sentence builder test.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.sectionHeader}>Title</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextFieldWithLanguageSelector
                        text={props.node.texts[0].text}
                        width="210px"
                        disabled={!props.canEditLesson}
                        onChange={(event) => handleChangeTextImageTitle(event.target.value)}
                        l2Id={null}
                        l1Id={null}
                        soloLanguageId={props.l1Id}
                        canChangeLanguage={false}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <InteractionImage
                        canEditLesson={props.canEditLesson}
                        node={props.node}
                        lesson={props.lesson}
                        testOption={props.node.testOptions[0]}
                        updateImageDrop={props.updateImageDrop}
                        scenarioImages={props.scenarioImages}
                        onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
                        onUpdatePreviewImage={handleUpdateImagePreview}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} style={{ width: '95%', marginTop: '40px' }}>
                    {interactionSelectionSection('testImage', 2, 'Interaction')}
                </Grid>

                {
                    // Sentence builder
                    detectInteractionMode(props.node.testOptions[2].alternatives, props.node.testOptions[2].text) ===
                        true && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#8E8E93',
                                        fontFamily: 'Lato',
                                        marginBottom: '10px',
                                    }}>
                                    Reconstruct the text in the right order. The order of the text tiles are randomised
                                    in the app.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                                <TextFieldWithLanguageSelector
                                    text={props.node.testOptions[2].text}
                                    disabled={!props.canEditLesson}
                                    onChange={(event) => handleChangeTextImageQuestionL2(event.target.value, 2)}
                                    l2Id={null}
                                    l1Id={null}
                                    soloLanguageId={props.languageId}
                                    canChangeLanguage={false}
                                />
                            </Grid>
                        </React.Fragment>
                    )
                }
                {props.node.testOptions &&
                    props.node.testOptions.length > 2 &&
                    props.node.testOptions[2].alternatives && (
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12} className={classes.interactions}>
                                {displayInteraction(
                                    props.node.testOptions[2].alternatives,
                                    props.node.testOptions[2].scenarioInteractiveElementType,
                                    2,
                                    ScenarioNodeType.TestImage,
                                    'Enter the interaction text',
                                    false
                                )}
                            </Grid>
                        </React.Fragment>
                    )}
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
                    <Typography
                        style={{
                            boxShadow: 'none',
                            color: 'black',
                            fontFamily: 'Lato',
                            fontSize: 22,
                            fontWeight: 'bold',
                        }}>
                        Translation
                    </Typography>
                </Grid>
                <Grid container style={{ marginTop: '10px' }}>
                    <Grid item xs={9} sm={9} md={9}>
                        <div style={{ marginRight: '5px' }}>
                            <TextFieldWithLanguageSelector
                                text={props.node.testOptions[1].text}
                                disabled={!props.canEditLesson}
                                onChange={(event) => handleChangeTextImageQuestionL1(event.target.value)}
                                onChangeLanguageLanguage={(event) =>
                                    handleChangeLanguageImageQuestionL1(event.target.value)
                                }
                                l2Id={props.languageId}
                                l1Id={props.l1Id}
                                canChangeLanguage={true}
                                displayL1OrL2={props.node.testOptions[1].textLanguageId === props.languageId ? 1 : 0}
                                fullWidth={true}
                            />
                        </div>
                    </Grid>
                    {props.canEditLesson && props.node.testOptions[1].textLanguageId === props.l1Id && (
                        <Grid item xs={3} sm={3} md={3}>
                            <Button
                                onClick={() => handleAutoTranslation(props.node.testOptions[2].text)}
                                className={classes.textfieldWithDisable}
                                variant="outlined">
                                Translate
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
                    <Grid container>
                        <Grid item xs={2} sm={2} md={2}>
                            <Typography
                                variant="h6"
                                style={{
                                    boxShadow: 'none',
                                    color: 'black',
                                    fontFamily: 'Lato',
                                    fontSize: 22,
                                    fontWeight: 'bold',
                                }}>
                                Voice
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Select
                                style={{
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    marginBottom: '5px',
                                    height: '60px',
                                    border: '5px solid #143349',
                                    boxSizing: 'border-box',
                                    borderRadius: '10px',
                                    maxHeight: '40px',
                                    minWidth: '260px',
                                    minHeight: '40px',
                                }}
                                disableUnderline
                                value={props.node.testOptions[2].audioPlayType}
                                autoWidth
                                onChange={(e) => handleChangeWhenSpoken(e.target.value, 2)}>
                                <MenuItem disabled={!props.canEditLesson} value={0}>
                                    Never spoken out
                                </MenuItem>
                                <MenuItem disabled={!props.canEditLesson} value={1}>
                                    Spoken at the start of the stage
                                </MenuItem>
                                <MenuItem disabled={!props.canEditLesson} value={2}>
                                    Spoken at the end of the stage
                                </MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>

                {audioLibraryV2(AudioLibraryType.TestOptionVoice, 2)}

                {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                {saveOrCancelStage()}
            </Grid>
        )
    }
    const displayLeftOrRightOrMiddle = (testOptions, index) => {
        let currentDirection = 1
        let currentDirectionString = 'left'
        let continueSearch = true
        if (index === 0) {
            if (testOptions[0].mentor === 100) {
                return 'middle'
            }
            return 'left'
        }
        testOptions.forEach((testOption, i) => {
            console.log('for each', testOption.mentor, testOptions)
            if (continueSearch === true && i > 0 && testOptions[i - 1].mentor !== testOption.mentor) {
                if (testOption.mentor !== 100) {
                    if (testOptions[i - 1].mentor !== 100) {
                        currentDirection = currentDirection * -1
                        console.log('swapping to', testOption, currentDirection)
                    } else {
                        if (i > 1) {
                            if (continueSearch === true && testOptions[i - 2].mentor !== testOption.mentor) {
                                currentDirection = currentDirection * -1
                                console.log(
                                    'previous was a narrator. compareing',
                                    testOptions[i - 2].mentor,
                                    'with ',
                                    testOption.mentor,
                                    'changed now to',
                                    currentDirection
                                )
                            }
                        }
                    }
                }
            }
            if (i === index) {
                if (testOption.mentor === 100) {
                    currentDirection = 0
                }
                if (currentDirection === -1) {
                    currentDirectionString = 'right'
                } else if (currentDirection === 1) {
                    currentDirectionString = 'left'
                } else {
                    currentDirectionString = 'middle'
                }
                continueSearch = false
            }
        })
        return currentDirectionString
    }

    const dialogueStagePhoneText = () => {
        return (
            <React.Fragment>
                <Grid
                    container
                    style={{
                        position: 'absolute',
                        color: '#8E8E93',
                        border: 'none',
                        top: '90px',
                        fontWeight: 900,
                        marginLeft: '51px',
                        width: '250px',
                        display: 'flex',
                        alignItems: 'top',
                        justifyContent: 'top',
                        spacing: 0,
                    }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography
                            variant="body1"
                            style={{
                                fontSize: 14,
                                fontFamily: 'Lato',
                                width: '230px',
                                textAlign: 'center',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            {props.node.texts[0].text}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            height: '230px',
                            marginTop: '10px',
                            overflowY: 'auto',
                            alignContent: 'flex-start',
                        }}>
                        {props.node?.testOptions?.length > 0 &&
                            props.node.testOptions.map((testOption, index) => (
                                <Grid item xs={12}>
                                    <DialogueStageUtterance
                                        mentor={testOption.mentor}
                                        alternatives={testOption.alternatives}
                                        text={testOption.text}
                                        translation={testOption.explanation}
                                        position={displayLeftOrRightOrMiddle(props.node.testOptions, index)}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                    {selectedDialogueUtterance !== -1 && (
                        <Grid
                            container
                            align="center"
                            justify="center"
                            alignItems="center"
                            style={{
                                position: 'absolute',
                                color: '#8E8E93',
                                marginLeft: '5px',
                                border: 'none',
                                top: 338,
                                fontWeight: 900,
                                left: '10px',
                                width: '219px',
                                minHeight: '45px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            {props.node.testOptions &&
                                props.node.testOptions.length > selectedDialogueUtterance &&
                                props.node.testOptions[selectedDialogueUtterance].alternatives &&
                                props.node.testOptions[selectedDialogueUtterance].alternatives.length > 0 &&
                                props.node.testOptions[selectedDialogueUtterance]?.scenarioInteractiveElementType !==
                                    2 &&
                                props.node.testOptions[selectedDialogueUtterance].alternatives
                                    .sort((a, b) =>
                                        detectInteractionMode(
                                            props.node.testOptions[selectedDialogueUtterance].alternatives,
                                            props.node.testOptions[selectedDialogueUtterance].text
                                        ) === true
                                            ? 0.5 - Math.random()
                                            : 1
                                    )
                                    .map((alternative, index) => (
                                        <Grid item>
                                            <Typography
                                                index={index}
                                                className={classes.explanationText}
                                                style={{
                                                    marginRight: '10px',
                                                    color: '#35323C',
                                                    background: '#FFFFFF',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    paddingLeft: '5px',
                                                    paddingRight: '5px',
                                                }}>
                                                {alternative.text}
                                            </Typography>
                                        </Grid>
                                    ))}
                        </Grid>
                    )}
                    {selectedDialogueUtterance !== -1 &&
                        props.node.testOptions[selectedDialogueUtterance]?.scenarioInteractiveElementType ===
                            ScenarioInteractiveElementType.FillInBlankWriting && (
                            <Grid
                                container
                                align="center"
                                justify="center"
                                alignItems="center"
                                style={{
                                    position: 'absolute',
                                    color: '#8E8E93',
                                    border: 'none',
                                    top: 273,
                                    fontWeight: 900,
                                    left: '10px',
                                    width: '219px',
                                    minHeight: '45px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <img component="img" style={{ width: '245px' }} src={Keyboard} />
                            </Grid>
                        )}
                </Grid>
            </React.Fragment>
        )
    }

    const puzzleStagePhoneText = () => {
        return (
            <React.Fragment>
                <Grid
                    container
                    align="center"
                    justify="center"
                    alignItems="center"
                    style={{
                        position: 'absolute',
                        color: '#8E8E93',
                        border: 'none',
                        top: '90px',
                        fontWeight: 900,
                        marginLeft: '51px',
                        width: '219px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenarsio}>
                        <Typography
                            variant="body1"
                            style={{
                                fontSize: 14,
                                fontFamily: 'Lato',
                                width: '230px',
                                textAlign: 'center',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            {props.node.texts[0].text}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    align="center"
                    justify="center"
                    alignItems="center"
                    style={{
                        position: 'absolute',
                        color: '#8E8E93',
                        marginLeft: '5px',
                        border: 'none',
                        top: 175,
                        fontWeight: 900,
                        left: '49px',
                        width: '219px',
                        minHeight: '45px',
                        display: 'flex',
                        alignItems: 'center',
                        height: '313px',
                        overflow: 'hidden',
                        overflowX: 'hidden',
                    }}>
                    {props.node?.testOptions?.length > 0 &&
                        props.node.testOptions[0]?.alternatives?.length > 0 &&
                        puzzleStageBlockPhoneDisplay(props.node.testOptions[0]?.alternatives[puzzleBlockInFocus], true)}
                </Grid>
                <Grid
                    container
                    align="center"
                    justify="center"
                    alignItems="center"
                    style={{
                        position: 'absolute',
                        color: '#8E8E93',
                        marginLeft: '5px',
                        border: 'none',
                        top: props.node.testOptions[0].alternatives.length === 2 ? '76px' : '114px',
                        fontWeight: 900,
                        left: '49px',
                        width: '219px',
                        minHeight: '45px',
                        display: 'flex',
                        alignItems: 'center',
                        height: '293px',
                        overflow: 'hidden',
                        overflowX: 'hidden',
                    }}>
                    {props.node?.testOptions?.length > 0 &&
                        props.node.testOptions[0]?.alternatives &&
                        props.node.testOptions[0].alternatives.map(
                            (alternative, index) =>
                                determinePuzzleBlocksToDisplay(index) === true &&
                                puzzleStageBlockPhoneDisplay(alternative, false)
                        )}
                </Grid>
            </React.Fragment>
        )
    }

    const subtitles = (subtitleType) => {
        return (
            <Grid
                container
                align="center"
                justify="center"
                alignItems="center"
                style={{
                    position: 'absolute',
                    color: '#8E8E93',
                    border: 'none',
                    top: '242px',
                    minHeight: '34px',
                    fontWeight: 900,
                    marginLeft: '41px',
                    width: '264px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(10, 10, 10, 0.6)',
                    backdropFilter: 'blur(37px)',
                }}>
                <Grid item xs={9} sm={9} md={9} className={classes.scenarsio}>
                    <Typography
                        variant="body1"
                        style={{
                            fontSize: 12,
                            fontFamily: 'Lato',
                            width: '230px',
                            textAlign: 'center',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        {subtitleType === 1 ? props.node.texts[1].text : props.node.options[0].text}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const quizCalculateColor = (index) => {
        switch (index) {
            case 0:
                return '#86C4F0'
            case 1:
                return '#CC85C6'
            case 2:
                return '#FFAE65'
            case 3:
                return '#9BD8A0'
        }
    }
    const quizStagePhoneText = () => {
        return (
            <div>
                {props.node.scenarioNodeType === ScenarioNodeType.ListenMultiChoice &&
                    props.node.testOptions.length > quizQuestionInFocus && (
                        <React.Fragment>
                            <img
                                alt="Crop"
                                style={{
                                    position: 'absolute',
                                    left: '38px',
                                    width: '264px',
                                    top: '232px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                }}
                                src={QuizWizardAudio}
                            />

                            <div
                                style={{
                                    position: 'absolute',
                                    left: '45px',
                                    width: '249px',
                                    top: '290px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                }}>
                                <Grid container>
                                    {props.node?.testOptions?.length > 0 && (
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Textfit
                                                    max={12}
                                                    style={{
                                                        textAlign: 'left',
                                                        color: 'white',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        fontFamily: 'Lato',
                                                        justifyContent: 'left',
                                                        fontWeight: '500',
                                                        borderRadius: 8,
                                                        background: 'var(--Blue-Bright, #5AB4F1)',
                                                        width: '225px',
                                                        height: '50px',
                                                    }}>
                                                    {props.node.testOptions[quizQuestionInFocus].text}
                                                </Textfit>
                                            </Grid>
                                            <Grid
                                                container
                                                style={{
                                                    borderRadius: 10,
                                                    background: 'var(--Shadow, rgba(102, 67, 160, 0.12))',
                                                    padding: '10px',
                                                }}>
                                                {props.node.testOptions[quizQuestionInFocus].alternatives?.map(
                                                    (alternative, index) => (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            style={{
                                                                paddingRight: '2px',
                                                            }}>
                                                            <Textfit
                                                                max={12}
                                                                style={{
                                                                    borderRadius: 10,
                                                                    border: '4px solid ' + quizCalculateColor(index),
                                                                    background: '#FFF',
                                                                    boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0.25)',
                                                                    color: '#1678C1',
                                                                    textAlign: 'center',
                                                                    fontFamily: 'Nunito',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 700,
                                                                    height: '66px',
                                                                    width: '94px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    marginTop: '5px',
                                                                }}>
                                                                {
                                                                    props.node.testOptions[quizQuestionInFocus]
                                                                        .alternatives[index].text
                                                                }
                                                            </Textfit>
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </div>
                        </React.Fragment>
                    )}

                {props.node.scenarioNodeType === ScenarioNodeType.MultiChoice && (
                    <React.Fragment>
                        <div
                            style={{
                                position: 'absolute',
                                left: '45px',
                                width: '249px',
                                top: '290px',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'left',
                            }}>
                            <Grid container>
                                {props.node?.testOptions?.length > 0 &&
                                    props.node.testOptions.length > quizQuestionInFocus && (
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Textfit
                                                    max={12}
                                                    style={{
                                                        textAlign: 'left',
                                                        color: 'white',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        fontFamily: 'Lato',
                                                        justifyContent: 'left',
                                                        fontWeight: '500',
                                                        borderRadius: 8,
                                                        background: 'var(--Blue-Bright, #5AB4F1)',
                                                        width: '225px',
                                                        height: '50px',
                                                    }}>
                                                    {props.node.testOptions[quizQuestionInFocus].text}
                                                </Textfit>
                                            </Grid>
                                            <Grid
                                                container
                                                style={{
                                                    borderRadius: 10,
                                                    background: 'var(--Shadow, rgba(102, 67, 160, 0.12))',
                                                    padding: '10px',
                                                }}>
                                                {props.node.testOptions[quizQuestionInFocus].alternatives?.map(
                                                    (alternative, index) => (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            style={{
                                                                paddingRight: '2px',
                                                            }}>
                                                            <Textfit
                                                                max={12}
                                                                style={{
                                                                    borderRadius: 10,
                                                                    border: '4px solid ' + quizCalculateColor(index),
                                                                    background: '#FFF',
                                                                    boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0.25)',
                                                                    color: '#1678C1',
                                                                    textAlign: 'center',
                                                                    fontFamily: 'Nunito',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 700,
                                                                    height: '66px',
                                                                    width: '94px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    marginTop: '5px',
                                                                }}>
                                                                {
                                                                    props.node.testOptions[quizQuestionInFocus]
                                                                        .alternatives[index].text
                                                                }
                                                            </Textfit>
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    )}
                            </Grid>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    const audiostoryStagePhoneText = () => {
        let textWithGapsForDistractors = displayL2WithGapForDistractors(
            props.node.testOptions[2].text,
            props.node.testOptions[2].alternatives
        ).split('_')
        return (
            <div
                style={{
                    position: 'absolute',
                    left: '35px',
                    width: '250px',
                    top: '80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginLeft: '10px',
                    marginRight: '10px',
                }}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography
                            style={{
                                fontSize: '12px',
                                color: 'white',
                                fontFamily: 'Lato',
                                fontWeight: '500',
                            }}>
                            {props.node.texts[0].text}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            marginTop: '100px',
                            marginLeft: '20px',
                            marginRight: '20px',
                        }}>
                        <Typography
                            style={{
                                fontSize: '11px',
                                color: 'white',
                                fontFamily: 'Lato',
                                fontWeight: '500',
                            }}>
                            {props.node.testOptions[1].text}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            marginTop: '40px',
                            marginLeft: '20px',
                            marginRight: '20px',
                        }}>
                        <Typography
                            className={classes.testImageText}
                            style={{
                                color: 'white',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '220px',
                            }}>
                            {textWithGapsForDistractors.map((part, index) => (
                                <React.Fragment key={index}>
                                    {part}
                                    {index < textWithGapsForDistractors.length - 1 && (
                                        <span className={classes.underscoreReplacement}></span>
                                    )}
                                </React.Fragment>
                            ))}
                        </Typography>
                    </Grid>
                    {props.node.testOptions[2]?.scenarioInteractiveElementType ===
                        ScenarioInteractiveElementType.FillInBlankWriting && (
                        <Grid
                            container
                            align="center"
                            justify="center"
                            alignItems="center"
                            style={{
                                position: 'absolute',
                                marginLeft: '4px',
                                top: 271,
                                left: '4px',
                                width: '235px',
                                minHeight: '45px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <img component="img" style={{ width: '250px' }} src={Keyboard} />
                        </Grid>
                    )}
                    <Grid
                        container
                        align="center"
                        justify="center"
                        alignItems="center"
                        style={{
                            position: 'absolute',
                            color: '#8E8E93',
                            marginLeft: '5px',
                            border: 'none',
                            top: 325,
                            fontWeight: 900,
                            left: '11px',
                            width: '219px',
                            minHeight: '45px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        {props.node.testOptions &&
                            props.node.testOptions.length > 2 &&
                            props.node.testOptions[2].alternatives &&
                            props.node.testOptions[2].alternatives.length > 0 &&
                            props.node.testOptions[2]?.scenarioInteractiveElementType !== 2 &&
                            props.node.testOptions[2].alternatives
                                .sort((a, b) =>
                                    detectInteractionMode(
                                        props.node.testOptions[2].alternatives,
                                        props.node.testOptions[2].text
                                    ) === true
                                        ? 0.5 - Math.random()
                                        : 1
                                )
                                .map((alternative, index) => (
                                    <Grid item>
                                        <Typography
                                            index={index}
                                            className={classes.explanationText}
                                            style={{
                                                marginRight: '10px',
                                                color: '#35323C',
                                                background: '#FFFFFF',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                paddingLeft: '5px',
                                                paddingRight: '5px',
                                            }}>
                                            {alternative.text}
                                        </Typography>
                                    </Grid>
                                ))}
                    </Grid>
                </Grid>
            </div>
        )
    }

    const imageStagePhoneText = () => {
        let textWithGapsForDistractors = displayL2WithGapForDistractors(
            props.node.testOptions[2].text,
            props.node.testOptions[2].alternatives
        ).split('_')

        return (
            <React.Fragment>
                <div
                    style={{
                        position: 'absolute',
                        left: '95px',
                        width: '150px',
                        top: '90px',
                        display: 'flex',
                        alignItems: 'center',

                        textAlign: 'center',
                    }}>
                    <Typography
                        variant="body1"
                        style={{
                            fontSize: 14,
                            fontFamily: 'Lato',
                            width: '250px',
                            textAlign: 'center',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        {props.node.texts[0].text}
                    </Typography>
                </div>

                <Typography
                    className={classes.testImageText}
                    style={{
                        position: 'absolute',
                        left: '55px',
                        top: '90px',
                        color: 'white',
                        alignItems: 'center',
                        textAlign: 'center',
                        width: '220px',
                    }}>
                    {props.node.text && props.node.text.length > 0 && props.node.texts[0].text}
                </Typography>
                <div
                    className={classes.answerCard}
                    style={{
                        position: 'absolute',
                        color: 'white',
                        marginLeft: '5px',
                        top: 250,
                        fontWeight: 900,
                        left: '55px',
                        minHeight: '45px',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} className={classes.scesdnario}>
                            <Typography
                                className={classes.testImageText}
                                style={{
                                    color: 'white',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    width: '220px',
                                    marginBottom: '10px',
                                }}>
                                {props.node.testOptions[1].text}
                            </Typography>
                        </Grid>
                        {props.node.testOptions.length > 2 && (
                            <Grid item xs={12} sm={12} md={12} className={classes.scesdnario}>
                                <Typography
                                    className={classes.testImageText}
                                    style={{
                                        color: 'white',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        width: '220px',
                                    }}>
                                    {textWithGapsForDistractors.map((part, index) => (
                                        <React.Fragment key={index}>
                                            {part}
                                            {index < textWithGapsForDistractors.length - 1 && (
                                                <span className={classes.underscoreReplacement}></span>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </div>
                {props.node.testOptions[2]?.scenarioInteractiveElementType ===
                    ScenarioInteractiveElementType.FillInBlankWriting && (
                    <Grid
                        container
                        align="center"
                        justify="center"
                        alignItems="center"
                        style={{
                            position: 'absolute',
                            marginLeft: '5px',
                            top: 373,
                            left: '55px',
                            width: '219px',
                            minHeight: '45px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <img component="img" style={{ width: '250px' }} src={Keyboard} />
                    </Grid>
                )}
                <Grid
                    container
                    align="center"
                    justify="center"
                    alignItems="center"
                    style={{
                        position: 'absolute',
                        color: '#8E8E93',
                        marginLeft: '5px',
                        border: 'none',
                        top: 403,
                        fontWeight: 900,
                        left: '55px',
                        width: '219px',
                        minHeight: '45px',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    {props.node.testOptions &&
                        props.node.testOptions.length > 2 &&
                        props.node.testOptions[2].alternatives &&
                        props.node.testOptions[2].alternatives.length > 0 &&
                        props.node.testOptions[2]?.scenarioInteractiveElementType !== 2 &&
                        props.node.testOptions[2].alternatives
                            .sort((a, b) =>
                                detectInteractionMode(
                                    props.node.testOptions[2].alternatives,
                                    props.node.testOptions[2].text
                                ) === true
                                    ? 0.5 - Math.random()
                                    : 1
                            )
                            .map((alternative, index) => (
                                <Grid item>
                                    <Typography
                                        index={index}
                                        className={classes.explanationText}
                                        style={{
                                            marginRight: '10px',
                                            color: '#35323C',
                                            background: '#FFFFFF',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            paddingLeft: '5px',
                                            paddingRight: '5px',
                                        }}>
                                        {alternative.text}
                                    </Typography>
                                </Grid>
                            ))}
                </Grid>
            </React.Fragment>
        )
    }
    const instructionStagePhoneText = () => {
        return (
            <React.Fragment>
                <div
                    style={{
                        position: 'absolute',
                        left: '54px',
                        width: '225px',
                        top: '244px',
                        display: 'flex',
                        alignItems: 'left',
                        textAlign: 'left',
                        minHeight: props?.node?.testOptions[2].explanation !== '' ? '157px' : '135px',
                        borderRadius: 5,
                        border: '2px solid var(--Purple, #A0439F)',
                        background: 'var(--White, #FFF)',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}>
                    <div>
                        <Typography
                            variant="body1"
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                fontStyle: 'normal',
                                marginLeft: '5px',
                                width: '214px',
                                textAlign: 'left',
                                display: 'flex',
                                alignItems: 'left',
                                justifyContent: 'left',
                                background: 'white',
                                height: '99px',
                                overflowY: 'auto',
                                alignContent: 'flex-start',
                                paddingLeft: '1px',
                                paddingTop: '4px',
                                paddingRight: '1px',
                            }}>
                            {props?.node?.testOptions[2].text}
                        </Typography>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        left: '54px',
                        width: '229px',
                        top: '351px',
                        display: 'flex',
                        alignItems: 'left',
                        textAlign: 'left',
                        background: 'var(--Purple, #A0439F)',
                        zIndex: 0,
                    }}>
                    <Grid container>
                        {props?.node?.testOptions[2].explanation !== '' && (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    background: 'var(--Purple, #A0439F)',
                                    borderRadius: 5,
                                    border: '2px solid var(--Purple, #A0439F)',
                                }}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: 'white',
                                        fontFamily: 'Nunito',
                                        fontSize: 12,
                                        fontStyle: 'normal',
                                        width: '210px',
                                        textAlign: 'left',
                                        display: 'flex',
                                        alignItems: 'left',
                                        justifyContent: 'left',
                                        maxHeight: '69px',
                                        overflowY: 'auto',
                                        alignContent: 'flex-start',
                                        paddingLeft: '2px',
                                        paddingTop: '4px',
                                        paddingRight: '2px',
                                    }}>
                                    {props?.node?.testOptions[2].explanation}
                                </Typography>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                height: '30px',
                            }}>
                            <SubtitleImage
                                style={{
                                    height: '16px',
                                    background: 'var(--Purple, #A0439F)',
                                    borderRadius: 5,
                                    border: '2px solid var(--Purple, #A0439F)',
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }

    const definitionStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                {props.node.testOptions && props.node.testOptions.length > 0 && (
                    <React.Fragment>
                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: '70px' }}>
                            <Typography variant="h6" className={classes.stageHeader}>
                                Definition
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="body1" className={classes.scenarioDescriptionText}>
                                Teach words or phrases. Also provides pronunciation evaluation.
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.scenarioDescriptionText}
                                style={{ marginTop: '10px' }}>
                                You can add a translation, or an explanation of the text in either mother tongue or
                                learning language.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InteractionImage
                                canEditLesson={props.canEditLesson}
                                node={props.node}
                                lesson={props.lesson}
                                updateImageDrop={props.updateImageDrop}
                                scenarioImages={props.scenarioImages}
                                testOption={props.node.testOptions[0]}
                                onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
                                onUpdatePreviewImage={handleUpdateImagePreview}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            style={{
                                marginTop: '20px',
                                marginBottom: '10px',
                            }}>
                            <Typography className={classes.sectionHeader}>Text</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextFieldWithLanguageSelector
                                text={props.node.testOptions[1].text}
                                disabled={!props.canEditLesson}
                                onChange={(event) =>
                                    handleDefinitionTextUpdated(
                                        event.target.value,
                                        props.node.testOptions[1].explanation
                                    )
                                }
                                l2Id={null}
                                l1Id={null}
                                soloLanguageId={props.languageId}
                                canChangeLanguage={false}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            style={{
                                marginTop: '20px',
                                marginBottom: '10px',
                            }}>
                            <Typography className={classes.sectionHeader} style={{ marginBottom: '10px' }}>
                                Audio
                            </Typography>
                            <Typography variant="body1" className={classes.scenarioDescriptionText}>
                                Record a voice clip of the text being spoken out.
                            </Typography>
                            {audioLibraryV2(AudioLibraryType.TestOptionVoice, 1)}
                        </Grid>

                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{
                                    marginTop: '20px',
                                    marginBottom: '10px',
                                }}>
                                <Typography className={classes.sectionHeader}>Translation or Explanation</Typography>
                            </Grid>
                            <Grid item xs={9} sm={9} md={9}>
                                <div style={{ marginRight: '10px' }}>
                                    <TextFieldWithLanguageSelector
                                        text={props.node.testOptions[1].explanation}
                                        fullWidth={true}
                                        disabled={!props.canEditLesson}
                                        onChange={(event) =>
                                            handleDefinitionTextUpdated(
                                                props.node.testOptions[1].text,
                                                event.target.value
                                            )
                                        }
                                        onChangeLanguageLanguage={(event) =>
                                            handleLanguageForExplanation(event.target.value)
                                        }
                                        l2Id={props.languageId}
                                        l1Id={props.l1Id}
                                        canChangeLanguage={true}
                                        displayL1OrL2={
                                            props.node.testOptions[1].explanationLanguageId === props.languageId ? 1 : 0
                                        }
                                    />
                                </div>
                            </Grid>
                            {props.canEditLesson && (
                                <Grid item xs={3} sm={3} md={3}>
                                    <Button
                                        onClick={() => handleAutoTranslation(props.node.testOptions[1].text)}
                                        className={classes.textfieldWithDisable}
                                        variant="outlined">
                                        Translate
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </React.Fragment>
                )}
                {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                {saveOrCancelStage()}
            </Grid>
        )
    }

    const handleScrollIntoView = (index) => {
        //ruleCardRefs.current[index].scrollIntoView()
        setRuleCardInFocus(index)
    }

    const handleTestOptionTextLanguageTypeChange = (languageChooserValue, testOption, index) => {
        if (languageChooserValue !== undefined && languageChooserValue === 1) {
            setLanguageForTestOption(testOption, false)
        } else if (languageChooserValue !== undefined && languageChooserValue === 0) {
            setLanguageForTestOption(testOption, true)
        }
    }

    const rulecardStageOptions = () => {
        return (
            <RulecardModalContent
                onVoiceDrop={handleOnVoiceDrop}
                onMicVoiceDrop={handleOnMicVoiceDrop}
                onPlayVoice={handlePlayVoiceByAudioUrl}
                onRemoveVoice={handleRemoveVoice}
                RuleCardTitleChange={handleRuleCardTitleChange}
                interactionSelectionSection={interactionSelectionSection}
                onTestOptionTextLanguageTypeChange={handleTestOptionTextLanguageTypeChange}
                onChangeWhenSpoken={handleChangeWhenSpoken}
                detectInteractionMode={detectInteractionMode}
                node={props.node}
                onChangeTextImageQuestionL2={handleChangeTextImageQuestionL2}
                displayInteraction={displayInteraction}
                onRuleCardTitleChange={handleRuleCardTitleChange}
                onReorderTestOptions={onReorderTestOptions}
                onAddRule={handleAddRule}
                displayErrorsSection={displayErrorsSection}
                saveOrCancelStage={saveOrCancelStage}
                onChangeInteraction={handleChangeInteraction}
                languageId={props.languageId}
                audioPlaying={audioPlaying}
                canEditLesson={props.canEditLesson}
                handleOnNodeInteractionScoringChanged={handleOnNodeInteractionScoringChanged}
                currentAudioBlobUrl={props.currentAudioBlobUrl}
                currentAudioTracker={props.currentAudioTracker}
                onAudioLibraryClick={handleAudioLibraryClick}
                lesson={props.lesson}
                l1Id={props.l1Id}
                noiseSuppression={props.noiseSuppression}
                onToggleNoiseSuppression={() => props.onToggleNoiseSuppression()}
            />
        )
    }

    const multiChoiceStageOptions = () => {
        return (
            <MultiChoiceNodeModal
                onTestOptionTextLanguageTypeChange={handleTestOptionTextLanguageTypeChange}
                handleMultiChoiceQuestionDeleted={props.handleMultiChoiceQuestionDeleted}
                handleUpdateMultiChoiceAlternativeChanged={props.handleUpdateMultiChoiceAlternativeChanged}
                handleUpdateMultiChoiceQuestionChanged={props.handleUpdateMultiChoiceQuestionChanged}
                onAddMultiChoiceQuestion={props.onAddMultiChoiceQuestion}
                onAddRule={handleAddRule}
                onVoiceDrop={handleOnVoiceDrop}
                onMicVoiceDrop={handleOnMicVoiceDrop}
                onPlayVoice={handlePlayVoiceByAudioUrl}
                onRemoveVoice={handleRemoveVoice}
                backgroundAudioSection={backgroundAudioSection}
                mentorStageOptions={mentorStageOptions}
                handleMultiChoiceDropdownSelectorChanged={handleMultiChoiceDropdownSelectorChanged}
                interactionSelectionSection={interactionSelectionSection}
                onChangeWhenSpoken={handleChangeWhenSpoken}
                node={props.node}
                lesson={props.lesson}
                onReorderTestOptions={onReorderTestOptions}
                displayErrorsSection={displayErrorsSection}
                saveOrCancelStage={saveOrCancelStage}
                onChangeInteraction={handleChangeInteraction}
                languageId={props.languageId}
                audioPlaying={audioPlaying}
                canEditLesson={props.canEditLesson}
                handleOnNodeInteractionScoringChanged={handleOnNodeInteractionScoringChanged}
                onAudioLibraryClick={handleAudioLibraryClick}
                onUpdatePreviewImage={handleUpdateImagePreview}
                updateImageDrop={props.updateImageDrop}
                scenarioImages={props.scenarioImages}
                l1Id={props.l1Id}
                onSetQuizQuestionInFocus={handleSetQuizQuestionInFocus}
            />
        )
    }

    const briefStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                <Grid container>
                    <BackgroundImageSelection
                        canEditLesson={props.canEditLesson}
                        node={props.node}
                        lesson={props.lesson}
                        updateImageDrop={props.updateImageDrop}
                        scenarioImages={props.scenarioImages}
                        onUpdatePreviewImage={handleUpdateImagePreview}
                    />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px', marginBottom: '30px' }}>
                            <Typography variant="h6" className={classes.stageHeader}>
                                Brief
                            </Typography>
                            <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
                                <Typography variant="body1" className={classes.scenarioDescriptionText}>
                                    Write a short brief for your scenario. This gives your students an explanation of
                                    their objective in the scenario.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
                                <Typography className={classes.sectionHeader}>Brief description</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className={classes.scenarioShortDescription}>
                        <TextFieldWithLanguageSelector
                            text={props.node.options.length > 0 ? props.node.options[0].text : ''}
                            disabled={!props.canEditLesson}
                            onChange={(event) => handleScenarioBriefChange(event)}
                            onChangeLanguageLanguage={(e) => handleLanguageForBrief(e.target.value)}
                            l2Id={props.languageId}
                            l1Id={props.l1Id}
                            soloLanguageId={props.l1Id}
                            canChangeLanguage={true}
                            displayL1OrL2={props.node.options[0].textLanguageId === props.languageId ? 1 : 0}
                        />
                    </Grid>
                    {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                    {saveOrCancelStage()}
                </Grid>
            </Grid>
        )
    }

    const debriefStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                <Grid container>
                    <BackgroundImageSelection
                        canEditLesson={props.canEditLesson}
                        node={props.node}
                        lesson={props.lesson}
                        updateImageDrop={props.updateImageDrop}
                        scenarioImages={props.scenarioImages}
                        onUpdatePreviewImage={handleUpdateImagePreview}
                    />
                    <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
                        <Typography className={classes.sectionHeader}>Feedback when user passes the lesson</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenarioTextField}>
                        <TextFieldWithLanguageSelector
                            text={props.node.options.length > 0 ? props.node.options[0].text : ''}
                            disabled={!props.canEditLesson}
                            onChange={(event) => handleAnswerTextChange(event, 0)}
                            onChangeLanguageLanguage={(e) => handleDebriefTextLanguageChanged(e.target.value, 0)}
                            l2Id={props.languageId}
                            l1Id={props.l1Id}
                            soloLanguageId={props.l1Id}
                            canChangeLanguage={true}
                            displayL1OrL2={
                                props.node.options.length === 0 ||
                                props.node.options[0].textLanguageId === props.languageId
                                    ? 1
                                    : 0
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
                        <Typography className={classes.sectionHeader}>Feedback when user fails the lesson</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className={classes.scenarioTextField}>
                        <TextFieldWithLanguageSelector
                            text={props.node.options.length > 1 ? props.node.options[1].text : ''}
                            disabled={!props.canEditLesson}
                            onChange={(event) => handleAnswerTextChange(event, 1)}
                            onChangeLanguageLanguage={(e) => handleDebriefTextLanguageChanged(e.target.value, 1)}
                            l2Id={props.languageId}
                            l1Id={props.l1Id}
                            soloLanguageId={props.l1Id}
                            canChangeLanguage={true}
                            displayL1OrL2={
                                props.node.options.length < 2 ||
                                props.node.options[1].textLanguageId === props.languageId
                                    ? 1
                                    : 0
                            }
                        />
                    </Grid>
                    {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                    {saveOrCancelStage()}
                </Grid>
            </Grid>
        )
    }

    const handleAutoTranslation = (text, prev) => {
        props.onAutoTranslation(text)
    }

    const displayErrorsSection = () => {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
                    <React.Fragment>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={displayErrors}
                                    onChange={(event) => setDisplayErrors(!!!displayErrors)}
                                    value={displayErrors}
                                    color="primary"
                                />
                            }
                            label={'Display stage validation errors'}
                        />
                    </React.Fragment>
                </Grid>
                <List component="nav" aria-label="main mailbox folders">
                    {displayErrors &&
                        props.node?.validationErrors &&
                        props.node.validationErrors.map((validationError, index) => (
                            <ListItem key={index}>
                                <Typography variant="body1">{validationError}</Typography>
                            </ListItem>
                        ))}
                </List>
            </Grid>
        )
    }

    const saveOrCancelStage = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    gap: '8px',
                    marginTop: '16px',
                }}>
                <Button onClick={() => handleCancelStageOptions()} className={classes.saveButton} variant="outlined">
                    Cancel
                </Button>

                {props.canEditLesson && (
                    <Button onClick={() => handleSaveStageOptions()} className={classes.saveButton} variant="outlined">
                        Save changes
                        {savingNode && <CircularProgress />}
                    </Button>
                )}
            </div>
        )
    }

    const audioStageOptions = (
        audioLibraryType,
        title,
        description,
        noAudioAddedMessage,
        audioFieldType,
        propsSetAudioButton,
        propsAudioButton
    ) => {
        const { lesson, node } = props
        let audioNiceName = ''

        let nodeAudioUrl = null
        if (audioFieldType === AudioFieldType.BackgroundAudioUrl) {
            nodeAudioUrl = node.backgroundAudioUrl
        } else if (audioFieldType === AudioFieldType.SfxAudioUrl) {
            nodeAudioUrl = node.sfxAudioUrl
        }

        let audioUrl = null
        let audioTrackerId = null

        if (nodeAudioUrl) {
            var audioTracker = props.selectedNodeAudioTrackers.find((x) => x.audioUrl === nodeAudioUrl)
            if (!audioTracker) {
                // try also with the audioBlobUrl if the object is new
                audioTracker = props.selectedNodeAudioTrackers.find((x) => x.audioBlobUrl === nodeAudioUrl)
            }
            if (audioTracker) {
                if (audioTracker.audioUrl) {
                    audioUrl = audioTracker.audioUrl
                } else if (audioTracker.audioBlobUrl) {
                    audioUrl = audioTracker.audioBlobUrl
                }
                audioTrackerId = audioTracker.trackerId
                audioNiceName = audioTracker.audioNiceName
            } else if (nodeAudioUrl) {
                audioUrl = nodeAudioUrl
            }
        }

        var audioLibraryItems = []
        if (lesson?.lessonAudioReferences?.length > 0) {
            if (audioLibraryType === AudioLibraryType.BackgroundAudio) {
                audioLibraryItems = lesson.lessonAudioReferences.filter(
                    (x) =>
                        x.fileReferenceAudioUsageType === FileReferenceAudioUsageType.NotDefined ||
                        x.fileReferenceAudioUsageType === FileReferenceAudioUsageType.BackgroundAudio
                )
            } else if (audioLibraryType === AudioLibraryType.SoundEffect) {
                audioLibraryItems = lesson.lessonAudioReferences.filter(
                    (x) =>
                        x.fileReferenceAudioUsageType === FileReferenceAudioUsageType.NotDefined ||
                        x.fileReferenceAudioUsageType === FileReferenceAudioUsageType.SfxAudio
                )
            }
        }

        return (
            <Grid container>
                <React.Fragment>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
                        <Typography variant="body1" className={classes.sectionHeader}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
                        <Typography variant="body1" className={classes.scenarioDescriptionText}>
                            {description}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.scenarioScenesPreviewSection}>
                        {audioUrl ? (
                            <Card className={classes.cardPlayButton}>
                                {audioPlaying && props.currentAudioTracker?.trackerId === audioTrackerId ? (
                                    props.isAudioDownloading ? (
                                        <CircularProgress className={classes.circularProgress} />
                                    ) : (
                                        <StopIcon
                                            className={classes.playIcon}
                                            onClick={() => handlePlay(audioTrackerId)}
                                        />
                                    )
                                ) : (
                                    <PlayArrowIcon
                                        className={classes.playIcon}
                                        onClick={() => handlePlay(audioTrackerId)}
                                    />
                                )}
                                <Typography
                                    variant="body1"
                                    style={{
                                        width: '65px',
                                        boxShadow: 'none',
                                        flex: 1,
                                        fontSize: 14,
                                        color: 'white',
                                        fontFamily: 'Lato',
                                    }}>
                                    {audioNiceName && audioNiceName.slice(0, 30)}
                                    {audioNiceName &&
                                        audioNiceName.length > 30 &&
                                        '...' + audioNiceName.slice(audioNiceName.length - 4, audioNiceName.length)}
                                    {!!!audioNiceName && ''}
                                </Typography>
                                <CloseIcon
                                    className={classes.closeIcon}
                                    onClick={() => handleRemoveBackgroundAudio(audioFieldType, audioTrackerId)}
                                />
                            </Card>
                        ) : (
                            <React.Fragment>
                                <Typography variant="body1" className={classes.scenarioDescriptionText}>
                                    {noAudioAddedMessage}
                                </Typography>
                                <ButtonGroup className={classes.buttongroupSection}>
                                    <Button
                                        onClick={() => propsSetAudioButton(ScenarioAudioSelection.DragAndDropUpload)}
                                        style={
                                            propsAudioButton === ScenarioAudioSelection.ChooseFromLibrary
                                                ? {
                                                      backgroundColor: 'white',
                                                      fontColor: 'black',
                                                      color: 'black',
                                                  }
                                                : {
                                                      backgroundColor: '#143349',
                                                      fontColor: 'white',
                                                      color: 'white',
                                                  }
                                        }
                                        className={classes.noCapitals}
                                        variant={
                                            propsAudioButton === ScenarioAudioSelection.DragAndDropUpload
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        disabled={!props.canEditLesson}>
                                        Upload New
                                    </Button>
                                    <Button
                                        onClick={() => propsSetAudioButton(ScenarioAudioSelection.ChooseFromLibrary)}
                                        style={
                                            propsAudioButton === ScenarioAudioSelection.DragAndDropUpload
                                                ? {
                                                      backgroundColor: 'white',
                                                      fontColor: 'black',
                                                      color: 'black',
                                                  }
                                                : {
                                                      backgroundColor: '#143349',
                                                      fontColor: 'white',
                                                      color: 'white',
                                                  }
                                        }
                                        className={classes.noCapitals}
                                        variant="outlined"
                                        disabled={!props.canEditLesson}>
                                        Choose from library
                                    </Button>
                                </ButtonGroup>
                            </React.Fragment>
                        )}

                        {propsAudioButton === ScenarioAudioSelection.DragAndDropUpload && !audioUrl && (
                            <Grid item xs={12}>
                                {props.canEditLesson && (
                                    <Dropzone
                                        disabled={!props.canEditLesson}
                                        accept="audio/mp3,audio/mpeg,audio/mpeg3"
                                        onDrop={(file) =>
                                            handleOnAudioDrop(file, audioFieldType, audioNiceName, props.node.nodeId)
                                        }>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()} className={classes.dropZone}>
                                                    <input {...getInputProps()} />
                                                    <AudiotrackIcon className={classes.uploadIcon} />
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                )}
                            </Grid>
                        )}
                        {propsAudioButton === ScenarioAudioSelection.ChooseFromLibrary && !audioUrl && (
                            <Grid container className={classes.divList}>
                                <Grid item xs={12}>
                                    <List component="nav" aria-label="main mailbox folders">
                                        {audioLibraryItems.map((audio, index) => (
                                            <ListItem
                                                key={index}
                                                button
                                                selected={
                                                    props.node &&
                                                    props.node[audioFieldType] &&
                                                    props.node[audioFieldType] === audio.apiUrl
                                                }
                                                onClick={(event) =>
                                                    handleAudioItemClick(audioFieldType, audio, audioNiceName)
                                                }>
                                                <Typography variant="body1" className={classes.nameyourimage}>
                                                    {audio.description ? audio.description : audio.niceName}
                                                </Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </React.Fragment>
            </Grid>
        )
    }
    const mentorStageOptions = () => {
        return (
            <Grid container>
                <React.Fragment>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
                        <Typography variant="body1" className={classes.sectionHeader}>
                            Character
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
                        <Typography variant="body1" className={classes.scenarioDescriptionText}>
                            Choose the character that will appear in the stage.
                        </Typography>
                    </Grid>
                    <Grid container className={classes.scenarioScenesPreviewSection}>
                        <div className={classes.scenarioMentorPreviewComponent}>
                            <ScenarioMentorPreview mentor={1} onSelectMentor={() => handleMentorSelect(1)} />
                        </div>
                        <div className={classes.scenarioMentorPreviewComponent}>
                            <ScenarioMentorPreview mentor={3} onSelectMentor={() => handleMentorSelect(3)} />
                        </div>
                        <div className={classes.scenarioMentorPreviewComponent}>
                            <ScenarioMentorPreview mentor={-1} onSelectMentor={() => handleMentorSelect(-1)} />
                        </div>
                    </Grid>
                </React.Fragment>
            </Grid>
        )
    }

    const handleChangeStageLength = (value) => {
        props.onChangeStageLength(value)
    }

    const animationStageOptions = () => {
        return (
            <Grid container>
                <React.Fragment>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
                        <Typography className={classes.sectionHeader}>Animations</Typography>
                        <Typography
                            style={{
                                boxShadow: 'none',
                                letterSpacing: '0.02em',
                                fontSize: 14,
                                color: '#8E8E93',
                                fontFamily: 'Lato',
                                marginTop: '10px',
                            }}>
                            An important attribute of a Cutscene is the ability to make the character walk around the
                            screen.
                        </Typography>
                        <Typography
                            style={{
                                boxShadow: 'none',
                                letterSpacing: '0.02em',
                                fontSize: 14,
                                color: '#8E8E93',
                                fontFamily: 'Lato',
                                marginTop: '10px',
                            }}>
                            Drag and drop the character to the position on the screen that you wish. A character always
                            walks to the position it is placed from its position in the previous stage.
                        </Typography>
                        <Typography
                            style={{
                                boxShadow: 'none',
                                letterSpacing: '0.02em',
                                fontSize: 14,
                                color: '#8E8E93',
                                fontFamily: 'Lato',
                                marginTop: '10px',
                            }}>
                            Resize the character by moving the mouse to the top-right corner of the character. This
                            means you can give the illusion of the character walking towards the screen.
                        </Typography>
                    </Grid>
                    <Grid container className={classes.scenarioScenesPreviewSection}>
                        <Select
                            style={{
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                marginBottom: '5px',
                                width: '60px',
                                height: '60px',
                                border: '5px solid #143349',
                                boxSizing: 'border-box',
                                borderRadius: '10px',
                                maxHeight: '40px',
                                minWidth: '400px',
                                minHeight: '40px',
                            }}
                            disableUnderline
                            value={props.node.nodeLengthSeconds}
                            autoWidth
                            onChange={(e) => handleChangeStageLength(e.target.value)}>
                            <MenuItem disabled={!props.canEditLesson} value={0.1}>
                                This is the first stage in an animation sequence{' '}
                            </MenuItem>
                            <MenuItem disabled={!props.canEditLesson} value={1}>
                                The character should walk for 1 second
                            </MenuItem>
                            <MenuItem disabled={!props.canEditLesson} value={2}>
                                The character should walk for 2 seconds
                            </MenuItem>
                            <MenuItem disabled={!props.canEditLesson} value={3}>
                                The character should walk for 3 seconds
                            </MenuItem>
                        </Select>
                    </Grid>
                </React.Fragment>
            </Grid>
        )
    }

    const audioLibraryV2 = (type, testOptionIndex) => {
        if (type === AudioLibraryType.OptionsVoice) {
            return (
                <React.Fragment>
                    {!!!isHumanVoiceAdded(props.node.options[0]) && (
                        <Grid container style={{ marginTop: '10px' }}>
                            <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                style={{
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Button
                                    onClick={() => setButtonSelected(AudioSelectionOptionType.UploadAudio)}
                                    style={
                                        buttonSelected === AudioSelectionOptionType.UploadAudio
                                            ? {
                                                  backgroundColor: '#143349',
                                                  fontColor: 'white',
                                                  color: 'white',
                                              }
                                            : {
                                                  backgroundColor: 'white',
                                                  fontColor: 'black',
                                                  color: 'black',
                                              }
                                    }
                                    className={classes.audioLibraryButton}
                                    disabled={!props.canEditLesson}
                                    variant="outlined">
                                    Upload
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                style={{
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Button
                                    onClick={() => recordModeSelected()}
                                    style={
                                        buttonSelected === AudioSelectionOptionType.RecordAudio
                                            ? {
                                                  backgroundColor: '#143349',
                                                  fontColor: 'white',
                                                  color: 'white',
                                              }
                                            : {
                                                  backgroundColor: 'white',
                                                  fontColor: 'black',
                                                  color: 'black',
                                              }
                                    }
                                    className={classes.audioLibraryButton}
                                    disabled={!props.canEditLesson}
                                    variant="outlined">
                                    Record
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                style={{
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Button
                                    onClick={() => setButtonSelected(AudioSelectionOptionType.LibraryAudio)}
                                    style={
                                        buttonSelected === AudioSelectionOptionType.LibraryAudio
                                            ? {
                                                  backgroundColor: '#143349',
                                                  fontColor: 'white',
                                                  color: 'white',
                                              }
                                            : {
                                                  backgroundColor: 'white',
                                                  fontColor: 'black',
                                                  color: 'black',
                                              }
                                    }
                                    className={classes.audioLibraryButton}
                                    disabled={!props.canEditLesson}
                                    variant="outlined">
                                    Voice Library
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <div
                        style={{
                            marginTop: '10px',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <AudioPlayer
                            scenarioNodeType={props.node.scenarioNodeType}
                            humanAudioAdded={isHumanVoiceAdded(props.node.options[0])}
                            audioPlaying={audioPlaying}
                            isAudioDownloading={false}
                            onPlayVoice={handlePlayVoiceByAudioUrl}
                            onRemoveVoice={handleRemoveVoice}
                            textAudioUrl={
                                props.node.options?.length > 0 && props.node.options[0].textAudioUrl
                                    ? props.node.options[0].textAudioUrl
                                    : ''
                            }
                            testOptionId={null}
                            currentAudioBlobUrl={props.currentAudioBlobUrl}
                            currentAudioTracker={props.currentAudioTracker}
                        />
                    </div>
                    {buttonSelected === AudioSelectionOptionType.UploadAudio && (
                        <div
                            style={{
                                marginTop: '10px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '95%',
                            }}>
                            <UploadFile onVoiceDrop={(file) => handleOnVoiceDrop(file, null, true)} />
                        </div>
                    )}
                    {buttonSelected === AudioSelectionOptionType.RecordAudio && (
                        <div
                            style={{
                                marginTop: '10px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <AudioRecorder
                                scenarioNodeType={ScenarioNodeType.Listen}
                                audioBlob={'testListenAudioBlob'}
                                urlType={'testListenAudioBlob'}
                                onMicVoiceDrop={handleOnMicVoiceDrop}
                                isBlocked={isBlocked}
                                humanAudioAdded={isHumanVoiceAdded(props.node.options[0])}
                                audioPlaying={props.audioPlaying}
                                isAudioDownloading={false}
                                onPlayVoice={handlePlayVoiceByAudioUrl}
                                onRemoveVoice={handleRemoveVoice}
                                textAudioUrl={
                                    props.node.options?.length > 0 && props.node.options[0].textAudioUrl
                                        ? props.node.options[0].textAudioUrl
                                        : ''
                                }
                                testOptionId={null}
                                noiseSuppression={props.noiseSuppression}
                                onToggleNoiseSuppression={() => props.onToggleNoiseSuppression()}
                                niceName={props.node.options[0].text}
                            />
                        </div>
                    )}
                    {buttonSelected === AudioSelectionOptionType.LibraryAudio && (
                        <div
                            style={{
                                marginTop: '10px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <AudioLibrary
                                isAudioDownloading={props.isAudioDownloading}
                                onPlayVoice={handlePlayVoiceByAudioUrl}
                                audioPlaying={audioPlaying}
                                lesson={props.lesson}
                                audioFieldType={AudioFieldType.Option}
                                filterForVoiceOnlyAudio={true}
                                onAudioLibraryClick={handleAudioLibraryClick}
                            />
                        </div>
                    )}
                </React.Fragment>
            )
        }

        if (type === AudioLibraryType.TestOptionVoice) {
            return (
                <React.Fragment>
                    {!!!isHumanVoiceAdded(props.node.testOptions[testOptionIndex]) && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                gap: '8px',
                                marginTop: '10px',
                            }}>
                            <Button
                                onClick={() => setButtonSelected(AudioSelectionOptionType.UploadAudio)}
                                disabled={!props.canEditLesson}
                                style={
                                    buttonSelected === AudioSelectionOptionType.UploadAudio
                                        ? {
                                              backgroundColor: '#143349',
                                              fontColor: 'white',
                                              color: 'white',
                                          }
                                        : {
                                              backgroundColor: 'white',
                                              fontColor: 'black',
                                              color: 'black',
                                          }
                                }
                                className={classes.audioLibraryButton}
                                variant="outlined">
                                Upload
                            </Button>
                            <Button
                                onClick={() => recordModeSelected()}
                                disabled={!props.canEditLesson}
                                style={
                                    buttonSelected === AudioSelectionOptionType.RecordAudio
                                        ? {
                                              backgroundColor: '#143349',
                                              fontColor: 'white',
                                              color: 'white',
                                          }
                                        : {
                                              backgroundColor: 'white',
                                              fontColor: 'black',
                                              color: 'black',
                                          }
                                }
                                className={classes.audioLibraryButton}
                                variant="outlined">
                                Record
                            </Button>
                            <Button
                                onClick={() => setButtonSelected(AudioSelectionOptionType.LibraryAudio)}
                                disabled={!props.canEditLesson}
                                style={
                                    buttonSelected === AudioSelectionOptionType.LibraryAudio
                                        ? {
                                              backgroundColor: '#143349',
                                              fontColor: 'white',
                                              color: 'white',
                                          }
                                        : {
                                              backgroundColor: 'white',
                                              fontColor: 'black',
                                              color: 'black',
                                          }
                                }
                                className={classes.audioLibraryButton}
                                variant="outlined">
                                Library
                            </Button>
                        </div>
                    )}
                    <div
                        style={{
                            marginTop: '10px',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '95%',
                        }}>
                        <AudioPlayer
                            scenarioNodeType={props.node.scenarioNodeType}
                            humanAudioAdded={isHumanVoiceAdded(props.node.testOptions[testOptionIndex])}
                            audioPlaying={audioPlaying}
                            isAudioDownloading={false}
                            onPlayVoice={handlePlayVoiceByAudioUrl}
                            onRemoveVoice={handleRemoveVoice}
                            textAudioUrl={props.node.testOptions[testOptionIndex].textAudioUrl}
                            testOptionId={props.node.testOptions[testOptionIndex].testOptionId}
                            currentAudioBlobUrl={props.currentAudioBlobUrl}
                            currentAudioTracker={props.currentAudioTracker}
                        />
                    </div>
                    {buttonSelected === AudioSelectionOptionType.UploadAudio && (
                        <div
                            style={{
                                marginTop: '10px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <UploadFile
                                onVoiceDrop={(file) =>
                                    handleOnVoiceDrop(file, props.node.testOptions[testOptionIndex].testOptionId, true)
                                }
                            />
                        </div>
                    )}
                    {buttonSelected === AudioSelectionOptionType.RecordAudio && (
                        <div
                            style={{
                                marginTop: '10px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <AudioRecorder
                                scenarioNodeType={ScenarioNodeType}
                                onMicVoiceDrop={handleOnMicVoiceDrop}
                                isBlocked={isBlocked}
                                humanAudioAdded={isHumanVoiceAdded(props.node.testOptions[testOptionIndex])}
                                audioPlaying={props.audioPlaying}
                                isAudioDownloading={false}
                                onPlayVoice={handlePlayVoiceByAudioUrl}
                                onRemoveVoice={handleRemoveVoice}
                                textAudioUrl={props.node.testOptions[testOptionIndex].textAudioUrl}
                                testOptionId={props.node.testOptions[testOptionIndex].testOptionId}
                                noiseSuppression={props.noiseSuppression}
                                onToggleNoiseSuppression={() => props.onToggleNoiseSuppression()}
                            />
                        </div>
                    )}
                    {buttonSelected === AudioSelectionOptionType.LibraryAudio && (
                        <div
                            style={{
                                marginTop: '10px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <AudioLibrary
                                isAudioDownloading={props.isAudioDownloading}
                                onPlayVoice={handlePlayVoiceByAudioUrl}
                                audioPlaying={audioPlaying}
                                onAudioLibraryClick={handleAudioLibraryClick}
                                lesson={props.lesson}
                                audioFieldType={AudioFieldType.TestOption}
                                filterForVoiceOnlyAudio={true}
                            />
                        </div>
                    )}
                </React.Fragment>
            )
        }
    }

    const listenStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px', marginBottom: '30px' }}>
                    <Typography variant="h6" className={classes.stageHeader}>
                        Listen
                    </Typography>
                    <Typography
                        style={{
                            boxShadow: 'none',
                            letterSpacing: '0.02em',
                            fontSize: 14,
                            color: '#8E8E93',
                            fontFamily: 'Lato',
                        }}>
                        The listen stage is typically used as one half of a conversation in a role play lesson. Listen
                        and Branching stages are often used together to create a conversation.
                    </Typography>
                </Grid>
                <BackgroundImageSelection
                    canEditLesson={props.canEditLesson}
                    node={props.node}
                    lesson={props.lesson}
                    updateImageDrop={props.updateImageDrop}
                    scenarioImages={props.scenarioImages}
                    onUpdatePreviewImage={handleUpdateImagePreview}
                />
                <Grid item xs={12} sm={12} md={12} style={{ paddingRight: '50px', marginTop: '20px' }}>
                    <Typography variant="body1" className={classes.sectionHeader}>
                        Script
                    </Typography>
                    <Typography variant="body1" className={classes.editNodeSectionDescription}>
                        Write the text that will be spoken out.
                    </Typography>
                    <div className={classes.answersSection}>
                        <TextFieldWithLanguageSelector
                            text={props.node.options.length > 0 ? props.node.options[0].text : ''}
                            disabled={!props.canEditLesson}
                            onChange={(event) => handleAnswerTextChange(event, 0)}
                            l2Id={null}
                            l1Id={null}
                            soloLanguageId={props.languageId}
                            canChangeLanguage={false}
                        />
                        <Typography variant="body1" className={classes.editNodeSectionDescription}>
                            Altering the script means you will need to add a new voice sample
                        </Typography>
                    </div>
                    <Typography variant="body1" className={classes.sectionHeader} style={{ marginTop: '10px' }}>
                        Subtitle
                    </Typography>

                    <Typography
                        variant="body1"
                        className={classes.editNodeSectionDescription}
                        style={{ marginBottom: '5px' }}>
                        Optionally show a translation of the script as a subtitle. Leave blank to show the original
                        script as a subtitle. Turn subtitles on and off in Options.
                    </Typography>
                    <Grid container>
                        <Grid item xs={9} sm={9} md={9}>
                            <div style={{ marginRight: '10px' }}>
                                <TextFieldWithLanguageSelector
                                    text={
                                        props.node?.texts?.length > 0 &&
                                        props.node.texts.filter(
                                            (x) =>
                                                x.textType === ScenarioTextType.Subtitle &&
                                                x.textLanguageId == props.l1Id
                                        ).length > 0
                                            ? props.node.texts.filter(
                                                  (x) =>
                                                      x.textType === ScenarioTextType.Subtitle &&
                                                      x.textLanguageId == props.l1Id
                                              )[0].text
                                            : ''
                                    }
                                    disabled={!props.canEditLesson}
                                    onChange={(event) => handleL1SubtitleTextChange(event.target.value, 0)}
                                    fullWidth={true}
                                    l2Id={null}
                                    l1Id={null}
                                    soloLanguageId={props.l1Id}
                                    canChangeLanguage={false}
                                />
                            </div>
                        </Grid>
                        {props.canEditLesson && (
                            <Grid item xs={3} sm={3} md={3}>
                                <Button
                                    disabled={props.externalServiceIsBusy}
                                    onClick={() =>
                                        handleAutoTranslation(
                                            props.node.options[0].text,
                                            props.node?.texts?.length > 0 &&
                                                props.node.texts.filter(
                                                    (x) =>
                                                        x.textType === ScenarioTextType.Subtitle &&
                                                        x.textLanguageId == props.l1Id
                                                ).length > 0
                                                ? props.node.texts.filter(
                                                      (x) =>
                                                          x.textType === ScenarioTextType.Subtitle &&
                                                          x.textLanguageId == props.l1Id
                                                  )[0].text
                                                : ''
                                        )
                                    }
                                    variant="outlined"
                                    className={classes.textfieldWithDisable}>
                                    Translate
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                        <Typography variant="body1" className={classes.sectionHeader} style={{}}>
                            Voice
                        </Typography>
                    </Grid>
                    {audioLibraryV2(AudioLibraryType.OptionsVoice)}
                </Grid>
                {backgroundAudioSection()}
                {sfxAudioSection()}
                {mentorStageOptions()}
                {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                {saveOrCancelStage()}
            </Grid>
        )
    }

    const watchStageOptions = () => {
        return (
            <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                    <Typography variant="h6" className={classes.stageHeader}>
                        Cutscene
                    </Typography>
                    <Typography
                        style={{
                            boxShadow: 'none',
                            letterSpacing: '0.02em',
                            fontSize: 14,
                            color: '#8E8E93',
                            fontFamily: 'Lato',
                        }}>
                        Great to help create immersion or set the scene, cutscenes are often used at the beginning of a
                        lesson.
                    </Typography>
                    <Typography
                        style={{
                            boxShadow: 'none',
                            letterSpacing: '0.02em',
                            fontSize: 14,
                            color: '#8E8E93',
                            fontFamily: 'Lato',
                            marginTop: '20px',
                            marginBottom: '30px',
                        }}>
                        They are great to connect separate scenes within a role play - For instance to walk the
                        character into a building ready for the next scene.
                    </Typography>
                </Grid>

                <BackgroundImageSelection
                    canEditLesson={props.canEditLesson}
                    node={props.node}
                    lesson={props.lesson}
                    updateImageDrop={props.updateImageDrop}
                    scenarioImages={props.scenarioImages}
                    onUpdatePreviewImage={handleUpdateImagePreview}
                />
                {backgroundAudioSection()}
                {sfxAudioSection()}
                {mentorStageOptions()}
                {animationStageOptions()}
                {props.node?.validationErrors?.length > 0 && displayErrorsSection()}
                {saveOrCancelStage()}
            </Grid>
        )
    }

    const handleChangeInteraction = (interactionType, stageType, testOptionsIndex) => {
        setCurrentInteraction(interactionType)
        props.onChangeInteraction(interactionType, stageType, testOptionsIndex)
    }
    const handleLanguageForScoreReasoning = (languageChooserValue, optionIndex) => {
        if ((languageChooserValue !== undefined && languageChooserValue === 1) || languageChooserValue === 0) {
            var scoreReasoningIsInL1 = languageChooserValue === 0

            props.onChangeScoreReasoningL1Id(props.node.id, optionIndex, scoreReasoningIsInL1)
        }
    }

    const handleSpeakOrBranchingMultiChoiceSelector = (scenarioNodeType) => {
        if (
            (scenarioNodeType !== undefined && scenarioNodeType === ScenarioNodeType.Speak) ||
            scenarioNodeType === ScenarioNodeType.BranchingMultiChoice
        ) {
            props.onChangedNodeType(scenarioNodeType)
        }
    }

    const handleMultiChoiceDropdownSelectorChanged = (scenarioNodeType) => {
        if (scenarioNodeType === props.node.scenarioNodeType) {
            return
        }

        if (
            (scenarioNodeType !== undefined && scenarioNodeType === ScenarioNodeType.MultiChoice) ||
            scenarioNodeType === ScenarioNodeType.ListenMultiChoice
        ) {
            setQuizQuestionInFocus(0)

            if (scenarioNodeType === ScenarioNodeType.MultiChoice) {
                // remove the listen if it's there
                let otherTestOptions = props.node.testOptions.filter(
                    (x) => x.scenarioInteractiveElementType !== ScenarioInteractiveElementType.ListenButtonWithSlider
                )
                props.onUpdateAllTestOptionsOnCurrentNodeAndNodeType(scenarioNodeType, otherTestOptions)
            } else if (scenarioNodeType === ScenarioNodeType.ListenMultiChoice) {
                // add the listen if it's not there

                let listenWithSliderTestOption = {
                    audioPlayType: ScenarioAudioPlayType.PlayBefore,
                    scenarioInteractiveElementType: ScenarioInteractiveElementType.ListenButtonWithSlider,
                    testOptionId: uuidv4(),
                    text: '',
                    textSnippetId: null,
                    textLanguageId: props.languageId,
                    textAudioUrl: '',
                }

                let newTestOptions = [listenWithSliderTestOption, ...props.node.testOptions]
                props.onUpdateAllTestOptionsOnCurrentNodeAndNodeType(scenarioNodeType, newTestOptions)
            }
        }
    }

    const handleLanguageForExplanation = (languageChooserValue) => {
        if ((languageChooserValue !== undefined && languageChooserValue === 1) || languageChooserValue === 0) {
            var explanationIsInL1 = languageChooserValue === 0
            props.updateScenarioDefinitionText(
                props.node.testOptions[1].text,
                props.node.testOptions[1].explanation,
                props.node.id,
                explanationIsInL1
            )
        }
    }

    const handleLanguageForBrief = (languageChooserValue) => {
        if ((languageChooserValue !== undefined && languageChooserValue === 1) || languageChooserValue === 0) {
            var briefIsInL1 = languageChooserValue === 0
            var briefText = ''
            if (props.node?.options?.length && props.node.options[0].text) {
                briefText = props.node.options[0].text
            }
            props.updateScenarioBriefText(briefText, briefIsInL1)
        }
    }

    const handleChangeWhenSpoken = (value, testOptionId) => {
        props.onChangeWhenSpoken(value, props.node.testOptions[testOptionId].testOptionId)
    }

    const handleChangePuzzleInteractionType = () => {
        if (
            props.node.testOptions[0].scenarioInteractiveElementType ===
            ScenarioInteractiveElementType.AssociationPuzzle
        ) {
            props.onSetPuzzleInteractionType(ScenarioInteractiveElementType.AssociationPuzzleSequential)
        } else {
            props.onSetPuzzleInteractionType(ScenarioInteractiveElementType.AssociationPuzzle)
        }
    }

    const handleChangePuzzleInteractionKeepRight = () => {
        if (
            props.node.testOptions[0].scenarioInteractiveElementType ===
            ScenarioInteractiveElementType.AssociationPuzzleSequentialKeepRight
        ) {
            props.onSetPuzzleInteractionType(ScenarioInteractiveElementType.AssociationPuzzleSequential)
        } else {
            props.onSetPuzzleInteractionType(ScenarioInteractiveElementType.AssociationPuzzleSequentialKeepRight)
        }
    }
    const handleChangeTextImageQuestionL1 = (text) => {
        props.onChangeTextImageQuestion(props.node.testOptions[1].testOptionId, text)
    }

    const handleChangeLanguageImageQuestionL1 = (languageChooserValue) => {
        if ((languageChooserValue !== undefined && languageChooserValue === 1) || languageChooserValue === 0) {
            if (languageChooserValue === 0) {
                props.onChangeTestOptionLanguageId(props.node.testOptions[1].testOptionId, props.l1Id)
            } else {
                props.onChangeTestOptionLanguageId(props.node.testOptions[1].testOptionId, props.languageId)
            }
        }
    }

    const handleChangeTextImageTitle = (text) => {
        props.onChangeTextImageTitle(props.node.texts[0].textSnippetId, text)
    }

    const determinePuzzleBlocksToDisplay = (index) => {
        return index <= puzzleBlockInFocus + 2 && index != puzzleBlockInFocus
    }
    const handleChangeTextImageQuestionL2 = (text, testOptionIndex) => {
        var chunkMode = -1
        if (props.node.testOptions[testOptionIndex].testOptionId in testOptionChunkingMode) {
            chunkMode = testOptionChunkingMode[props.node.testOptions[testOptionIndex].testOptionId]
        }

        props.onChangeTextImageQuestionL2(
            props.node.testOptions[testOptionIndex].testOptionId,
            text,
            chunkMode,
            testOptionIndex
        )
    }

    const displayNewImageLayout = (type) => {
        // FIXME this is a temporary solution until after the demo
        let stageImageStyle = {}
        stageImageStyle.position = 'absolute'
        stageImageStyle.color = 'black'
        stageImageStyle.top = '64px'
        stageImageStyle.left = '51px'
        //stageImageStyle.height = '352px';
        stageImageStyle.width = '246px'
        if (props.node) {
            switch (props.node.scenarioNodeType) {
                case ScenarioNodeType.Watch:
                    return stageImageStyle
                case ScenarioNodeType.Listen:
                    stageImageStyle.left = '41px'
                    stageImageStyle.top = '55px'
                    stageImageStyle.width = '263px'
                    return stageImageStyle
                case ScenarioNodeType.Speak:
                    stageImageStyle.left = '41px'
                    stageImageStyle.top = '55px'
                    stageImageStyle.width = '263px'
                    return stageImageStyle
                case ScenarioNodeType.BranchingMultiChoice:
                    stageImageStyle.left = '41px'
                    stageImageStyle.top = '55px'
                    stageImageStyle.width = '263px'
                case ScenarioNodeType.Debrief:
                    return stageImageStyle
                case ScenarioNodeType.Brief:
                    return stageImageStyle
                case ScenarioNodeType.TestDefinition:
                    stageImageStyle.left = '82px'
                    stageImageStyle.top = '98px'
                    stageImageStyle.width = '176px'
                    return stageImageStyle
                case ScenarioNodeType.TestImage:
                    stageImageStyle.left = '110px'
                    stageImageStyle.top = '124px'
                    stageImageStyle.width = '120px'
                    return stageImageStyle
                case ScenarioNodeType.Instruction:
                    stageImageStyle.top = '92px' // 58px
                    stageImageStyle.left = '94px'
                    stageImageStyle.width = '156px'
                    stageImageStyle.height = '156px'
                    stageImageStyle.backgroundImage = 'url(' + definitionImageAddress + ')'
                    return stageImageStyle
                case ScenarioNodeType.MultiChoice:
                    stageImageStyle.top = '50px'
                    stageImageStyle.left = '38px'
                    stageImageStyle.width = '264px'
                    stageImageStyle.height = '264px'
                    return stageImageStyle
                case ScenarioNodeType.ListenMultiChoice:
                    stageImageStyle.top = '50px'
                    stageImageStyle.left = '38px'
                    stageImageStyle.width = '264px'
                    stageImageStyle.height = '264px'
                    return stageImageStyle
                default:
                    return stageImageStyle
            }
        }
    }

    const interactionSelectionSection = (stageType, testOptionsIndex, title) => {
        return (
            <Grid container>
                <Grid item xs={3} sm={3} md={3}>
                    <Typography variant="h6" className={classes.sectionHeader}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                    <Select
                        style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width: '60px',
                            height: '60px',
                            border: '5px solid #143349',
                            boxSizing: 'border-box',
                            borderRadius: '10px',
                            maxWidth: '7px',
                            maxHeight: '40px',
                            minWidth: '200px',
                            minHeight: '40px',
                        }}
                        disableUnderline
                        value={
                            props.node.testOptions[testOptionsIndex].scenarioInteractiveElementType ===
                            ScenarioInteractiveElementType.FillInBlankWriting
                                ? 0
                                : detectInteractionMode(
                                        props.node.testOptions[testOptionsIndex].alternatives,
                                        props.node.testOptions[testOptionsIndex].text
                                    ) === true
                                  ? 2
                                  : props.node.testOptions[testOptionsIndex].scenarioInteractiveElementType ===
                                      ScenarioInteractiveElementType.Text
                                    ? 3
                                    : 1
                        }
                        autoWidth
                        onChange={(e) => handleChangeInteraction(e.target.value, stageType, testOptionsIndex)}>
                        {stageType !== 'testImage' && <MenuItem value={3}>Reading</MenuItem>}
                        <MenuItem disabled={!props.canEditLesson} value={0}>
                            Keyboard
                        </MenuItem>
                        <MenuItem disabled={!props.canEditLesson} value={1}>
                            Multiple choice
                        </MenuItem>
                        <MenuItem disabled={!props.canEditLesson} value={2}>
                            Sentence Builder
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
        )
    }

    const speakAndBranchingMultiChoiceSelector = () => {
        const { lesson } = props
        return (
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                    paddingRight: '10px',
                    marginTop: '10px',
                    marginBottom: '30px',
                }}>
                <Select
                    xs={12}
                    className={classes.nodeTypeSelectorStyle}
                    disableUnderline
                    value={props.node.scenarioNodeType}
                    autoWidth
                    onChange={(e) => handleSpeakOrBranchingMultiChoiceSelector(e.target.value)}>
                    <MenuItem disabled={!props.canEditLesson} value={ScenarioNodeType.Speak}>
                        Speak
                    </MenuItem>
                    <MenuItem disabled={!props.canEditLesson} value={ScenarioNodeType.BranchingMultiChoice}>
                        Tap
                    </MenuItem>
                </Select>
            </Grid>
        )
    }

    const nodeScoringToggle = () => {
        const { lesson } = props
        return (
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                    marginTop: '75px',
                    position: 'absolute',
                    left: '670px',
                    minWidth: '200px',
                    display: 'flex',
                    textAlign: 'right',
                    alignItems: 'right',
                    justifyContent: 'right',
                    boxShadow: 'none',
                    flex: 1,
                    fontSize: 6,
                    color: 'black',
                    fontFamily: 'Lato',
                }}>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={!props.canEditLesson}
                            checked={props.node?.lessonNodeScoringDynamicConfigurationV1Dto?.shouldBeScored}
                            onChange={(e, checked) => {
                                props.onChangedNodeScoring(checked)
                            }}
                        />
                    }
                    label={
                        props.node?.lessonNodeScoringDynamicConfigurationV1Dto?.shouldBeScored
                            ? 'Scoring Enabled'
                            : 'Scoring Disabled'
                    }
                    labelPlacement="start"
                />
            </Grid>
        )
    }

    const getNodeScoreTotal = () => {
        const testOptions = props.node.testOptions

        let points = 0
        for (let i = 0; i < testOptions.length; i++) {
            if (
                testOptions[i].interactionScoringDynamicConfigurationV1Dto?.shouldBeScored &&
                (testOptions[i].scenarioInteractiveElementType === ScenarioInteractiveElementType.MultiChoice ||
                    testOptions[i].scenarioInteractiveElementType ===
                        ScenarioInteractiveElementType.FillInBlankDistractors ||
                    testOptions[i].scenarioInteractiveElementType === ScenarioInteractiveElementType.FillInBlankWriting)
            ) {
                points += 100
            }
        }

        return points
    }

    const nodeScoringHighlight = () => {
        const { lesson } = props
        return (
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                    marginTop: '75px',
                    position: 'absolute',
                    left: '700px',
                    minWidth: '200px',
                    display: 'flex',
                    textAlign: 'right',
                    alignItems: 'right',
                    justifyContent: 'right',
                    boxShadow: 'none',
                    flex: 1,
                }}>
                <Typography
                    variant="body1"
                    style={{
                        boxShadow: 'none',
                        fontSize: 20,
                        color: 'black',
                        fontFamily: 'Lato',
                    }}>
                    Max Score: {getNodeScoreTotal()}
                </Typography>
            </Grid>
        )
    }

    const classes = useStyles()
    return (
        <Grid container onClick={() => handleClickedModal()}>
            <ReactPlayer
                ref={audioRef}
                className="react-player"
                width="0px"
                height="0px"
                url={props.currentAudioBlobUrl}
                playing={audioPlaying && !!!props.isAudioDownloading}
                controls={audioControls}
                light={false}
                loop={loop}
                playbackRate={1.0}
                volume={0.8}
                muted={false}
                onReady={() => console.log('onReady')}
                onStart={() => console.log('onStart')}
                onPause={handlePause}
                onBuffer={() => console.log('onBuffer')}
                onSeek={(e) => console.log('onSeek', e)}
                onEnded={handleEnded}
                onError={(e) => console.log('onError', e)}
                onProgress={handleProgress}
                onDuration={handleDuration}
            />
            <Grid item xs={5} sm={5} md={5}>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ minWidth: '301px', marginTop: '16px' }}>
                    <Grid
                        item
                        style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '301px',
                        }}>
                        {readyToLoad ? (
                            <React.Fragment>
                                {imagePreviewFileUrl ? (
                                    <img component="img" style={displayNewImageLayout()} src={imagePreviewFileUrl} />
                                ) : (
                                    <section style={displayStageBackgroundImage()}></section>
                                )}
                            </React.Fragment>
                        ) : (
                            <CircularProgress
                                color="primary"
                                style={{
                                    position: 'absolute',
                                    color: 'black',
                                    top: '148px',
                                    left: '148px',
                                    display: 'flex',
                                    justify: 'flex-start',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            />
                        )}

                        <img
                            alt="Crop"
                            style={{
                                position: 'relative',
                                color: 'black',
                                top: 0,
                                left: 0,
                                right: '22px',
                                width: '301px',
                                height: '597px',
                                marginLeft: '22px',
                                draggable: 'false',
                            }}
                            src={displayPhoneSurround()}
                        />

                        {props.node.scenarioNodeType !== ScenarioNodeType.TestRule &&
                            props.node.scenarioNodeType !== ScenarioNodeType.MultiChoice &&
                            props.node.scenarioNodeType !== ScenarioNodeType.ListenMultiChoice &&
                            props.node.scenarioNodeType !== ScenarioNodeType.Listen &&
                            props.node.scenarioNodeType !== ScenarioNodeType.Watch &&
                            props.node.scenarioNodeType !== ScenarioNodeType.Brief &&
                            props.node.scenarioNodeType !== ScenarioNodeType.Debrief &&
                            props.node.scenarioNodeType !== ScenarioNodeType.Instruction &&
                            nodeScoringToggle()}

                        {(props.node.scenarioNodeType === ScenarioNodeType.TestRule ||
                            props.node.scenarioNodeType === ScenarioNodeType.MultiChoice ||
                            props.node.scenarioNodeType === ScenarioNodeType.ListenMultiChoice) &&
                            nodeScoringHighlight()}

                        {!!!props.displayComments && authorCommentsToggle()}

                        {props.node && props.node.scenarioMentors && props.node.scenarioMentors[0].mentor && (
                            <div
                                style={{
                                    position: 'absolute',
                                    left: -45,
                                    top: -140,
                                    width: '436px',
                                    height:
                                        props.node.type === ScenarioNodeType.Listen ||
                                        props.node.type === ScenarioNodeType.Speak ||
                                        props.node.type === ScenarioNodeType.BranchingMultiChoice
                                            ? 632
                                            : 772,
                                }}>
                                <Rnd
                                    position={{ x: mentorX, y: mentorY }}
                                    onDragStop={(e, d) => {
                                        handleMoveMentor(d.x, d.y)
                                    }}
                                    size={{
                                        width: mentorWidth,
                                        height: mentorHeight,
                                    }}
                                    minWidth={50}
                                    onResizeStop={(e, direction, ref, delta, position) => {
                                        handleSetMentorSize(ref.style.width, ref.style.height, position)
                                    }}
                                    minHeight={50}
                                    bounds="parent"
                                    lockAspectRatio={true}>
                                    <BoxMentor />
                                </Rnd>
                            </div>
                        )}
                        {((props?.subtitleType === 2 && props.node.options.length > 0) ||
                            (props?.subtitleType === 1 &&
                                props.node?.texts?.length > 1 &&
                                props.node.texts[1].text !== '')) &&
                            props?.node?.scenarioNodeType === ScenarioNodeType.Listen &&
                            subtitles(props.subtitleType)}
                        {props.node &&
                            props.node.scenarioNodeType &&
                            props.node.scenarioNodeType === ScenarioNodeType.TestDefinition &&
                            props.node.testOptions &&
                            props.node.testOptions.length > 1 && (
                                <React.Fragment>
                                    <div
                                        className={classes.answerCard}
                                        style={{
                                            position: 'absolute',
                                            color: '#44A6EF',
                                            marginLeft: '8px',
                                            letterSpacing: '0.5px',
                                            top: 240,
                                            fontWeight: 900,
                                            left: '55px',
                                            width: '180px',
                                            minHeight: '45px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Typography className={classes.definitionText}>
                                            {props.node.testOptions[1].text}
                                        </Typography>
                                    </div>
                                    <div
                                        className={classes.answerCard}
                                        style={{
                                            position: 'absolute',
                                            color: '#8E8E93',
                                            marginLeft: '5px',
                                            border: 'none',
                                            top: 283,
                                            fontWeight: 900,
                                            left: '55px',
                                            width: '219px',
                                            minHeight: '45px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Typography className={classes.explanationText}>
                                            {props.node.testOptions[1].explanation}
                                        </Typography>
                                    </div>
                                </React.Fragment>
                            )}
                        {props.node &&
                            props.node.scenarioNodeType &&
                            props.node.scenarioNodeType === ScenarioNodeType.TestImage &&
                            props.node.testOptions &&
                            props.node.testOptions.length > 1 &&
                            imageStagePhoneText()}
                        {props.node &&
                            props.node.scenarioNodeType &&
                            (props.node.scenarioNodeType === ScenarioNodeType.MultiChoice ||
                                props.node.scenarioNodeType === ScenarioNodeType.ListenMultiChoice) &&
                            quizStagePhoneText()}
                        {props.node &&
                            props.node.scenarioNodeType &&
                            props.node.scenarioNodeType === ScenarioNodeType.TestListenWithSlider &&
                            audiostoryStagePhoneText()}
                        {props.node &&
                            props.node.scenarioNodeType &&
                            props.node.scenarioNodeType === ScenarioNodeType.Instruction &&
                            props.node.testOptions &&
                            props.node.testOptions.length > 1 &&
                            instructionStagePhoneText()}
                        {props.node &&
                            props.node.scenarioNodeType &&
                            props.node.scenarioNodeType === ScenarioNodeType.TestDialog &&
                            dialogueStagePhoneText()}
                        {props.node &&
                            props.node.scenarioNodeType &&
                            props.node.scenarioNodeType === ScenarioNodeType.TestAssociationPuzzle &&
                            puzzleStagePhoneText()}
                        {props.node && props.node.options && props.node.scenarioNodeType === ScenarioNodeType.Brief && (
                            <React.Fragment>
                                <ScenarioAnswer
                                    fontSize={20}
                                    textAlign="center"
                                    answerClick={handleAnswerClick}
                                    answerPosition={displayOptionVisual(0, props.node.scenarioNodeType)}
                                    index={0}
                                    text={props.scenarioName}
                                    answerText=""
                                />
                                <ScenarioAnswer
                                    fontWeight="normal"
                                    textAlign="center"
                                    marginTop={40}
                                    answerClick={handleAnswerClick}
                                    answerPosition={displayOptionVisual(1, props.node.scenarioNodeType)}
                                    index={1}
                                    text={props.node.options[0].text}
                                    answerText=""
                                />
                            </React.Fragment>
                        )}
                        {props.node &&
                            props.node.options &&
                            props.node.scenarioNodeType === ScenarioNodeType.Debrief && (
                                <React.Fragment>
                                    <ScenarioAnswer
                                        answerPosition={250}
                                        index={0}
                                        height={'34px'}
                                        text={
                                            props.node.options && props.node.options[0]
                                                ? props.node.options[0].text
                                                : ''
                                        }
                                        answerText=""
                                    />
                                </React.Fragment>
                            )}
                        {props.node &&
                            props.node.options &&
                            (props.node.scenarioNodeType === ScenarioNodeType.Speak ||
                                props.node.scenarioNodeType === ScenarioNodeType.BranchingMultiChoice) &&
                            props.node.options.map((node, index) => {
                                return (
                                    <ScenarioAnswer
                                        key={index}
                                        answerClick={handleAnswerClick}
                                        answerPosition={displayOptionVisual(index, props.node.scenarioNodeType)}
                                        index={index}
                                        height={'34px'}
                                        text={node.text}
                                        node={node}
                                        answerText=""
                                    />
                                )
                            })}
                        {props.node && props.node.testOptions && props.node.scenarioNodeType === 8 && (
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    position: 'absolute',
                                    left: '60px',
                                    width: '230px',
                                    top: '100px',
                                }}>
                                {props.node && props.node.texts && props.node.texts.length > 0 && (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                width: '220px',
                                                top: '100px',
                                            }}>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: 'Lato',
                                                    textAlign: 'center',
                                                    color: 'white',
                                                }}>
                                                {props.node.texts[0].text}
                                            </Typography>
                                        </div>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{
                                        height: '220px',
                                        overflow: 'scroll',
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                    }}>
                                    {props.node.testOptions.map((testOption, index) => {
                                        return (
                                            <div ref={(el) => (ruleCardRefs.current[index] = el)}>
                                                <RuleCardDisplay
                                                    testOption={testOption}
                                                    isInFocus={index === ruleCardInFocus}
                                                    key={index}
                                                    answerClick={handleAnswerClick}
                                                    index={index}
                                                    height={'34px'}
                                                    text={handleDistractorDisplayTest(testOption)}
                                                    node={props.node}
                                                    answerText=""
                                                />
                                            </div>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {props.node && props.node.options && (
                <Grid item>
                    <Box
                        style={{
                            width: '538px',
                            maxHeight: '80vh', // Set the maxHeight to control the scrollable area
                            overflowY: 'auto',
                        }}>
                        {displayStageOptions()}
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}

function mapStateToProps(state) {
    const { organization, usergroup, topic, lesson, metadata } = state
    return {
        organization,
        topic,
        usergroup,
        lesson,
        metadata,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAudio: (organizationId, audioId) => dispatch(lessonActions.getAudio(organizationId, audioId)),
        getImage: (organizationId, imageId) => dispatch(lessonActions.getImage(organizationId, imageId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioEditNode)
