import { call, put, takeLatest } from 'redux-saga/effects'
import { snackbarActions } from '../actions'
import { courseGroupService } from '../services/coursegroup.service'
import { courseGroupConstants } from '../actions/coursegroup.actions'
import { topicService } from '../services'
import { history } from '_state/helpers'

export function* getCourseGroup(action) {
    try {
        const response = yield call(courseGroupService.getCourseGroup, action.payload.courseGroupId)
        yield put({ type: courseGroupConstants.GET_COURSE_GROUP.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to get course group. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: courseGroupConstants.GET_COURSE_GROUP.FAILURE, message: e.message })
    }
}

export function* createCourseGroup(action) {
    try {
        const response = yield call(courseGroupService.createCourseGroup, action.payload.adminCourseGroupDto)

        let courseGroupDto = response.data
        courseGroupDto['courseGroupSubPartDtos'] = [
            {
                name: courseGroupDto.name,
                index: 0,
                courseGroupSubPartToTopicMapperDtos: [],
            },
        ]
        const addSubPartResponse = yield call(courseGroupService.updateCourseGroup, courseGroupDto)
        yield put({ type: courseGroupConstants.CREATE_COURSE_GROUP.SUCCESS, data: addSubPartResponse.data })

        console.log('shoud forward', action.payload.autoForwardToCourse)

        if (action.payload.autoForwardToCourse) {
            const courseGroupId = response.data.id

            history.push('/creator/course/group/' + courseGroupId)
        }
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to create course group. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: courseGroupConstants.CREATE_COURSE_GROUP.FAILURE, message: e.message })
    }
}

export function* updateCourseGroup(action) {
    try {
        const response = yield call(courseGroupService.updateCourseGroup, action.payload.adminCourseGroupDto)
        yield put({ type: courseGroupConstants.UPDATE_COURSE_GROUP.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to update the course group. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: courseGroupConstants.UPDATE_COURSE_GROUP.FAILURE, message: e.message })
    }
}

export function* deleteCourseGroup(action) {
    try {
        const response = yield call(courseGroupService.deleteCourseGroup, action.payload.courseGroupId)
        yield put({ type: courseGroupConstants.DELETE_COURSE_GROUP.SUCCESS, data: response.data })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to delete the course group. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: courseGroupConstants.DELETE_COURSE_GROUP.FAILURE, message: e.message })
    }
}

export function* deleteCourseGroupSubPartToTopicMapper(action) {
    try {
        const response = yield call(courseGroupService.deleteCourseGroupSubPartToTopicMapper, action.payload)
        yield put({
            type: courseGroupConstants.DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to create new topic for course. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({
            type: courseGroupConstants.DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER.FAILURE,
            message: e.message,
        })
    }
}

export function* addTopicToCourseGroupSubPart(action) {
    try {
        let adminCourseGroupDto = action.payload.adminCourseGroupDto
        let topicDto = {
            organizationId: adminCourseGroupDto.organizationId,
            name: action.payload.topicName,
            l2Id: adminCourseGroupDto.l2Id,
            defaultL1Id: adminCourseGroupDto.l1Id,
            demoTopic: true,
            autoPublish: true,
            lessonsOnly: true,
        }

        const createTopicResponse = yield call(topicService.createTopic, topicDto)

        let newTopicId = createTopicResponse.data.id

        const topicUpdateDto = {
            organizationId: adminCourseGroupDto.organizationId,
            topicId: newTopicId,
            name: action.payload.topicName,
            topWordsCount: createTopicResponse.data.topWordsCount,
            description: action.payload.topicDescription,
            tags: createTopicResponse.data.tags,
            lessonsOnly: createTopicResponse.data.lessonsOnly,
            imageFileReferenceId: action.payload.imageFileReferenceId,
        }

        const newTopic = yield call(topicService.updateTopic, topicUpdateDto)

        newTopicId = newTopic.data.id

        let subPartToTopicMapper = {
            courseGroupSubPartId: action.payload.subPartId,
            topicId: newTopicId,
        }

        adminCourseGroupDto.courseGroupSubPartDtos.forEach((x) => {
            if (x.id === action.payload.subPartId) {
                if (!x.courseGroupSubPartToTopicMapperDtos) {
                    x.courseGroupSubPartToTopicMapperDtos = [subPartToTopicMapper]
                } else {
                    x.courseGroupSubPartToTopicMapperDtos.push(subPartToTopicMapper)
                }
            }
        })

        const response = yield call(courseGroupService.updateCourseGroup, adminCourseGroupDto)
        yield put({ type: courseGroupConstants.ADD_TOPIC_TO_COURSE_GROUP_SUB_PART.SUCCESS, data: response.data })

        if (action.payload.autoForwardToTopic) {
            const courseGroupId = adminCourseGroupDto.id
            history.push('/creator/course/group/' + courseGroupId + '/topic/' + newTopicId)
        }
    } catch (e) {
        let usefulInformation = '' // in case response data is empty.
        usefulInformation += e.response.data

        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were not able to create new topic for course. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: courseGroupConstants.ADD_TOPIC_TO_COURSE_GROUP_SUB_PART.FAILURE, message: e.message })
    }
}

//Listeners
export function* courseGroupSagas() {
    yield takeLatest(courseGroupConstants.GET_COURSE_GROUP.REQUEST, getCourseGroup)
    yield takeLatest(courseGroupConstants.UPDATE_COURSE_GROUP.REQUEST, updateCourseGroup)
    yield takeLatest(courseGroupConstants.CREATE_COURSE_GROUP.REQUEST, createCourseGroup)
    yield takeLatest(courseGroupConstants.DELETE_COURSE_GROUP.REQUEST, deleteCourseGroup)
    yield takeLatest(courseGroupConstants.ADD_TOPIC_TO_COURSE_GROUP_SUB_PART.REQUEST, addTopicToCourseGroupSubPart)
    yield takeLatest(
        courseGroupConstants.DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER.REQUEST,
        deleteCourseGroupSubPartToTopicMapper
    )
}
