import { asyncRequest } from './asyncRequest'

export const courseGroupConstants = {
    GET_COURSE_GROUP: new asyncRequest('GET_COURSE_GROUP'),
    CREATE_COURSE_GROUP: new asyncRequest('CREATE_COURSE_GROUP'),
    CREATE_COURSE_GROUP_RESET: new asyncRequest('CREATE_COURSE_GROUP_RESET'),
    UPDATE_COURSE_GROUP: new asyncRequest('UPDATE_COURSE_GROUP'),
    DELETE_COURSE_GROUP: new asyncRequest('DELETE_COURSE_GROUP'),
    DELETE_COURSE_GROUP_RESET: new asyncRequest('DELETE_COURSE_GROUP_RESET'),
    ADD_TOPIC_TO_COURSE_GROUP_SUB_PART: new asyncRequest('ADD_TOPIC_TO_COURSE_GROUP_SUB_PART'),

    DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER: new asyncRequest('DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER'),
}

export const courseGroupActions = {
    getCourseGroup(courseGroupId) {
        return {
            type: courseGroupConstants.GET_COURSE_GROUP.REQUEST,
            payload: {
                courseGroupId,
            },
        }
    },

    createCourseGroup(adminCourseGroupDto, autoForwardToCourse) {
        return {
            type: courseGroupConstants.CREATE_COURSE_GROUP.REQUEST,
            payload: {
                adminCourseGroupDto,
                autoForwardToCourse
            },
        }
    },

    createCourseGroupReset() {
        return {
            type: courseGroupConstants.CREATE_COURSE_GROUP_RESET.REQUEST,
            payload: {},
        }
    },

    updateCourseGroup(adminCourseGroupDto) {
        return {
            type: courseGroupConstants.UPDATE_COURSE_GROUP.REQUEST,
            payload: {
                adminCourseGroupDto,
            },
        }
    },

    deleteCourseGroup(courseGroupId) {
        return {
            type: courseGroupConstants.DELETE_COURSE_GROUP.REQUEST,
            payload: {
                courseGroupId,
            },
        }
    },
    deleteCourseGroupReset() {
        return {
            type: courseGroupConstants.DELETE_COURSE_GROUP_RESET.REQUEST,
            payload: {},
        }
    },

    addTopicToCourseGroupSubPart(topicName, subPartId, adminCourseGroupDto, imageFileReferenceId, topicDescription, autoForwardToTopic) {
        return {
            type: courseGroupConstants.ADD_TOPIC_TO_COURSE_GROUP_SUB_PART.REQUEST,
            payload: {
                topicName,
                subPartId,
                adminCourseGroupDto,
                imageFileReferenceId,
                topicDescription,
                autoForwardToTopic
            },
        }
    },

    deleteCourseGroupSubPartToTopicMapper(courseGroupId, courseGroupSubPartId, groupSubPartToTopicMapperId) {
        return {
            type: courseGroupConstants.DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER.REQUEST,
            payload: {
                courseGroupId,
                courseGroupSubPartId,
                groupSubPartToTopicMapperId,
            },
        }
    },
}
